import React, {FC} from "react";
import {useNavigate} from "react-router-dom";

import {useAppDispatch, useAppSelector} from "../../hooks/reduxHooks";
import {IProduct} from "../../interfaces/IProduct";
import {MAIN_ROUTES} from "../../routes/mainRoutes";
import {productsActions} from "../../store/slices/productSlice";
import styles from "./SearchResultsList.module.scss";

interface ISearchResultsListProps {
  hasSearched: boolean;
  setHasSearched?: (value: boolean) => void;
  setIsHamburgerOpen?: (value: boolean) => void;
}

const SearchResultsList: FC<ISearchResultsListProps> = ({hasSearched, setHasSearched, setIsHamburgerOpen}) => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const {filteredProducts, searchValue} = useAppSelector(state => state.products);

  const handleClearResults = (product: IProduct) => {
    dispatch(productsActions.clearFilteredProducts());
    setHasSearched?.(false);
    setIsHamburgerOpen?.(false);
    navigate(`${MAIN_ROUTES.PRODUCT}/${product.pk}`);
  }

  return (
    <>
      {hasSearched &&
        <ul className={styles.searchResultsList}>
          {hasSearched &&
            <>
              {filteredProducts.map((product: IProduct, index: number) =>
                <li
                  key={index}
                  className={styles.searchResultsList_item}
                  onClick={() => handleClearResults(product)}
                >{product.name}</li>)}

              {filteredProducts.length > 0 &&
                <li
                  className={styles.searchResultsList_item}
                  onClick={() => {
                    navigate(`${MAIN_ROUTES.SEARCH_RESULTS}?search=${searchValue}`, {state: {searchValue}});
                    setHasSearched?.(false);
                  }}
                >Показати всі результати пошуку</li>}
            </>
          }

          {(filteredProducts.length === 0 && hasSearched) &&
            <li className={styles.searchResultsList_item}>На жаль, нам нічого не вдалось знайти по вашому
              запиту</li>}
        </ul>}
    </>
  );
};

export default SearchResultsList;
