import {endpoints} from "../configs/endpoints";
import {instance} from "./axios.service";

export const categoriesService = {
  getAll: () => {
    const res = instance.get(endpoints.categories.base);

    return res;
  },
  getById: (id: number) => {
    const res = instance.get(`${endpoints.categories.base}${id}/`);

    return res;
  },
}
