import {endpoints} from "../configs/endpoints";
import {IUserChainStructure} from "../interfaces/IUserChainStructure";
import {IUserDetailed} from "../interfaces/IUserDetailed";
import {instance, IRes} from "./axios.service";

export const userService = {
  getById: (id: string): IRes<IUserDetailed> => {
    const res = instance.get(`${endpoints.user.base}${id}/`);

    return res;
  },
  updateById: (id: string, data: FormData): IRes<IUserDetailed> => {
    const res = instance.patch(`${endpoints.user.base}${id}/`, data, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });

    return res;
  },
  getChainByCurrentUser: (): IRes<IUserChainStructure> => {
    const res = instance.get(endpoints.user.currentUserChain);

    return res;
  },
  getChildrenByCurrentUserTreeView: (level: string): IRes<IUserChainStructure> => {
    const res = instance.get(endpoints.user.currentUserChain, {
      params: {
        "max_depth": level,
      }
    });

    return res;
  },
  getChildrenByCurrentUserListView: (uuid: string): IRes<IUserChainStructure> => {
    const res = instance.get(`${endpoints.user.base}${uuid}/user-chain`, {
      params: {
        "max_depth": 1,
      }
    });

    return res;
  },
}
