import React, {FC} from "react";
import {useNavigate} from "react-router-dom";

import close from "../../assets/close-white.svg";
import heart from "../../assets/favourite-white.svg";
import {MAIN_ROUTES} from "../../routes/mainRoutes";
import MainButtonTransparentBackground from "../MainButtonTransparentBackground/MainButtonTransparentBackground";
import styles from "./ModalAddedToFavourite.module.scss";

interface IModalAddedToFavouriteProps {
  handleClose: () => void;
}

const ModalAddedToFavourite: FC<IModalAddedToFavouriteProps> = ({handleClose}) => {
  const navigate = useNavigate();

  return (
    <div className={styles.modal}>
      <img
        onClick={handleClose}
        className={styles.modal_close}
        src={close}
        alt="close icon"
      />
      <img className={styles.modal_heart} src={heart} alt="heart"/>

      <h2 className={styles.modal_title}>Товар додано до ʼСписок вподобаногоʼ</h2>
      <MainButtonTransparentBackground
        text={"Переглянути Вподобані Товари"}
        width={"auto"}
        onClick={() => navigate(MAIN_ROUTES.FAVOURITES)}
        type={"button"}
      />
    </div>
  );
};

export default ModalAddedToFavourite;
