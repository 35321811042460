import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {AxiosError} from "axios";

import {IPaymentHistoryRequest} from "../../interfaces/IPaymentHistoryRequest";
import {IPaymentInfo} from "../../interfaces/IPaymentInfo";
import {paymentService} from "../../services/payment.service";

interface IState {
  status: "loading" | "success";
  current_available_balance: number | null;
  can_request_payment: boolean;
  history: IPaymentHistoryRequest[];
}

const initialState: IState = {
  status: "success",
  current_available_balance: null,
  can_request_payment: false,
  history: [],
}

const getByCurrentUser = createAsyncThunk<IPaymentInfo, void>("paymentSlice/getByCurrentUser",
  async (_, {rejectWithValue}) => {
    try {
      const {data} = await paymentService.getInfoByCurrentUser();

      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  });

const paymentSlice = createSlice({
  name: "paymentSlice",
  initialState,
  reducers: {
    reset: () => initialState
  },
  extraReducers: builder => builder
    .addCase(getByCurrentUser.fulfilled, (state, action) => {
      const {current_available_balance, history, can_request_payment} = action.payload;

      state.current_available_balance = current_available_balance;
      state.history = history;
      state.can_request_payment = can_request_payment;
      state.status = "success";
    })
    .addCase(getByCurrentUser.pending, (state, action) => {
      state.status = "loading";
    })
});

const {reducer: paymentReducer, actions} = paymentSlice;

const paymentActions = {
  ...actions,
  getByCurrentUser
}

export {
  paymentActions,
  paymentReducer,
}
