import React, {FC, useEffect} from "react";
import toast, {Toaster} from "react-hot-toast";
import {useNavigate} from "react-router-dom";

import chevron from "../../assets/chevron-left.svg";
import DeliveryAddresses from "../../components/DeliveryAddresses/DeliveryAddresses";
import UserDataProfile from "../../components/UserDataProfile/UserDataProfile";
import UserStatistics from "../../components/UserStatistics/UserStatistics";
import WithdrawalPoints from "../../components/WithdrawalPoints/WithdrawalPoints";
import IsAuth from "../../HOC/IsAuth";
import styles from "./UserProfilePage.module.scss";

interface IUserProfilePageProps {
}

const UserProfilePage: FC<IUserProfilePageProps> = () => {
  const navigate = useNavigate();

  const handleNavigateBack = () => {
    navigate(-1);
  }

  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth"
    });

    return () => {
      toast.remove("update");
    }
  }, []);

  return (
    <div className={styles.userProfilePage}>
      <div className={styles.userProfilePage_title}>
        <img onClick={handleNavigateBack} src={chevron} alt="chevron"/>
        <h1>ОСОБИСТА <span>ІНФОРМАЦІЯ</span></h1>
      </div>

      <div className={styles.userProfilePage_content}>
        <div className={styles.userProfilePage_content_left}>
          <UserStatistics/>
          <WithdrawalPoints/>
        </div>
        <div className={styles.userProfilePage_content_right}>
          <UserDataProfile/>
          <DeliveryAddresses type={"view"} title={"Дані доставки"}/>
        </div>
      </div>

      <Toaster/>
    </div>
  );
};

export default IsAuth(UserProfilePage);
