import {joiResolver} from "@hookform/resolvers/joi";
import CircularProgress from "@mui/material/CircularProgress";
import {AxiosError} from "axios";
import React, {FC, useState} from "react";
import {useForm} from "react-hook-form";
import {useNavigate, useParams} from "react-router-dom";

import InputField from "../../components/InputField/InputField";
import MainButtonRedBackground from "../../components/MainButtonRedBackground/MainButtonRedBackground";
import MainButtonTransparentBackground
  from "../../components/MainButtonTransparentBackground/MainButtonTransparentBackground";
import {endpoints} from "../../configs/endpoints";
import {MAIN_ROUTES} from "../../routes/mainRoutes";
import {instance} from "../../services/axios.service";
import {passwordRecoveryValidator} from "../../validators/passwordRecovery.validator";
import styles from "./PasswordRecoveryStep2Page.module.scss";

interface IPasswordRecoveryStep2PageProps {
}

const PasswordRecoveryStep2Page: FC<IPasswordRecoveryStep2PageProps> = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [isPasswordChanged, setIsPasswordChanged] = useState<boolean>(false);

  const {token} = useParams<{ token: string }>();

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: {errors, isValid},
  } = useForm<{ password: string; confirm_password: string }>({
    mode: "all",
    resolver: joiResolver(passwordRecoveryValidator),
  });

  const handleResetPassword = async (data: { password: string; confirm_password: string }) => {
    const {password, confirm_password} = data;

    try {
      setIsLoading(true);

      const res = await instance.post(endpoints.passwordRecovery.setNewPassword, {
        password,
        confirm_password,
        token,
      });

      if (res.status === 200) {
        setIsPasswordChanged(true);
      }
    } catch (e) {
      const error = e as AxiosError;
      if (error?.status === 500) {
        setError("Сталася помилка, спробуйте, будь ласка пізніше");
      } else {
        setError(JSON.stringify(error?.response?.data || error?.message));
      }
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapper_recoveryPasswordPage}>
        <h1 className={styles.wrapper_recoveryPasswordPage_title}>ПРИЄДНУЙТЕСЬ У СВІТ <span>КРАСИ</span></h1>

        <div className={styles.wrapper_recoveryPasswordPage_content}>
          <div className={styles.wrapper_recoveryPasswordPage_content_rectangle}></div>
          <div className={styles.wrapper_recoveryPasswordPage_content_form}>
            <h2>{isPasswordChanged ? "Ваш пароль успішно змінено!" : "Відновіть свій пароль"}</h2>

            {!isPasswordChanged &&
              <div className={styles.wrapper_recoveryPasswordPage_content_form_block}>
                <InputField
                  width={"100%"}
                  placeholder={""}
                  label={"Пароль"}
                  type={"password"} //type of input (password, text ...)
                  name={"password"}
                  register={register}
                  errors={errors}
                  requestErrorMessage={error}
                />
                <InputField
                  width={"100%"}
                  placeholder={""}
                  label={"Повторіть Пароль"}
                  type={"password"} //type of input (password, text ...)
                  name={"confirm_password"}
                  register={register}
                  errors={errors}
                />
              </div>}

            <div className={styles.wrapper_recoveryPasswordPage_content_form_buttons}>
              <MainButtonTransparentBackground
                text={"Повернутись на Головну"}
                width={"auto"}
                onClick={() => navigate(MAIN_ROUTES.HOME)}
                type={"button"}
              />
              {!isPasswordChanged &&
                ((isLoading) ?
                  <CircularProgress style={{color: "#9B232C"}} disableShrink/> :
                  <MainButtonRedBackground
                    text={"Відновити"}
                    width={"auto"}
                    onClick={handleSubmit(handleResetPassword)}
                    type={"submit"}
                    isDisabled={!isValid}
                  />)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordRecoveryStep2Page;
