import Joi from "joi";

import {REGEX} from "../constants/regex";

export const loginValidator = Joi.object({
  email: Joi.string().regex(REGEX.EMAIL).required().messages({
    "string.empty": "Заповніть це поле",
    "string.pattern.base": "Невалідний email",
  }),
  password: Joi.string().messages({
    "string.empty": "Заповніть це поле",
  })
});
