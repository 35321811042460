import {combineReducers, configureStore} from "@reduxjs/toolkit";
import {persistReducer, persistStore} from "redux-persist";
import storage from "redux-persist/lib/storage";

import {cartReducer} from "./slices/cartSlice";
import {categoriesReducer} from "./slices/categoriesSlice";
import {deliveryReducer} from "./slices/deliverySlice";
import {imagesReducer} from "./slices/imagesSlice";
import {orderReducer} from "./slices/orderSlice";
import {paymentReducer} from "./slices/paymentSlice";
import {productsReducer} from "./slices/productSlice";
import {UIReducer} from "./slices/UISlice";
import {userReducer} from "./slices/userSlice";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
}

const reducer = combineReducers({
  UI: UIReducer,
  user: userReducer,
  products: productsReducer,
  delivery: deliveryReducer,
  cart: cartReducer,
  categories: categoriesReducer,
  order: orderReducer,
  payment: paymentReducer,
  images: imagesReducer
});

const persistedReducer = persistReducer(persistConfig, reducer);

const store = configureStore({
  reducer: persistedReducer,
});

const persistor = persistStore(store);

type RootState = ReturnType<typeof store.getState>
type AppDispatch = typeof store.dispatch

export type {
  AppDispatch,
  RootState,
}

export {
  persistor,
  store
}
