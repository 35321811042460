import {AxiosError} from "axios";
import React, {FC, useEffect} from "react";
import {FieldError, useForm} from "react-hook-form";
import toast from "react-hot-toast";

import {useAppDispatch, useAppSelector} from "../../hooks/reduxHooks";
import {paymentService} from "../../services/payment.service";
import {paymentActions} from "../../store/slices/paymentSlice";
import MainButtonRedBackground from "../MainButtonRedBackground/MainButtonRedBackground";
import styles from "./WithdrawalRequestItem.module.scss";

interface IWithdrawalRequestItemProps {
}

const WithdrawalRequestItem: FC<IWithdrawalRequestItemProps> = () => {
  const {user} = useAppSelector(state => state.user);
  const {current_available_balance, can_request_payment} = useAppSelector(state => state.payment);
  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    formState: {errors, isValid},
    setError
  } = useForm<{ amount: number }>({
    mode: "all",
  });

  const getPaymentInfoByCurrentUser = () => {
    dispatch(paymentActions.getByCurrentUser());
  }

  const handleSendWithdrawalRequest = async (data: { amount: number }) => {
    const {amount} = data;

    if (Number(amount) < 27) {
      setError("amount", {message: "Мінімальна кількість для виведення - 27 балів!"});
      return;
    }

    if (current_available_balance && (Number(amount) > current_available_balance)) {
      setError("amount", {message: "Введена кількість перевищує доступний баланс!"});
    }

    try {
      await paymentService.sendWithdrawalRequest(amount);
      toast.success("Заявку на виведення успішно надіслано!", {id: "payment"});
      getPaymentInfoByCurrentUser();
    } catch (e) {
      const error = e as AxiosError;
      toast.error(JSON.stringify(error?.response?.data || error?.message), {id: "payment"});
    }
  }

  useEffect(() => {
    return () => {
      toast.remove("payment");
    }
  }, []);

  if (user) {
    const {current_balance} = user;

    return (
      <div className={styles.withdrawalRequestItem}>
        <h4 className={styles.title}>Бонусні Бали</h4>
        <div className={styles.withdrawalRequestItem_divider}></div>

        <div className={styles.withdrawalRequestItem_pointsCircular_points}>
          <div className={styles.withdrawalRequestItem_pointsCircular_points_count}>
            <div className={styles.withdrawalRequestItem_pointsCircular_points_count_wrapper}>
              <p className={styles.subtitle}>Поточний Розрахунковий Баланс</p>
              <p className={styles.count}>{current_balance.toFixed(2)}</p>
            </div>

            <div className={styles.withdrawalRequestItem_pointsCircular_points_count_wrapper}>
              <p className={styles.subtitle}>Доступно для виведення</p>
              <p className={styles.count}>{current_available_balance?.toFixed(2)}</p>
            </div>
          </div>

          <form
            onSubmit={handleSubmit(handleSendWithdrawalRequest)}
            className={styles.withdrawalRequestItem_pointsCircular_points_form}
          >
            <label className={styles.withdrawalRequestItem_pointsCircular_points_form_label}>
              Яку к-сть ББ бажаєте вивести?
              <input
                placeholder={"Введіть кількість"}
                type="text"
                {...register("amount")}
              />

              {errors.amount && <span className={styles.error}>{(errors.amount as FieldError).message}</span>}
            </label>

            <MainButtonRedBackground
              text={"Відправити Заявку"}
              width={"100%"}
              onClick={null}
              type={"submit"}
              isDisabled={!can_request_payment}
            />
          </form>
        </div>
      </div>
    );
  }

  return null;
};

export default WithdrawalRequestItem;
