import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {AxiosError} from "axios";

import {ICategory} from "../../interfaces/ICategory";
import {IResApiPagination} from "../../interfaces/IResApiPagination";
import {categoriesService} from "../../services/categories.service";

interface IState {
  categories: ICategory[];
  status: "loading" | "success";
  category: ICategory | null;
  breadcrumbs: string[];
}

const initialState: IState = {
  categories: [],
  status: "success",
  category: null,
  breadcrumbs: []
}

const getAll = createAsyncThunk<IResApiPagination<ICategory[]>, void>("categoriesSlice/getAll",
  async (_, {rejectWithValue}) => {
    try {
      const {data} = await categoriesService.getAll();

      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error!.response!.data);
    }
  });

const getById = createAsyncThunk<ICategory, { id: number }>("categoriesSlice/getById",
  async ({id}, {rejectWithValue}) => {
    try {
      const {data} = await categoriesService.getById(id);

      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error!.response!.data);
    }
  });

const categoriesSlice = createSlice({
  name: "categoriesSlice",
  initialState,
  reducers: {
    addBreadcrumbs: (state, action) => {
      const {subcategory, category} = action.payload;

      if (state.breadcrumbs.length > 1) {
        state.breadcrumbs.pop();
      }

      if (category === state.breadcrumbs[0]) {
        state.breadcrumbs.push(subcategory);
      } else {
        state.breadcrumbs = [category, subcategory];
      }
    },
    initializeBreadcrumbs: (state, action) => {
      state.breadcrumbs = action.payload;
    },
    reset: () => initialState
  },
  extraReducers: builder => builder
    .addCase(getAll.fulfilled, (state, action) => {
      state.categories = action.payload.results;
      state.status = "success";
    })
    .addCase(getAll.pending, (state, action) => {
      state.status = "loading";
    })

    .addCase(getById.fulfilled, (state, action) => {
      state.category = action.payload;
      state.status = "success";
    })
    .addCase(getById.pending, (state, action) => {
      state.status = "loading";
    })
});

const {reducer: categoriesReducer, actions} = categoriesSlice;

const categoriesActions = {
  ...actions,
  getAll,
  getById
}

export {
  categoriesActions,
  categoriesReducer,
}
