import React, {FC, useEffect, useState} from "react";

import certificate from "../../assets/certificate.svg";
import MainButtonTransparentBackground from "../MainButtonTransparentBackground/MainButtonTransparentBackground";
import styles from "./CertificateItem.module.scss";

interface ICertificateItemProps {
}

const CertificateItem: FC<ICertificateItemProps> = () => {
  const [deviceWidth, setDeviceWidth] = useState<number>(window.innerWidth);

  const handleResize = () => {
    setDeviceWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className={styles.item}>
      <img src={certificate} alt="certificate" draggable={false}/>

      <div className={styles.item_description}>
        <h3>Сертифікат ISO 9001</h3>
        <p>
          Встановлює критерії системи управління якістю. Стандарт заснований на низці принципів менеджменту
          якості, таких як сильна клієнтоорієнтованість, мотивація та залученість керівництва, процесний
          підхід та постійне вдосконалення. Застосування ISO 9001 допомагає гарантувати, що замовники
          стабільно отримують якісну продукцію та послуги.
        </p>
        <MainButtonTransparentBackground
          text={"Завантажити Сертифікат"}
          width={deviceWidth > 768 ? "auto" : "100%"}
          onClick={null}
          type={"button"}
        />
      </div>
    </div>
  );
};

export default CertificateItem;
