import React, {FC, useEffect} from "react";
import {QueryClient, QueryClientProvider} from "react-query";
import {Provider} from "react-redux";
import {createBrowserRouter, Navigate, RouterProvider} from "react-router-dom";
import {PersistGate} from "redux-persist/integration/react";

import {isTokenValid} from "./helpers/isTokenValid";
import MainLayout from "./layouts/MainLayout/MainLayout";
import MyAccountLayout from "./layouts/MyAccountLayout/MyAccountLayout";
import AboutUsPage from "./pages/AboutUsPage/AboutUsPage";
import BlogPage from "./pages/BlogPage/BlogPage";
import CartPage from "./pages/CartPage/CartPage";
import CategoryPage from "./pages/CategoryPage/CategoryPage";
import CertirficatesPage from "./pages/CertirficatesPage/CertirficatesPage";
import ConfirmEmailPage from "./pages/ConfirmEmailPage/ConfirmEmailPage";
import ConfirmOrderPage from "./pages/ConfirmOrderPage/ConfirmOrderPage";
import ContactsPage from "./pages/ContactsPage/ContactsPage";
import ErrorPage from "./pages/ErrorPage/ErrorPage";
import EventsCalendarPage from "./pages/EventsCalendarPage/EventsCalendarPage";
import FavouriteProductsPage from "./pages/FavouriteProductsPage/FavouriteProductsPage";
import HomePage from "./pages/HomePage/HomePage";
import LoginPage from "./pages/LoginPage/LoginPage";
import MarketingPlanPage from "./pages/MarketingPlanPage/MarketingPlanPage";
import PasswordRecoveryStep1Page from "./pages/PasswordRecoveryStep1Page/PasswordRecoveryStep1Page";
import PasswordRecoveryStep2Page from "./pages/PasswordRecoveryStep2Page/PasswordRecoveryStep2Page";
import PersonalOrdersPage from "./pages/PersonalOrdersPage/PersonalOrdersPage";
import ProductPage from "./pages/ProductPage/ProductPage";
import ReferralsStructurePage from "./pages/ReferralsStructurePage/ReferralsStructurePage";
import RegistrationPage from "./pages/RegistrationPage/RegistrationPage";
import ReportsPage from "./pages/ReportsPage/ReportsPage";
import SearchResultsPage from "./pages/SearchResultsPage/SearchResultsPage";
import SuccessStoriesPage from "./pages/SuccessStoriesPage/SuccessStoriesPage";
import UserProfilePage from "./pages/UserProfilePage/UserProfilePage";
import VerifyEmailPage from "./pages/VerifyEmailPage/VerifyEmailPage";
import WithdrawalRequestPage from "./pages/WithdrawalRequestPage/WithdrawalRequestPage";
import {MAIN_ROUTES} from "./routes/mainRoutes";
import {persistor, store} from "./store/store";

const queryClient = new QueryClient();

const App: FC = () => {
  const router = createBrowserRouter([
    {
      index: true,
      element: <Navigate to={MAIN_ROUTES.HOME}/>
    },
    {
      path: "",
      element: <MainLayout/>,
      children: [
        {
          path: MAIN_ROUTES.HOME,
          element: <HomePage/>
        },
        {
          path: `${MAIN_ROUTES.CATEGORIES}/:id`,
          element: <CategoryPage/>
        },
        {
          path: MAIN_ROUTES.REGISTRATION,
          element: <RegistrationPage/>
        },
        {
          path: MAIN_ROUTES.CONFIRM_EMAIL,
          element: <ConfirmEmailPage/>
        },
        {
          path: `${MAIN_ROUTES.VERIFY_EMAIL}/:token`,
          element: <VerifyEmailPage/>
        },
        {
          path: MAIN_ROUTES.PASSWORD_RECOVERY_STEP_1,
          element: <PasswordRecoveryStep1Page/>
        },
        {
          path: `${MAIN_ROUTES.PASSWORD_RECOVERY_STEP_2}/:token`,
          element: <PasswordRecoveryStep2Page/>
        },
        {
          path: MAIN_ROUTES.LOGIN,
          element: <LoginPage/>
        },
        {
          path: MAIN_ROUTES.CART,
          element: <CartPage/>
        },
        {
          path: `${MAIN_ROUTES.PRODUCT}/:id`,
          element: <ProductPage/>
        },
        {
          path: MAIN_ROUTES.CONTACTS,
          element: <ContactsPage/>
        },
        {
          path: MAIN_ROUTES.CONFIRM_ORDER,
          element: <ConfirmOrderPage/>
        },
        {
          path: MAIN_ROUTES.FAVOURITES,
          element: <FavouriteProductsPage/>
        },
        {
          path: MAIN_ROUTES.SEARCH_RESULTS,
          element: <SearchResultsPage/>
        },
        {
          path: MAIN_ROUTES.CERTIFICATES,
          element: <CertirficatesPage/>
        },
        {
          path: MAIN_ROUTES.ABOUT_US,
          element: <AboutUsPage/>
        },
        {
          path: MAIN_ROUTES.SUCCESS_STORIES,
          element: <SuccessStoriesPage/>
        },
        {
          path: MAIN_ROUTES.MARKETING_PLAN,
          element: <MarketingPlanPage/>
        },
        {
          path: MAIN_ROUTES.BLOG,
          element: <BlogPage/>
        },
        {
          path: "",
          element: <MyAccountLayout/>,
          children: [
            {
              path: MAIN_ROUTES.USER_INFO,
              element: <UserProfilePage/>
            },
            {
              path: MAIN_ROUTES.REFERRAL_STRUCTURE,
              element: <ReferralsStructurePage/>
            },
            {
              path: MAIN_ROUTES.WITHDRAWAL_REQUESTS,
              element: <WithdrawalRequestPage/>
            },
            {
              path: MAIN_ROUTES.REPORTS,
              element: <ReportsPage/>
            },
            {
              path: MAIN_ROUTES.EVENTS_CALENDAR,
              element: <EventsCalendarPage/>
            },
            {
              path: MAIN_ROUTES.MY_ORDERS,
              element: <PersonalOrdersPage/>
            },
          ]
        },
        {
          path: "*",
          element: <ErrorPage/>
        }
      ]
    },
  ]);

  useEffect(() => {
    if (!isTokenValid()) {
      localStorage.clear();
      window.location.reload();
    }
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <RouterProvider router={router}/>
        </PersistGate>
      </Provider>
    </QueryClientProvider>
  );
}

export default App;
