import {joiResolver} from "@hookform/resolvers/joi";
import CircularProgress from "@mui/material/CircularProgress";
import {AxiosError} from "axios";
import {jwtDecode} from "jwt-decode";
import React, {FC, useEffect, useRef, useState} from "react";
import {useForm} from "react-hook-form";
import {Link, useNavigate} from "react-router-dom";

import {getHttpErrorMessageByStatus} from "../../helpers/getHttpErrorMessageByErrorStatus";
import {storeTokens} from "../../helpers/storeTokens";
import {useAppDispatch} from "../../hooks/reduxHooks";
import {ICredentials} from "../../interfaces/ICredentials";
import {ITokenPayload} from "../../interfaces/ITokenPayload";
import {MAIN_ROUTES} from "../../routes/mainRoutes";
import {authService} from "../../services/auth.service";
import {deliveryActions} from "../../store/slices/deliverySlice";
import {userActions} from "../../store/slices/userSlice";
import {loginValidator} from "../../validators/login.validator";
import InputField from "../InputField/InputField";
import MainButtonRedBackground from "../MainButtonRedBackground/MainButtonRedBackground";
import styles from "./LoginForm.module.scss";

interface ILoginFormProps {
  type: "modal" | "page";
  isModalOpen?: boolean;
  handleCloseModal?: () => void;
  routingPath: string; // where to redirect the user after login
}

const LoginForm: FC<ILoginFormProps> = ({type, isModalOpen, handleCloseModal, routingPath}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const modalRef = useRef<HTMLDivElement>(null);

  const {
    register,
    handleSubmit,
    formState: {errors, isValid},
  } = useForm<ICredentials>({
    mode: "all",
    resolver: joiResolver(loginValidator),
  });

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const handleLogin = async (userData: ICredentials) => {
    try {
      setIsLoading(true);

      const {data: tokens} = await authService.login(userData);
      storeTokens(tokens);
      const {user_id, exp, iat} = jwtDecode(tokens.access) as ITokenPayload;

      dispatch(userActions.getById({user_id})); // get user info by id
      if (routingPath) {
        navigate(routingPath);
        dispatch(deliveryActions.getAllByCurrentUser());
      } else {
        navigate(MAIN_ROUTES.USER_INFO, {state: {user_id}}); // navigate to user profile
      }

      const isLoggedInString = localStorage.getItem("isLoggedIn");
      const isLoggedIn: {
        isLoggedIn: boolean,
        count: number
      } = isLoggedInString ? JSON.parse(isLoggedInString) : {isLoggedIn: false, count: 0};
      localStorage.setItem("isLoggedIn", JSON.stringify({isLoggedIn: true, count: isLoggedIn.count + 1}));

      localStorage.setItem("exp", JSON.stringify(exp));
      localStorage.setItem("iat", JSON.stringify(iat));

      handleCloseModal?.(); // hande close login modal
    } catch (e) {
      console.error(e);
      const error = e as AxiosError;

      const errorMessage = getHttpErrorMessageByStatus(error);
      setErrorMessage(errorMessage);
    } finally {
      setIsLoading(false);
    }
  }

  const handleClickOutside = (event: MouseEvent) => {
    if (type === "modal" && modalRef.current && !modalRef.current.contains(event.target as Node)) {
      handleCloseModal?.();
    }
  };

  const handleScroll = () => {
    const currentPosition = window.scrollY;

    if (type === "modal" && currentPosition > 450) {
      handleCloseModal?.();
    }
  };

  // if the user scrolls or clicks outside the modal, it closes
  useEffect(() => {
    if (isModalOpen) {
      document.addEventListener("scroll", handleScroll);
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("scroll", handleScroll);
    };
  }, [isModalOpen]);

  return (
    <div className={`${styles.loginForm} ${type === "modal" ? styles.modal : ""}`} ref={modalRef}>
      <form
        className={styles.loginForm_form}
        onSubmit={handleSubmit(handleLogin)}
      >
        <div className={styles.loginForm_form_flexEnd}>
          <div className={styles.loginForm_form_flexEnd_inputs}>
            <InputField
              width={"100%"}
              placeholder={""}
              label={"Email"}
              type={"text"} //type of input (password, text ...)
              name={"email"}
              register={register}
              errors={errors}
            />
            <InputField
              width={"100%"}
              placeholder={""}
              label={"Пароль"}
              type={"password"} //type of input (password, text ...)
              name={"password"}
              register={register}
              errors={errors}
              requestErrorMessage={errorMessage}
            />
          </div>
          <Link
            to={MAIN_ROUTES.PASSWORD_RECOVERY_STEP_1}
            onClick={() => isModalOpen && handleCloseModal?.()}
            className={styles.loginForm_form_flexEnd_forgotPassword}
          >Забули пароль</Link>
        </div>

        {isLoading ?
          <CircularProgress style={{color: "#9B232C"}} disableShrink/> :
          <MainButtonRedBackground
            type={"submit"} // button, submit or reset
            text={"Увійти"}
            width={"100%"}
            onClick={null}
            isDisabled={!isValid}
          />}

        <p className={styles.loginForm_form_registration}>
          Ще не маєте аккаунту?
          <Link
            className={styles.loginForm_form_registration_link}
            onClick={() => isModalOpen && handleCloseModal?.()}
            to={MAIN_ROUTES.REGISTRATION}> Зареєструватись</Link>
        </p>
      </form>
    </div>
  );
};

export default LoginForm;
