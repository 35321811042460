import React, {FC, useEffect, useState} from "react";

import {useAppDispatch, useAppSelector} from "../../hooks/reduxHooks";
import {productsActions} from "../../store/slices/productSlice";
import Pagination from "../Pagination/Pagination";
import ProductItemOrderRecommendations from "../ProductItemOrderRecommendations/ProductItemOrderRecommendations";
import styles from "./OfferedProducts.module.scss";

interface IOfferedProductsProps {
}

const OfferedProducts: FC<IOfferedProductsProps> = () => {
  const [tab, setTab] = useState(1);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const dispatch = useAppDispatch();
  const {products} = useAppSelector(state => state.cart);
  const {promoProducts, totalCountPromoProducts} = useAppSelector(state => state.products);

  useEffect(() => {
    const productIds = products.flatMap(obj => Array.from({length: obj.count}, () => obj.pk));
    const queryParams = productIds.map(id => `product=${id}`).join("&");

    dispatch(productsActions.getPromoProducts({queryParams, page: currentPage}));
  }, [currentPage]);

  return (
    <div className={styles.offeredProducts}>
      <ul className={styles.offeredProducts_tabs}>
        <li
          onClick={() => setTab(1)}
          className={`${styles.offeredProducts_tabs_item} ${tab === 1 ? styles.active : ""}`}
        >Акційні товари
        </li>
        {/*<li*/}
        {/*  onClick={() => setTab(2)}*/}
        {/*  className={`${styles.offeredProducts_tabs_item} ${tab === 2 ? styles.active : ""}`}*/}
        {/*>Спеціально для вас*/}
        {/*</li>*/}
      </ul>

      {tab === 1 &&
        <div className={styles.offeredProducts_additionalPoints}>
          {promoProducts.map((product, index) =>
            <ProductItemOrderRecommendations key={index} product={product}/>)}

          <Pagination
            currentPage={currentPage}
            onPageChange={setCurrentPage}
            totalItems={totalCountPromoProducts}
            position={"center"}
          />
        </div>}
    </div>
  );
};

export default OfferedProducts;
