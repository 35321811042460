import axios from "axios";

export const novaPoshtaService = {
  getAllAddressesByCityRef: (ref: string) => {
    const res = axios.post("https://api.novaposhta.ua/v2.0/json/", {
      "apiKey": process.env.REACT_APP_API_KEY_NOVA_POSHTA,
      "modelName": "Address",
      "calledMethod": "getWarehouses",
      "methodProperties": {
        "CityRef": ref
      }
    });

    return res;
  },
  getCityRefByName: async (cityName: string) => {
    const res = axios.post("https://api.novaposhta.ua/v2.0/json/", {
      "apiKey": process.env.REACT_APP_API_KEY_NOVA_POSHTA,
      "modelName": "AddressGeneral",
      "calledMethod": "getCities",
      "methodProperties": {
        "FindByString": cityName
      }
    });

    return res;
  }
}
