export const endpoints = {
  auth: {
    login: "auth/jwt/create/",
    create: "user/",
  },
  user: {
    base: "user/",
    currentUserChain: "user/current-user-chain/"
  },
  product: {
    base: "product/",
    promo: "product/promo-details/",
    allPromo: "product/all-promo-products/"
  },
  delivery: {
    base: "/delivery-method/",
  },
  categories: {
    base: "category/"
  },
  order: {
    base: "order/"
  },
  passwordRecovery: {
    sendLink: "user/send-password-recovery/",
    setNewPassword: "user/password-recovery/"
  },
  payment: {
    base: "/payment-request/"
  },
  images: {
    base: "config/website-header-images"
  }
}
