import {joiResolver} from "@hookform/resolvers/joi";
import React, {ChangeEvent, FC, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import toast from "react-hot-toast";

import close from "../../assets/close-white.svg";
import upload from "../../assets/upload-cloud.svg";
import avatar from "../../assets/user-avatar.svg";
import {useAppDispatch, useAppSelector} from "../../hooks/reduxHooks";
import {IUserDetailed} from "../../interfaces/IUserDetailed";
import {userActions} from "../../store/slices/userSlice";
import {updateUserValidator} from "../../validators/updateUser.validator";
import InputField from "../InputField/InputField";
import InputFieldMask from "../InputFieldMask/InputFieldMask";
import MainButtonRedBackground from "../MainButtonRedBackground/MainButtonRedBackground";
import MainButtonTransparentBackground from "../MainButtonTransparentBackground/MainButtonTransparentBackground";
import ProfilePhotoZoom from "../ProfilePhotoZoom/ProfilePhotoZoom";
import styles from "./ModalEditUserData.module.scss";

interface IModalEditPersonalDataProps {
  handleClose: () => void;
}

const ModalEditUserData: FC<IModalEditPersonalDataProps> = ({handleClose}) => {
  const [profilePhotoPreview, setProfilePhotoPreview] = useState<string | null>(null);
  const [profilePhotoFile, setProfilePhotFile] = useState<File | null>(null);
  const [isPreviewOpen, setIsPreviewOpen] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const dispatch = useAppDispatch();
  const {user} = useAppSelector(state => state.user);
  const {photo, mobile_nummer, email, city, uuid, first_name, last_name} = user!;

  const {
    register,
    handleSubmit,
    formState: {errors, isValid},
    reset,
    setValue,
    watch
  } = useForm<Partial<IUserDetailed>>({
    mode: "all",
    resolver: joiResolver(updateUserValidator),
  });

  const mobileNumber = watch("mobile_nummer");

  useEffect(() => {
    setValue("mobile_nummer", mobile_nummer);
    setValue("city", city);
    setValue("email", email);
    setValue("first_name", first_name);
    setValue("last_name", last_name);
  }, []);

  const handleGetProfilePhotoFromInput = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;

    if (file) {
      const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];
      if (!allowedTypes.includes(file.type)) {
        setError("Формат файлу має бути PNG, JPG або JPEG.");
        return;
      }

      const maxSizeInBytes = 20 * 1024 * 1024; // 20MB
      if (file.size > maxSizeInBytes) {
        setError("Максимальний розмір файлу має бути менше 20MB.");
        return;
      }

      const photoUrl = URL.createObjectURL(file);
      setProfilePhotoPreview(photoUrl);
      setProfilePhotFile(file);
    }
  }

  const handleOpenFileExplorer = () => {
    const fileInput = document.getElementById("profile-photo");

    if (fileInput) {
      fileInput.click();
    }
  }

  const handleCreateFormData = (userData: Partial<IUserDetailed>) => {
    let formData = new FormData();

    const {mobile_nummer, city, first_name, last_name} = userData;

    if (profilePhotoFile) {
      formData.append("photo", profilePhotoFile);
    }
    formData.append("mobile_nummer", mobile_nummer!); // ! it indicates that userData values not undefined
    formData.append("last_name", last_name!); // ! it indicates that userData values not undefined
    formData.append("first_name", first_name!); // ! it indicates that userData values not undefined
    formData.append("city", city!); // ! it indicates that userData values not undefined

    return formData;
  }

  const handleUpdateUserData = (data: Partial<IUserDetailed>) => {
    const formData = handleCreateFormData(data);

    dispatch(userActions.updateById({user_id: uuid, dataForUpdate: formData}));
    toast.success("Ваші дані успішно оновлено!", {
      id: "update"
    });

    handleClose(); // close the modal
  }

  useEffect(() => {
    if (error) {
      toast.error(error, {id: "update"});
      setError("");
    }
  }, [error]);

  return (
    <div className={styles.modal}>
      <div className={styles.modal_title}>
        <h3>Особиста Інформація</h3>
        <img
          draggable={false}
          onClick={handleClose}
          src={close}
          alt="close"
        />
      </div>

      <form
        className={styles.modal_form}
        onSubmit={handleSubmit(handleUpdateUserData)}
      >
        <div className={styles.modal_form_photoContainer}>
          <img
            draggable={false}
            className={styles.modal_form_photoContainer_preview}
            src={profilePhotoPreview || (photo ? photo : avatar)}
            alt="user`s photo"
            style={{cursor: profilePhotoPreview ? "pointer" : ""}}
            onClick={() => (profilePhotoPreview || photo) && setIsPreviewOpen(true)}
          />
          <div
            onClick={handleOpenFileExplorer}
            className={styles.modal_form_photoContainer_add}
          >
            <img draggable={false} src={upload} alt="upload cloud"/>
            <p>Натисніть щоб завантажити нове фото</p>
          </div>
          <input
            id={"profile-photo"}
            type="file"
            style={{display: "none"}}
            onChange={handleGetProfilePhotoFromInput}
          />
        </div>

        <InputField
          width={"100%"}
          label={"Email"}
          type={"email"} // type of input (text, number ...)
          name={"email"}
          register={register}
          errors={errors}
          placeholder={""}
          disabled={true}
        />
        <InputField
          width={"100%"}
          label={"Ім'я"}
          type={"text"} // type of input (text, number ...)
          name={"first_name"}
          register={register}
          errors={errors}
          placeholder={""}
        />
        <InputField
          width={"100%"}
          label={"Прізвище"}
          type={"text"} // type of input (text, number ...)
          name={"last_name"}
          register={register}
          errors={errors}
          placeholder={""}
        />
        <InputField
          width={"100%"}
          label={"Місто"}
          type={"text"} // type of input (text, number ...)
          name={"city"}
          register={register}
          errors={errors}
          placeholder={""}
        />

        <InputFieldMask
          errors={errors}
          name={"mobile_nummer"}
          placeholder={"+38 (0XX) XXX-XX-XX"}
          mobile_number_value={mobileNumber!} //it indicates that mobileNumber not null
          register={register}
        />

        <div className={styles.modal_form_buttons}>
          <MainButtonTransparentBackground
            text={"Скасувати"}
            width={"100%"}
            onClick={handleClose}
            type={"button"}
          />
          <MainButtonRedBackground
            text={"Зберегти Зміни"}
            width={"100%"}
            onClick={null}
            type={"submit"}
            isDisabled={Object.keys(errors).length > 0}
          />
        </div>
      </form>


      {isPreviewOpen &&
        <ProfilePhotoZoom
          photoURL={profilePhotoPreview || photo}
          handleClose={() => setIsPreviewOpen(false)}
        />}
    </div>
  );
};

export default ModalEditUserData;
