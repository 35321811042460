import {MAIN_ROUTES} from "../routes/mainRoutes";
import {userActions} from "../store/slices/userSlice";

// Usage - to prevent error when user is logged in different accounts in the same browser

export const isLoggedInListener = (dispatch: any, navigate: any) => {
  // isLoggedIn: {isLoggedIn: boolean, count: number}

  window.addEventListener("storage", (event) => {
    if (event.key === "isLoggedIn") {
      const isLoggedInString = localStorage.getItem("isLoggedIn");
      const isLoggedIn: {
        isLoggedIn: boolean,
        count: number
      } = isLoggedInString ? JSON.parse(isLoggedInString) : {isLoggedIn: false, count: 0};

      if (isLoggedIn && isLoggedIn.isLoggedIn && isLoggedIn.count > 1) {
        dispatch(userActions.reset());
        navigate(MAIN_ROUTES.HOME);
      }
    }
  });
}
