import React, {FC} from "react";

import truck from "../../assets/truck.svg";
import styles from "./DeliveryMethods.module.scss";

interface IDeliveryMethodsProps {
  hasBonusPrice: boolean;
}

const DeliveryMethods: FC<IDeliveryMethodsProps> = ({hasBonusPrice}) => {
  return (
    <div className={styles.deliveryMethods}>
      <div className={styles.deliveryMethods_title}>
        <img src={truck} alt="truck" draggable={false}/>
        <p>Способи доставки</p>
      </div>

      <div className={styles.deliveryMethods_method}>
        <p className={styles.deliveryMethods_method_title}>Нова Пошта</p>
        <div className={styles.deliveryMethods_method_prices}>
          <p>За тарифом перевізника</p>
          {!hasBonusPrice && <p>Від 1300грн - безкоштовно!</p>}
        </div>
      </div>
      <div className={styles.deliveryMethods_method}>
        <p className={styles.deliveryMethods_method_title}>Укрпошта</p>
        <div className={styles.deliveryMethods_method_prices}>
          <p>За тарифом перевізника</p>
        </div>
      </div>
    </div>
  );
};

export default DeliveryMethods;
