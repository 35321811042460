import React, {FC, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

import unAuthUser from "../../assets/user.svg";
import avatar from "../../assets/user-avatar.svg";
import {useAppSelector} from "../../hooks/reduxHooks";
import {MAIN_ROUTES} from "../../routes/mainRoutes";
import styles from "./UserDetails.module.scss";

interface IUserDetailsProps {
  setIsModalOpen: (value: boolean) => void;
}

const UserDetails: FC<IUserDetailsProps> = ({setIsModalOpen}) => {
  const [deviceWidth, setDeviceWidth] = useState<number>(window.innerWidth);

  const navigate = useNavigate();

  const {user} = useAppSelector(state => state.user);
  const {first_name, last_name, photo} = user || {}; // it prevents error 'reading null'

  const handleResize = () => {
    setDeviceWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleNavigate = (path: string) => {
    navigate(path);
  }

  const handleOpenLoginModalOrNavigate = () => {
    if (!(last_name && first_name)) {
      if (window.innerWidth > 768) {
        setIsModalOpen(true);
      } else {
        handleNavigate(MAIN_ROUTES.LOGIN);
      }
    } else {
      handleNavigate(MAIN_ROUTES.USER_INFO);
    }
  };

  return (
    (last_name && first_name) ?
      <div
        className={styles.user}
        onClick={handleOpenLoginModalOrNavigate}
      >
        <button className={`${styles.user_button} ${(last_name || first_name) ? styles.isUser : ""}`}>
          <img src={photo || avatar} alt="user icon" draggable={false}/>
        </button>
        <p className={styles.user_info}>{deviceWidth > 1280 ? `${first_name} ${last_name}` : first_name}</p>
      </div> :
      <div
        className={styles.user}
        onClick={handleOpenLoginModalOrNavigate}
      >
        <button className={styles.user_button}>
          <img src={unAuthUser} alt="user icon" draggable={false}/>
        </button>
        <p className={styles.user_info}>Увійти</p>
      </div>
  );
};

export default UserDetails;
