import "rsuite/dist/rsuite-no-reset.min.css";
import "../../utils/datePicker.scss";

import React, {FC, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {DatePicker} from "rsuite";

import chevron from "../../assets/chevron-left.svg";
import ReportStatistics from "../../components/ReportStatistics/ReportStatistics";
import styles from "./ReportsPage.module.scss";

interface IReportsPageProps {
}

const ReportsPage: FC<IReportsPageProps> = () => {
  const navigate = useNavigate();

  const handleNavigateBack = () => {
    navigate(-1);
  }

  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth"
    });
  }, []);

  return (
    <div className={styles.reportPage}>
      <div className={styles.reportPage_title}>
        <img onClick={handleNavigateBack} src={chevron} alt="chevron"/>
        <h1>МОЇ <span>ЗВІТИ</span></h1>
      </div>

      <div className={styles.reportPage_content}>
        <div className={styles.reportPage_content_dates}>
          <DatePicker/>
          <span className={styles.reportPage_content_dates_divider}>-</span>
          <DatePicker/>
        </div>
        <ReportStatistics/>
      </div>
    </div>
  );
};

export default ReportsPage;
