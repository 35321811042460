import React, {FC, useEffect} from "react";
import toast, {Toaster} from "react-hot-toast";
import {useParams} from "react-router-dom";

import Loader from "../../components/Loader/Loader";
import ProductDescription from "../../components/ProductDescription/ProductDescription";
import ProductDetailedInfo from "../../components/ProductDetailedInfo/ProductDetailedInfo";
import ProductsBlockContainer from "../../components/ProductsBlockContainer/ProductsBlockContainer";
import {useAppDispatch, useAppSelector} from "../../hooks/reduxHooks";
import {productsActions} from "../../store/slices/productSlice";
import ErrorPage from "../ErrorPage/ErrorPage";
import styles from "./ProductPage.module.scss";

interface IProductPageProps {
}

const ProductPage: FC<IProductPageProps> = () => {
  const {id} = useParams<{ id: string }>(); // get product id from url

  const dispatch = useAppDispatch();
  const {product, status, errorStatus} = useAppSelector(state => state.products);

  const getProductById = () => {
    try {
      dispatch(productsActions.getById({id: Number(id)}));
    } catch (e) {
      console.error(e);
    }
  }

  useEffect(() => {
    // scroll to the top
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });

    getProductById();
  }, [id]);

  useEffect(() => {
    return () => {
      toast.remove("cart");
    }
  }, []);

  if (errorStatus === 404) {
    return <ErrorPage/>;
  }

  if (status === "loading") {
    return <Loader/>;
  }

  if (product) {
    return (
      <div className={styles.wrapper}>
        <div className={styles.wrapper_productPage}>
          <ProductDetailedInfo product={product!}/>

          <div className={styles.wrapper_productPage_description}>
            <ProductDescription
              description={product!.description!}
            />
            {/*<ProductFeedbacks/>*/}
          </div>


          {product?.recommended_to_buy_with.length > 0 &&
            <ProductsBlockContainer
              products={product!.recommended_to_buy_with}
              title={"З цим товаром купують"}
            />}
        </div>

        <Toaster/>
      </div>
    );
  }

  return null;
};

export default ProductPage;
