import React, {FC} from "react";

import close from "../../assets/close-white.svg";
import styles from "./ProfilePhotoZoom.module.scss";

interface IProfilePhotoZoomProps {
  photoURL: string;
  handleClose: () => void;
}

const ProfilePhotoZoom: FC<IProfilePhotoZoomProps> = ({photoURL, handleClose}) => {
  return (
    <div className={styles.backgroundOverlay}>
      <img
        className={styles.close}
        src={close}
        alt="close"
        onClick={handleClose}
        draggable={false}
      />

      <img
        className={styles.backgroundOverlay_content}
        src={photoURL}
        alt={"user`s photo"}
        draggable={false}
      />
    </div>
  );
};

export default ProfilePhotoZoom;
