import React, {ChangeEventHandler, FC} from "react";

import styles from "./CustomCheckbox.module.scss";

interface ICustomCheckboxProps {
    label: string;
    id: string;
    isChecked: boolean;
    setIsChecked: ChangeEventHandler<HTMLInputElement>;
    position: "center" | "flex-start"
}

const CustomCheckbox: FC<ICustomCheckboxProps> = ({label, id, isChecked, setIsChecked, position}) => {
  return (
    <div
      className={styles.checkbox}
      style={{alignItems: position}}
    >
      <input
        className={styles.checkbox_input}
        type="checkbox"
        id={id}
        checked={isChecked}
        onChange={setIsChecked}
      />
      <label
        className={styles.checkbox_label}
        htmlFor={id}
      >{label}</label>
    </div>
  );
};

export default CustomCheckbox;
