import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {AxiosError} from "axios";

import {IPersonalOrderItem} from "../../interfaces/IPersonalOrderItem";
import {IResApiPagination} from "../../interfaces/IResApiPagination";
import {orderService} from "../../services/order.service";

interface IState {
  orders: IPersonalOrderItem[];
  totalCountOfOrders: number;
  status: "success" | "loading";
}

const initialState: IState = {
  orders: [],
  totalCountOfOrders: 0,
  status: "success",
}

const getByCurrentUser = createAsyncThunk<IResApiPagination<IPersonalOrderItem[]>, {
  page: number
}>("orderSlice/getByCurrentUser",
  async ({page}, {rejectWithValue}) => {
    try {
      const {data} = await orderService.getByCurrentUser(page);

      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  });

const orderSlice = createSlice({
  name: "orderSlice",
  initialState,
  reducers: {
    deleteById: (state, action) => {
      const uuid = action.payload;

      state.orders = state.orders.filter((order) => order.uuid !== uuid);
    },
    reset: () => initialState
  },
  extraReducers: builder => builder
    .addCase(getByCurrentUser.fulfilled, (state, action) => {
      state.orders = action.payload.results;
      state.totalCountOfOrders = action.payload.count;
      state.status = "success";
    })
    .addCase(getByCurrentUser.pending, (state, action) => {
      state.status = "loading";
    })
});

const {reducer: orderReducer, actions} = orderSlice;

const orderActions = {
  ...actions,
  getByCurrentUser,
}

export {
  orderActions,
  orderReducer,
}
