import React, {FC, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

import noResults from "../../assets/no-filtered-products-by-category.svg";
import {MAIN_ROUTES} from "../../routes/mainRoutes";
import MainButtonTransparentBackground from "../MainButtonTransparentBackground/MainButtonTransparentBackground";
import styles from "./EmptyStateProductsSearch.module.scss";

interface IEmptyStateProductsSearchProps {
}

const EmptyStateProductsSearch: FC<IEmptyStateProductsSearchProps> = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const navigate = useNavigate();

  return (
    <div className={styles.emptyState}>
      <div className={styles.emptyState_title}>
        <img src={noResults} alt="no results by filtering icon" draggable={false}/>
        <h2>По вашому запиту нічого не знайдено </h2>
      </div>

      <MainButtonTransparentBackground
        text={"На Головну"}
        width={windowWidth > 768 ? "auto" : "100%"}
        onClick={() => navigate(MAIN_ROUTES.HOME)}
        type={"button"}
      />
    </div>
  );
};

export default EmptyStateProductsSearch;
