import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {AxiosError} from "axios";

import {IUserChainStructure} from "../../interfaces/IUserChainStructure";
import {IUserDetailed} from "../../interfaces/IUserDetailed";
import {userService} from "../../services/user.service";

interface IState {
  user: IUserDetailed | null;
  status: "success" | "loading";

  // chain - referral structure
  chainStructure: IUserChainStructure | null;
  currentUser: IUserChainStructure | null;
  currentLevel: number;
  breadcrumbs: string[];
  usersChainDetails: IUserChainStructure[];

  // referral structure - tree view
  currentLevelDepth: string;
  childrenTreeView: IUserChainStructure | null;
}

const initialState: IState = {
  user: null,
  status: "success",

  // chain
  chainStructure: null,
  currentUser: null,
  currentLevel: 0,
  breadcrumbs: [],
  usersChainDetails: [],

  // referral structure - tree view
  currentLevelDepth: "1",
  childrenTreeView: null,
}

const getById = createAsyncThunk<IUserDetailed, { user_id: string }>("userSlice/getById",
  async ({user_id}, {rejectWithValue}) => {
    try {
      const {data} = await userService.getById(user_id);

      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  });

const updateById = createAsyncThunk<IUserDetailed, {
  user_id: string,
  dataForUpdate: FormData
}>("userSlice/updateById",
  async ({user_id, dataForUpdate}, {rejectWithValue}) => {
    try {
      const {data} = await userService.updateById(user_id, dataForUpdate);

      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  });

const getChainByCurrentUser = createAsyncThunk<IUserChainStructure, void>("userSlice/getChainByCurrentUser",
  async (_, {rejectWithValue}) => {
    try {
      const {data} = await userService.getChainByCurrentUser();

      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  });

const getChildrenByCurrentUserTreeView = createAsyncThunk<IUserChainStructure, {
  level: string
}>("userSlice/getChildrenByCurrentUserTreeView",
  async ({level}, {rejectWithValue}) => {
    try {
      const {data} = await userService.getChildrenByCurrentUserTreeView(level);

      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  });

const getChildrenByCurrentUserListView = createAsyncThunk<IUserChainStructure, {
  uuid: string
}>("userSlice/getChildrenByCurrentUserListView",
  async ({uuid}, {rejectWithValue}) => {
    try {
      const {data} = await userService.getChildrenByCurrentUserListView(uuid);

      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  });

const userSlice = createSlice({
  name: "userSlice",
  initialState,
  reducers: {
    setCurrentUser: (state, action) => {
      state.currentUser = action.payload;
    },
    setCurrentLevel: (state, action) => {
      state.currentLevel = action.payload;
    },
    addBreadcrumbs: (state, action) => {
      state.breadcrumbs.push(action.payload);
    },
    addUserChainDetails: (state, action) => {
      state.usersChainDetails.push(action.payload);
    },
    initializeUserChainDetails: (state, action) => {
      state.usersChainDetails = action.payload;
    },
    resetUserChainDetails: (state) => {
      state.usersChainDetails = [];
    },
    initializeBreadcrumbs: (state, action) => {
      state.breadcrumbs = action.payload;
    },
    setCurrentLevelDepth: (state, action) => {
      state.currentLevelDepth = action.payload;
    },
    reset: () => initialState
  },
  extraReducers: builder => builder
    .addCase(getById.fulfilled, (state, action) => {
      state.user = action.payload;
      state.status = "success";
    })
    .addCase(getById.pending, (state, action) => {
      state.status = "loading";
    })

    .addCase(updateById.fulfilled, (state, action) => {
      state.user = {...state.user, ...action.payload};
    })

    .addCase(getChainByCurrentUser.fulfilled, (state, action) => {
      state.chainStructure = action.payload;
      state.status = "success";
    })
    .addCase(getChainByCurrentUser.pending, (state, action) => {
      state.status = "loading";
    })

    .addCase(getChildrenByCurrentUserTreeView.fulfilled, (state, action) => {
      state.childrenTreeView = action.payload;
      state.status = "success";
    })
    .addCase(getChildrenByCurrentUserTreeView.pending, (state, action) => {
      state.status = "loading";
    })

    .addCase(getChildrenByCurrentUserListView.fulfilled, (state, action) => {
      state.usersChainDetails.push(action.payload);
      state.currentUser = action.payload;
      state.status = "success";
    })
    .addCase(getChildrenByCurrentUserListView.pending, (state, action) => {
      state.status = "loading";
    })
});

const {reducer: userReducer, actions} = userSlice;

const userActions = {
  ...actions,
  getById,
  updateById,
  getChainByCurrentUser,
  getChildrenByCurrentUserTreeView,
  getChildrenByCurrentUserListView,
}

export {
  userActions,
  userReducer,
}
