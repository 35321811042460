import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {AxiosError} from "axios";

import {IDeliveryAddress} from "../../interfaces/IDeliveryAddress";
import {IResApiPagination} from "../../interfaces/IResApiPagination";
import {deliveryService} from "../../services/delivery.service";

interface IState {
  deliveryAddresses: IDeliveryAddress[];
  deliveryAddress: IDeliveryAddress | null;
}

const initialState: IState = {
  deliveryAddresses: [],
  deliveryAddress: null,
}

const getAllByCurrentUser = createAsyncThunk<IResApiPagination<IDeliveryAddress[]>, void>("deliverySlice/getAllByCurrentUser",
  async (_, {rejectWithValue}) => {
    try {
      const {data} = await deliveryService.getAllByCurrentUser();

      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  });

const deliverySlice = createSlice({
  name: "deliverySlice",
  initialState,
  reducers: {
    addDeliveryAddress: (state, action) => {
      state.deliveryAddresses.push(action.payload);
    },
    deleteDeliveryAddressById: (state, action) => {
      const id = action.payload;

      const deliveryAddresses = state.deliveryAddresses.filter((deliveryAddress) => deliveryAddress.pk !== id);
      state.deliveryAddresses = [...deliveryAddresses];
    },
    setDeliveryAddress: (state, action) => {
      state.deliveryAddress = action.payload;
    },
    reset: () => initialState
  },
  extraReducers: builder => builder
    .addCase(getAllByCurrentUser.fulfilled, (state, action) => {
      state.deliveryAddresses = action.payload.results;
    })
});

const {reducer: deliveryReducer, actions} = deliverySlice;

const deliveryActions = {
  ...actions,
  getAllByCurrentUser
}

export {
  deliveryActions,
  deliveryReducer,
}
