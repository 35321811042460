// The token is valid for 7 days
export const isTokenValid = () => {
  const currentTimestamp = Math.floor(Date.now() / 1000);
  const iat = parseInt(localStorage.getItem("iat") || "0", 10);
  const exp = parseInt(localStorage.getItem("exp") || "0", 10);

  if (iat === 0 || exp === 0) {
    return true;
  }

  return !(currentTimestamp < iat || currentTimestamp > exp);
}
