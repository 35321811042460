import React from "react";
import {FieldError, FieldErrors, FieldValues, Path, UseFormRegister} from "react-hook-form";
import InputMask from "react-input-mask";

import styles from "./InputFieldMask.module.scss";

interface IInputFieldMaskProps<T extends FieldValues> {
    name: Path<T>;
    register: UseFormRegister<T>;
    errors: FieldErrors<T>;
    placeholder: string;
    mobile_number_value: string;
}

const InputFieldMask = <T extends FieldValues>({
  name,
  register,
  errors,
  placeholder,
  mobile_number_value
}: IInputFieldMaskProps<T>) => {
  return (
    <label className={styles.label}>
            Номер телефону
      <InputMask
        className={`${styles.input} ${errors[name] || mobile_number_value?.includes("_") ? styles.error : ""}`}
        {...register(name)}
        mask="+38 (099) 999-99-99"
        placeholder={placeholder}
      />

      {errors[name] && <span className={styles.errorText}>{(errors[name] as FieldError).message}</span> ||
                (mobile_number_value?.includes("_") && <span
                  className={styles.errorText}>Неправильний номер телефону</span>)}
    </label>
  );
};

export default InputFieldMask;
