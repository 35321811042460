import {joiResolver} from "@hookform/resolvers/joi";
import {Box, CircularProgress, Grow} from "@mui/material";
import {AxiosError} from "axios";
import React, {ChangeEvent, FC, useEffect, useState} from "react";
import {FieldError, useForm} from "react-hook-form";
import toast from "react-hot-toast";

import {EDeliveryType} from "../../enums/EDeliveryType";
import {useAppDispatch, useAppSelector} from "../../hooks/reduxHooks";
import {deliveryService} from "../../services/delivery.service";
import {deliveryActions} from "../../store/slices/deliverySlice";
import {newDeliveryAddressValidator} from "../../validators/newDeliveryAddress.validator";
import CustomDropdown from "../CustomDropdown/CustomDropdown";
import DeliveryAddresses from "../DeliveryAddresses/DeliveryAddresses";
import DepartmentAddressesAccordion from "../DepartmentAddressesAccordion/DepartmentAddressesAccordion";
import InputField from "../InputField/InputField";
import MainButtonRedBackground from "../MainButtonRedBackground/MainButtonRedBackground";
import MainButtonTransparentBackground from "../MainButtonTransparentBackground/MainButtonTransparentBackground";
import PopularCitiesAccordion from "../PopularCitiesAccordion/PopularCitiesAccordion";
import styles from "./ModalNewDeliveryAddress.module.scss";

interface IModalNewShippingAddressProps {
  handleClose: () => void;
}

interface IDataForm {
  delivery_method_name: string;
  city: string;
  number_of_department: string;
}

const ModalNewDeliveryAddress: FC<IModalNewShippingAddressProps> = ({handleClose}) => {
  const [isButtonPressed, setIsButtonPressed] = useState<boolean>(false);
  const [deliverySupplierName, setDeliverySupplierName] = useState<string>("");

  const [department, setDepartment] = useState<{
    description: string;
    ref: string;
    cityRef: string;
  }>({description: "", ref: "", cityRef: ""});
  const [searchValue, setSearchValue] = useState<string>("");
  const [allDepartmentsInCity, setAllDepartmentsInCity] = useState<{
    description: string;
    ref: string;
    cityRef: string;
  }[]>([]);
  const [filteredDepartmentsBySearchValue, setFilteredDepartmentsBySearchValue] = useState<{
    description: string;
    ref: string;
    cityRef: string;
  }[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    formState: {errors, isValid},
    setValue,
    watch
  } = useForm<IDataForm>({
    mode: "all",
    resolver: joiResolver(newDeliveryAddressValidator)
  });
  const city = watch("city");

  const dispatch = useAppDispatch();
  const {user} = useAppSelector(state => state.user);
  const {deliveryAddresses} = useAppSelector(state => state.delivery);

  const handleAddDeliveryAddress = async (data: IDataForm) => {
    try {
      const {first_name, last_name, mobile_nummer, email} = user!;

      const {delivery_method_name, city} = data;

      // numberOfDepartment: for ex. Відділення №2: вул. Городоцька, 359 => 2
      const match = department.description.match(/№(\d+)/); // get number after "№"
      const numberOfDepartment = Number(searchValue); // for "Укрпошта"

      const deliveryAddress = {
        delivery_method_name,
        city,
        id_of_department: department.ref,
        id_of_city: department.cityRef,
        number_of_department: deliverySupplierName === "Нова пошта" ? Number(match?.[1]) : numberOfDepartment,
        delivery_supplier_name: deliverySupplierName,
        receiver_first_name: first_name,
        receiver_last_name: last_name,
        receiver_mobile: mobile_nummer,
        receiver_email: email,
        delivery_type: "DEPARTMENT" as EDeliveryType,
        description: department.description,
      }

      // add new delivery method
      const res = await deliveryService.create(deliveryAddress);

      // if request was successful - add delivery method to the redux store
      dispatch(deliveryActions.addDeliveryAddress(res.data));

      toast.success("Адресу успішно додано");
    } catch (e) {
      const error = e as AxiosError;
      toast.error(JSON.stringify(error?.response?.data || error?.message));
    } finally {
      handleClose(); //close modal
    }
  }

  useEffect(() => {
    if (searchValue.length > 0) {
      const filteredDepartments = allDepartmentsInCity.filter(department =>
        department.description.toLowerCase().includes(searchValue.toLowerCase())
      );

      setFilteredDepartmentsBySearchValue(filteredDepartments);
    }
  }, [searchValue]);

  useEffect(() => {
    // clear data related to another delivery supplier name(Нова пошта або Укрпошта)
    setSearchValue("");
    setFilteredDepartmentsBySearchValue([]);
  }, [deliverySupplierName]);

  useEffect(() => {
    if (deliveryAddresses.length === 0) {
      setIsButtonPressed(true);
    }
  }, [deliveryAddresses]);

  return (
    <form
      className={styles.modal}
      onSubmit={handleSubmit(handleAddDeliveryAddress)}
    >
      <DeliveryAddresses
        handleClose={handleClose} //close modal
        type={"delete"}
        title={"Дані доставки"}
      />

      <div>
        {(deliveryAddresses.length !== 0 && !isButtonPressed) &&
          <MainButtonRedBackground
            text={"Додати Нову Адресу +"}
            width={"100%"}
            onClick={() => setIsButtonPressed(true)}
            type={"button"}
            isDisabled={false}
          />}
      </div>

      {isButtonPressed &&
        <Grow in={isButtonPressed}
          style={{transformOrigin: "0 0 0"}}
          {...(isButtonPressed ? {timeout: 1000} : {})}
        >
          <div className={styles.modal_form}>
            <InputField
              width={"100%"}
              label={"Назва адреси"}
              type={"text"}
              name={"delivery_method_name"}
              register={register}
              errors={errors}
              placeholder={""}
            />

            <CustomDropdown
              width={"auto"}
              option={deliverySupplierName}
              setOption={setDeliverySupplierName}
              defaultOption={"Оберіть спосіб доставки"}
              data={["Нова пошта", "Укрпошта"]}
              label={"Спосіб доставки"}
            />

            {(!!deliverySupplierName) &&
              <Grow in={!!deliverySupplierName}
                style={{transformOrigin: "0 0 0"}}
                {...(deliverySupplierName ? {timeout: 1000} : {})}
              >
                <div>
                  {(deliverySupplierName === "Нова пошта" ?
                    <PopularCitiesAccordion
                      department={department}
                      setDepartment={setDepartment}
                      location={city}
                      name={"city"}
                      errors={errors}
                      setValue={setValue}
                      setAllDepartmentsInCity={setAllDepartmentsInCity}
                      setIsLoading={setIsLoading}
                    /> :
                    <InputField
                      width={"100%"}
                      label={"Місто"}
                      type={"text"}
                      name={"city"}
                      register={register}
                      errors={errors}
                      placeholder={""}
                    />)}
                </div>
              </Grow>}

            {isLoading ?
              <Box sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                <CircularProgress style={{color: "#9B232C"}}/>
              </Box> :
              ((!!city) &&
                <Grow
                  in={!!city}
                  style={{transformOrigin: "0 0 0"}}
                  {...(city ? {timeout: 1000} : {})}
                >
                  <div>
                    {deliverySupplierName === "Нова пошта" ?
                      <DepartmentAddressesAccordion
                        errors={errors}
                        name={"number_of_department"}
                        setValue={setValue}
                        allDepartmentsInCity={allDepartmentsInCity}
                        filteredDepartmentsBySearchValue={filteredDepartmentsBySearchValue}
                        searchValue={searchValue}
                        setSearchValue={setSearchValue}
                        department={department}
                        setDepartment={setDepartment}
                        city={city}
                      /> :
                      <label>
                        Поштовий індекс
                        <input
                          className={errors.number_of_department ? styles.error : ""}
                          type="text"
                          {...register("number_of_department")}
                          value={searchValue}
                          onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            setSearchValue(event.target.value);
                            setValue("number_of_department", event.target.value, {shouldValidate: true});
                            setDepartment({description: "", ref: "", cityRef: ""});
                          }}
                        />
                        {errors.number_of_department &&
                          <span>{(errors.number_of_department as FieldError).message}</span>}
                      </label>}
                  </div>
                </Grow>)}
          </div>
        </Grow>}

      <div className={styles.modal_buttons}>
        <MainButtonTransparentBackground
          text={"Скасувати"}
          width={"100%"}
          onClick={handleClose}
          type={"button"}
        />
        <MainButtonRedBackground
          text={"Зберегти Зміни"}
          width={"100%"}
          onClick={null}
          type={"submit"}
          isDisabled={!isValid}
        />
      </div>
    </form>
  );
};

export default ModalNewDeliveryAddress;
