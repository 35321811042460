import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./MainSwiper.scss";

import React, {FC, useEffect, useState} from "react";
import {Autoplay, Navigation, Pagination} from "swiper/modules";
import {Swiper, SwiperSlide} from "swiper/react";

import {useAppSelector} from "../../hooks/reduxHooks";

interface IMainSwiperProps {
}

const MainSwiper: FC<IMainSwiperProps> = () => {
  const [deviceWidth, setDeviceWidth] = useState<number>(window.innerWidth);

  const handleResize = () => {
    setDeviceWidth(window.innerWidth);
  };

  const {bannerImages} = useAppSelector(state => state.images);

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const filteredImages = bannerImages.filter(image => {
    if (deviceWidth < 768) {
      return image.includes("mobile");
    } else {
      return !image.includes("mobile");
    }
  });

  return (
    <Swiper
      style={{width: "100%", height: "100%"}}
      spaceBetween={30}
      centeredSlides={true}
      autoplay={{
        delay: 5000,
        disableOnInteraction: false,
      }}
      pagination={{
        clickable: true,
      }}
      navigation={true}
      modules={[Autoplay, Pagination, Navigation]}
      className="mySwiper"
    >
      {filteredImages.map((image, index) => (
        <SwiperSlide key={index}>
          <img
            className={"main-swiper-photo"}
            src={image}
            alt={`banner image ${index + 1}`}
            draggable={false}
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default MainSwiper;
