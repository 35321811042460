export const levelNames = [
  "Консультант",            // 1 level
  "Спеціаліст",             // 2 level
  "Дистриб'ютор",           // 3 level
  "Менеджер групи",         // 4 level
  "Старший менеджер",       // 5 level
  "Лідер",                  // 6 level
  "ВІП лідер",              // 7 level
  "Президент",              // 8 level
  "Золотий президент",      // 9 level
  "Золотий президент",      // 10 level
  "Платиновий президент",   // 11 level
  "Платиновий президент",   // 12 level
];
