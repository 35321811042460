import React, {FC, useEffect} from "react";
import {useNavigate} from "react-router-dom";

import cart from "../../assets/cart.svg";
import {useAppDispatch, useAppSelector} from "../../hooks/reduxHooks";
import {IProductDetailed} from "../../interfaces/IProductDetailed";
import {MAIN_ROUTES} from "../../routes/mainRoutes";
import {productsService} from "../../services/products.service";
import {cartActions} from "../../store/slices/cartSlice";
import styles from "./CartDetails.module.scss";

interface ICartDetailsProps {
}

const CartDetails: FC<ICartDetailsProps> = () => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const {totalPrice, productsIds, products, totalPoints} = useAppSelector(state => state.cart);
  const {user} = useAppSelector(state => state.user);

  // get total price for all products from cart
  const getProductById = async (id: number) => {
    try {
      const res = await productsService.getById(id);
      const product = res.data as IProductDetailed;

      const productFromCart = products.find((product) => product.pk === id);

      dispatch(cartActions.addProductDescription({
        pk: product.pk,
        name: product.name,
        price: product.price,
        points_to_count: product.points_to_count,
        main_image: product.images[0],
        discount_percent: product.discount_percent,
        can_pay_with_bonus: product.can_pay_with_bonus,
        bonus_price: product.bonus_price,
        count: productFromCart?.count || 1, // count of this product
      }));
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    const getAllProductsDescription = async () => {
      try {
        dispatch(cartActions.deleteAllProductsDescription());

        await Promise.all(productsIds.map(productId => getProductById(productId)));
      } catch (error) {
        console.error(error);
      }
    };

    getAllProductsDescription()
      .then(() => dispatch(cartActions.calculateOrderSummary({isUser: !!user}))); // calculate total points, total price, total discount price
  }, [productsIds]);

  const canPayWithBonus = products.every(product => product.can_pay_with_bonus);

  const totalSumBonusPrice = products.reduce((total, product) => {
    return total + (Number(product.bonus_price) * product.count);
  }, 0);

  return (
    <div
      className={styles.cart}
      onClick={() => navigate(MAIN_ROUTES.CART)}
    >
      <button className={styles.cart_button}>
        <img src={cart} alt="cart icon" draggable={false}/>
        <span className={styles.cart_button_count}>{productsIds.length}</span>
      </button>
      <p
        className={styles.cart_price}>{(canPayWithBonus && products.length > 0) ? `${Number(totalSumBonusPrice).toFixed(2)} ПБ` : `₴ ${totalPrice.toFixed(2)}`}</p>
    </div>
  );
};

export default CartDetails;
