import "../../utils/accordion.scss";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {Accordion} from "@mui/material";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import {styled} from "@mui/system";
import {AxiosError} from "axios";
import React, {FC, useEffect} from "react";
import toast from "react-hot-toast";

import {EOrderStatus} from "../../enums/EOrderStatus";
import {useAppDispatch} from "../../hooks/reduxHooks";
import {IPersonalOrderItem} from "../../interfaces/IPersonalOrderItem";
import {orderService} from "../../services/order.service";
import {orderActions} from "../../store/slices/orderSlice";
import HistoryOrderItem from "../HistoryOrderItem/HistoryOrderItem";
import MainButtonRedBackground from "../MainButtonRedBackground/MainButtonRedBackground";
import MainButtonTransparentBackground from "../MainButtonTransparentBackground/MainButtonTransparentBackground";
import styles from "./AccordionOrderItem.module.scss";

interface IAppAccordionProps {
  type: "personal" | "partner";
  order: IPersonalOrderItem;
}

const CustomAccordion = styled(Accordion)({
  width: "100%",
  background: "#1D1D1D",
  padding: "16px 32px",
  "@media (max-width: 768px)": {
    padding: "8px 16px",
  },
});

const CustomAccordionDetails = styled(AccordionDetails)({
  padding: 0,
  display: "flex",
  flexDirection: "column",
  gap: 16
});

const CustomAccordionSummary = styled(AccordionSummary)({
  display: "flex",
  gap: 16,
  padding: 0,
});

const AccordionOrderItem: FC<IAppAccordionProps> = ({type, order}) => {
  const {
    uuid,
    created_on,
    order_total_price,
    products,
    status,
    order_total_points,
    delivery_code,
    delivery_method
  } = order;

  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      toast.remove("order");
    }
  }, []);

  const getBorderColor = () => {
    switch (EOrderStatus[status]) {
      case EOrderStatus.CREATED:
        return "#0033ff";
      case EOrderStatus.PAYED:
        return "#FAF84B";
      case EOrderStatus["PAYMENT-FAILED"]:
        return "#AF3D44";
      case EOrderStatus.DELIVERED:
        return "#0DBD14";
      case EOrderStatus.CANCELED:
        return "#888";
    }
  }

  const handlePayOrder = async () => {
    try {
      const {data} = await orderService.updateById(uuid);

      window.location.href = data.url;
    } catch (e) {
      const error = e as AxiosError;
      toast.error(JSON.stringify(error?.response?.data || error?.message), {id: "order"});
    }
  };

  const handleDeleteOrder = async () => {
    try {
      await orderService.deleteById(uuid);
      toast.success(`Замовлення №${uuid} успішно видалено!`, {id: "order"});

      setTimeout(() => {
        dispatch(orderActions.deleteById(uuid));
      }, 1500);
    } catch (e) {
      const error = e as AxiosError;
      toast.error(JSON.stringify(error?.response?.data || error?.message), {id: "order"});
    }
  };

  return (
    <CustomAccordion>
      <CustomAccordionSummary
        expandIcon={<ExpandMoreIcon style={{color: "white"}}/>}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <div style={{width: "100%", display: "flex", flexDirection: "column", gap: 16}}>
          {type === "partner" &&
            <div className={styles.partner}>
              <p className={styles.partner_fullname}>Юстина Олеськів</p>
              <p className={styles.partner_id}>ID: <span>TG4674448</span></p>
            </div>}

          {/* desktop */}
          <div style={{borderLeft: `2px solid ${getBorderColor()}`}} className={styles.orderDesktop}>
            <div className={styles.orderDesktop_block}>
              <p className={styles.orderDesktop_block_subtitle}>Замовлення</p>
              <p className={styles.orderDesktop_block_info}>{EOrderStatus[status]}</p>
            </div>
            {delivery_code && <div className={styles.orderDesktop_block}>
              <p className={styles.orderDesktop_block_subtitle}>Номер ТТН</p>
              <p className={styles.orderDesktop_block_info}>{delivery_code}</p>
            </div>}
            <div className={styles.orderDesktop_block}>
              <p className={styles.orderDesktop_block_subtitle}>Дата</p>
              <p className={styles.orderDesktop_block_info}>{new Date(created_on).toLocaleDateString()}</p>
            </div>
            <div className={styles.orderDesktop_block}>
              <p className={styles.orderDesktop_block_subtitle}>Назва адреси доставки</p>
              <p className={styles.orderDesktop_block_info}>{delivery_method?.delivery_method_name}</p>
            </div>
            <div className={styles.orderDesktop_block}>
              <p className={styles.orderDesktop_block_subtitle}>Загальна сума</p>
              <p className={styles.orderDesktop_block_info}>{order_total_price.toFixed(2)} ₴</p>
            </div>
            <div className={styles.orderDesktop_block}>
              <p className={styles.orderDesktop_block_subtitle}>Сума Балів</p>
              <p className={styles.orderDesktop_block_info}>{order_total_points}</p>
            </div>
          </div>

          {/* mobile */}
          <div style={{borderLeft: `2px solid ${getBorderColor()}`}} className={styles.orderMobile}>
            <div className={styles.orderMobile_spaceBetween}>
              <p className={styles.orderMobile_orderId}>Замовлення, {new Date(created_on).toLocaleDateString()}</p>
              <p className={styles.orderDesktop_block_info}>{delivery_method?.delivery_method_name}</p>
            </div>

            <div className={styles.orderMobile_spaceBetween}>
              <p className={styles.white}>{EOrderStatus[status]}</p>
              <p className={styles.white}>{order_total_price.toFixed(2)} ₴ </p>
            </div>

            <div className={styles.orderMobile_spaceBetween}>
              {delivery_code &&
                <div className={styles.orderMobile_spaceBetween_block}>
                  <p className={styles.gray}>Номер ТТН</p>
                  <p className={styles.white}>{delivery_code}</p>
                </div>}

              <div className={styles.orderMobile_spaceBetween_block}>
                <p className={styles.gray}>Сума Балів</p>
                <p className={styles.white}>{order_total_points}</p>
              </div>
            </div>
          </div>
        </div>
      </CustomAccordionSummary>
      <CustomAccordionDetails>
        <div>
          {products.map((product, index) =>
            <HistoryOrderItem key={index} product={product}/>)}
        </div>

        <div className={styles.buttons}>
          {status !== "PAYED" &&
            <MainButtonTransparentBackground
              text={"Видалити"}
              width={"auto"}
              onClick={handleDeleteOrder}
              type={"button"}
            />}

          {(status === "CREATED" || status === "PAYMENT-FAILED") &&
            <MainButtonRedBackground
              text={"Оплатити"}
              width={"auto"}
              onClick={handlePayOrder}
              type={"button"}
              isDisabled={false}
            />}
        </div>
      </CustomAccordionDetails>
    </CustomAccordion>
  );
};

export default AccordionOrderItem;
