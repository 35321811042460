import {joiResolver} from "@hookform/resolvers/joi";
import React, {FC, useEffect} from "react";
import {FieldError, useForm} from "react-hook-form";

import geo from "../../assets/marker-geo.svg";
import message from "../../assets/message.svg";
import phone from "../../assets/phone.svg";
import InputField from "../../components/InputField/InputField";
import MainButtonRedBackground from "../../components/MainButtonRedBackground/MainButtonRedBackground";
import {IContactsForm} from "../../interfaces/IContactsForm";
import {contactsSupportValidator} from "../../validators/contactsSupport.validator";
import styles from "./ContactsPage.module.scss";

interface IContactsPageProps {
}

const ContactsPage: FC<IContactsPageProps> = () => {
  const {
    register,
    handleSubmit,
    formState: {errors, isValid},
  } = useForm<IContactsForm>({
    mode: "all",
    resolver: joiResolver(contactsSupportValidator),
  });

  const handleSendData = (data: IContactsForm) => {
    console.log(data);
  }

  useEffect(() => {
    // scroll to the top
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapper_contacts}>
        <h1 className={styles.wrapper_contacts_title}>ЗАВЖДИ НА <span>ЗВʼЯЗКУ</span></h1>

        <div className={styles.wrapper_contacts_content}>
          <section className={styles.wrapper_contacts_content_info}>
            <div className={styles.wrapper_contacts_content_info_block}>
              <img src={message} alt="message chat" draggable={false}/>
              <div className={styles.wrapper_contacts_content_info_block_description}>
                <h4 className={styles.wrapper_contacts_content_info_block_description_title}>Напишіть
                  нам</h4>
                <p className={styles.wrapper_contacts_content_info_block_description_data}>info.lifestylle@gmail.com</p>
              </div>
            </div>

            <div className={styles.wrapper_contacts_content_info_block}>
              <img src={geo} alt="geo" draggable={false}/>
              <div className={styles.wrapper_contacts_content_info_block_description}>
                <h4 className={styles.wrapper_contacts_content_info_block_description_title}>Офіс</h4>
                <div>
                  <p className={styles.wrapper_contacts_content_info_block_description_data}>м.
                    Львів</p>
                  <p className={styles.wrapper_contacts_content_info_block_description_data}>вул.
                    Сахарова, 42</p>
                </div>
              </div>
            </div>

            <div className={styles.wrapper_contacts_content_info_block}>
              <img src={phone} alt="phone" draggable={false}/>
              <div className={styles.wrapper_contacts_content_info_block_description}>
                <h4 className={styles.wrapper_contacts_content_info_block_description_title}>Номер
                  телефону</h4>
                <a
                  href="tel:+38(098)8221882"
                  className={styles.wrapper_contacts_content_info_block_description_data}
                >
                  +38 098 822 18 82
                </a>
              </div>
            </div>
          </section>

          <form
            className={styles.wrapper_contacts_content_form}
            onSubmit={handleSubmit(handleSendData)}
          >
            <h3 className={styles.wrapper_contacts_content_form_subtitle}>Звʼяжіться з нами</h3>

            <div className={styles.wrapper_contacts_content_form_inputs}>
              <div className={styles.wrapper_contacts_content_form_inputs_flex2}>
                <InputField
                  width={"100%"}
                  label={"Прізвище"}
                  type={"text"}
                  name={"last_name"}
                  register={register}
                  errors={errors}
                  placeholder={""}
                />
                <InputField
                  width={"100%"}
                  label={"Ім'я"}
                  type={"text"}
                  name={"first_name"}
                  register={register}
                  errors={errors}
                  placeholder={""}
                />
              </div>
              <InputField
                width={"100%"}
                label={"Email"}
                type={"email"}
                name={"email"}
                register={register}
                errors={errors}
                placeholder={""}
              />
              <label className={styles.wrapper_contacts_content_form_inputs_message}
                htmlFor={"message"}>
                Повідомлення
                <textarea
                  {...register("message")}
                  id={"message"}
                  rows={5}
                  placeholder={"Повідомлення..."}
                ></textarea>

                {errors.message &&
                  <span className={styles.error}>{(errors.message as FieldError).message}</span>}
              </label>

              <MainButtonRedBackground
                text={"Надіслати"}
                width={"100%"}
                onClick={null}
                type={"submit"}
                isDisabled={!isValid}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactsPage;
