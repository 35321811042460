import React, {FC} from "react";

import {IExtendedProduct} from "../../interfaces/IExtendedProduct";
import styles from "./HistoryOrderItem.module.scss";

interface IHistoryOrderItemProps {
  product: IExtendedProduct;
}

const HistoryOrderItem: FC<IHistoryOrderItemProps> = ({product}) => {
  const {
    main_image,
    name,
    points_to_count,
    price,
    discounted_price,
    count
  } = product;

  return (
    <>
      {/* ------------------- desktop ------------------ */}
      <div className={styles.item}>
        <div className={styles.item_details}>
          <img src={main_image} alt="product"/>

          <div>
            <h3>{name}</h3>
            {/*<p>Код товару: <span>{pk}</span></p>*/}
          </div>
        </div>

        <p>{Number(discounted_price || price).toFixed(2)} ₴</p>
        <p>+ {points_to_count} бали</p>
        <p>{count} шт.</p>
      </div>

      {/* ------------------- mobile ------------------ */}
      <div className={styles.itemMobile}>
        <img src={main_image} alt="product"/>

        <div className={styles.itemMobile_details}>
          <div className={styles.itemMobile_details_col}>
            <h3>{name}</h3>
            {/*<p>Код товару: <span>{pk}</span></p>*/}
            <p>+{points_to_count} бали</p>
          </div>

          <div className={styles.itemMobile_details_jsBetween}>
            <p>{count} шт.</p>
            <p>{Number(discounted_price || price).toFixed(2)} ₴</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default HistoryOrderItem;
