import React, {FC} from "react";

import noResults from "../../assets/no-filtered-products-by-category.svg";
import styles from "./EmptyStateFilteredProductsByCategories.module.scss";

interface IEmptyFilteredProductsByCategoriesPageProps {
}

const EmptyFilteredProductsByCategoriesPage: FC<IEmptyFilteredProductsByCategoriesPageProps> = () => {
  return (
    <div className={styles.emptyState}>
      <img src={noResults} alt="no results by filtering icon" draggable={false}/>
      <h2 className={styles.emptyState_text}>В даній категорії товарів не знайдено</h2>
    </div>
  );
};

export default EmptyFilteredProductsByCategoriesPage;
