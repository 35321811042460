import {FC, MutableRefObject} from "react";
import {useNavigate} from "react-router-dom";

import catalogue from "../../assets/catalogue.svg";
import {MAIN_ROUTES} from "../../routes/mainRoutes";
import styles from "./HeaderNavLinks.module.scss";

interface IHeaderNavLinksProps {
  bestsellersRef: MutableRefObject<HTMLDivElement | null>;
  newProductsRef: MutableRefObject<HTMLDivElement | null>;
  promotionalProductsRef: MutableRefObject<HTMLDivElement | null>;
}

const HeaderNavLinks: FC<IHeaderNavLinksProps> = ({
  bestsellersRef,
  promotionalProductsRef,
  newProductsRef
}) => {
  const navigate = useNavigate();

  const handleScrollIntoSection = (ref: MutableRefObject<HTMLDivElement | null>) => {
    navigate(MAIN_ROUTES.HOME);

    if (ref) {
      setTimeout(() => {
        ref?.current?.scrollIntoView({
          behavior: "smooth"
        });
      }, 300);
    }
  }

  return (
    <nav className={styles.nav}>
      <div className={styles.nav_catalogue}>
        <img src={catalogue} alt="book open icon" draggable={false}/>
        <p>Каталог</p>
      </div>

      <ul className={styles.nav_list}>
        <li
          className={styles.nav_list_item}
          onClick={() => handleScrollIntoSection(bestsellersRef)}
        >хіти продажів
        </li>
        <li
          className={styles.nav_list_item}
          onClick={() => handleScrollIntoSection(newProductsRef)}
        >новинки
        </li>
        <li
          className={styles.nav_list_item}
          onClick={() => handleScrollIntoSection(promotionalProductsRef)}
        >акційні товари
        </li>
        <li
          onClick={() => navigate(MAIN_ROUTES.ABOUT_US)}
          className={styles.nav_list_item}
        >про нас
        </li>
        {/*<li*/}
        {/*  // onClick={() => navigate(MAIN_ROUTES.BLOG)}*/}
        {/*  className={styles.nav_list_item}*/}
        {/*>Блог*/}
        {/*</li>*/}
      </ul>
    </nav>
  );
};

export default HeaderNavLinks;
