import {AxiosError} from "axios";
import React, {FC, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";

import Loader from "../../components/Loader/Loader";
import MainButtonRedBackground from "../../components/MainButtonRedBackground/MainButtonRedBackground";
import {MAIN_ROUTES} from "../../routes/mainRoutes";
import {instance} from "../../services/axios.service";
import styles from "./VerifyEmailPage.module.scss";

interface IVerifyEmailPageProps {
}

// content for the page depending on whether the email was successfully verified or not
const content = {
  "success": {
    title: "Ваш Email підтверджено",
    description: "Дякуємо за Вашу реєстрацію, гарних Вам покупок",
    buttonText: "Перейти до покупок"
  },
  "rejected": {
    title: "Ваш Email не підтверджено",
    description: "Щось пішло не так...",
    buttonText: ""
  }
}

const VerifyEmailPage: FC<IVerifyEmailPageProps> = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [status, setStatus] = useState<"success" | "rejected">("success");

  const {token} = useParams<{ token: string }>();

  const navigate = useNavigate();

  const handleVerifyEmail = async () => {
    try {
      const res = await instance.get(`user/${token}/verify-email/`);

      if (res.status === 200) {
        setStatus("success");
      }
    } catch (e) {
      const error = e as AxiosError;
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    handleVerifyEmail();
  }, []);

  if (isLoading) {
    return <Loader/>;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapper_verifyEmailPage}>
        <h1 className={styles.wrapper_verifyEmailPage_title}>ПРИЄДНУЙТЕСЬ У СВІТ <span>КРАСИ</span></h1>

        <div className={styles.wrapper_verifyEmailPage_content}>
          <div className={styles.wrapper_verifyEmailPage_content_rectangle}></div>
          <div className={styles.wrapper_verifyEmailPage_content_block}>
            <h2>{content[status].title}</h2>

            <p>{content[status].description}</p>

            {content[status].buttonText && <MainButtonRedBackground
              text={content[status].buttonText}
              width={"auto"}
              onClick={() => navigate(MAIN_ROUTES.HOME)}
              type={"button"}
              isDisabled={false}
            />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmailPage;
