import {createSlice} from "@reduxjs/toolkit";

interface IState {
}

const initialState:IState = {
}

const UISlice = createSlice({
  name: "UISlice",
  initialState,
  reducers: {
    reset: () => initialState
  },
});

const {reducer: UIReducer, actions} = UISlice;

const UIActions = {
  ...actions,
}

export {
  UIActions,
  UIReducer,
}
