import React, {FC} from "react";

import HeartLoader from "../../components/HeartLoader/HeartLoader";

interface IFavouriteProductsPageProps {
}

const FavouriteProductsPage: FC<IFavouriteProductsPageProps> = () => {
  return (
    <HeartLoader/>
  // <div className={styles.wrapper}>
  //     <div className={styles.wrapper_favouriteProductsPage}>
  //         <h1 className={styles.wrapper_favouriteProductsPage_title}>СПИСОК <span>ВПОДОБАНОГО</span></h1>
  //
  //         <Breadcrumbs/>
  //         <EmptyFavouriteProducts/>
  //         {/*<div className={styles.wrapper_favouriteProductsPage_products}>*/}
  //         {/*    <FavouriteProductCard type={"sale"}/>*/}
  //         {/*</div>*/}
  //     </div>
  // </div>
  );
};

export default FavouriteProductsPage;
