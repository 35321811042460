import React, {FC, useEffect, useState} from "react";
import {Toaster} from "react-hot-toast";
import {useQuery} from "react-query";
import {useParams} from "react-router-dom";

import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import CategoryExpandable from "../../components/CategoryExpandable/CategoryExpandable";
import Loader from "../../components/Loader/Loader";
import MainSwiper from "../../components/MainSwiper/MainSwiper";
import OrderingBy from "../../components/OrderingBy/OrderingBy";
import Pagination from "../../components/Pagination/Pagination";
import PriceRangeExpandable from "../../components/PriceRangeExpandable/PriceRangeExpandable";
import ProductCard from "../../components/ProductCard/ProductCard";
import ProductsBlockContainer from "../../components/ProductsBlockContainer/ProductsBlockContainer";
import {useAppDispatch, useAppSelector} from "../../hooks/reduxHooks";
import {productsService} from "../../services/products.service";
import {categoriesActions} from "../../store/slices/categoriesSlice";
import EmptyFilteredProductsByCategoriesPage
  from "../EmptyStateFilteredProductsByCategories/EmptyStateFilteredProductsByCategories";
import styles from "./CategoryPage.module.scss";

interface ICategoryPageProps {
}

const CategoryPage: FC<ICategoryPageProps> = () => {
  const {id} = useParams<{ id: string }>(); // get product id from url

  const [currentPage, setCurrentPage] = useState<number>(1);

  const dispatch = useAppDispatch();
  const {category, status} = useAppSelector(state => state.categories);
  const {recentlyViewed, filteringValues} = useAppSelector(state => state.products);

  const getCategoryById = () => {
    dispatch(categoriesActions.getById({id: Number(id)}));
  }

  const getAllCategories = () => {
    dispatch(categoriesActions.getAll());
  }

  useEffect(() => {
    // scroll to the top
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });

    getCategoryById();
  }, [id, currentPage]);

  useEffect(() => {
    getAllCategories();
  }, []);

  const isCategoryBonusProducts = Number(id) === 4;

  const {data, error, isLoading} = useQuery(
    ["get-products-by-category", category, filteringValues, currentPage], // second, third arg - dependency when the request is repeated
    async () => productsService.getByCategory(category!.name, filteringValues.ordering.ordering, isCategoryBonusProducts, currentPage),
    {
      enabled: !!category?.name, // this request will be executed when category name not null
    }
  );

  if (status === "loading" || isLoading) {
    return <Loader/>;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapper_categoryPage}>
        <div style={{display: "flex", flexDirection: "column", gap: 32}}>
          <h1 className={styles.wrapper_categoryPage_title}><span>{category?.name}</span></h1>

          <MainSwiper/>
        </div>

        <div className={styles.wrapper_categoryPage_ordering}>
          <div className={styles.wrapper_categoryPage_ordering_breadcrumbs}>
            <Breadcrumbs type={"categories"}/>
            <OrderingBy/>
          </div>

          <div className={styles.wrapper_categoryPage_ordering_content}>
            <div className={styles.wrapper_categoryPage_ordering_content_products}>
              <aside className={styles.wrapper_categoryPage_ordering_content_products_sidebar}>
                <CategoryExpandable/>
                <PriceRangeExpandable/>
              </aside>
              <div className={styles.wrapper_categoryPage_ordering_content_products_list}>
                {data?.data?.results?.length === 0 ?
                  <EmptyFilteredProductsByCategoriesPage/> :
                  <div
                    className={styles.wrapper_categoryPage_ordering_content_products_list_pagination}>
                    <div className={styles.products}>
                      {data?.data?.results.map((product, index) =>
                        <ProductCard
                          key={index}
                          width={"100%"}
                          product={product}
                          type={product.is_promo ? "promo" : (+product.discount_percent ? "sale" : "usual")}
                          isFavourite={false}
                        />)}
                    </div>

                    <Pagination
                      totalItems={data?.data?.count || 1}
                      currentPage={currentPage}
                      onPageChange={setCurrentPage}
                      position={"flex-start"}
                    />
                  </div>}
              </div>
            </div>
          </div>
        </div>

        {recentlyViewed.length > 0 &&
          <ProductsBlockContainer products={recentlyViewed} title={"ви нещодавно переглядали"}/>}
      </div>

      {/* to appear hot toast notification */}
      <Toaster/>
    </div>
  );
};

export default CategoryPage;
