import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import React, {FC} from "react";
import {useNavigate} from "react-router-dom";

import {useAppDispatch, useAppSelector} from "../../hooks/reduxHooks";
import {ICategory} from "../../interfaces/ICategory";
import {ISubcategory} from "../../interfaces/ISubcategory";
import {MAIN_ROUTES} from "../../routes/mainRoutes";
import {categoriesActions} from "../../store/slices/categoriesSlice";
import {productsActions} from "../../store/slices/productSlice";
import styles from "./CategoryExpandable.module.scss";

interface ICategoryExpandableProps {
}

const CategoryExpandable: FC<ICategoryExpandableProps> = () => {
  const dispatch = useAppDispatch();
  const {categories, breadcrumbs} = useAppSelector(state => state.categories);
  const {filteringValues} = useAppSelector(state => state.products);

  const navigate = useNavigate();

  const handleNavigateToCategoryPage = (category: ICategory) => {
    navigate(`${MAIN_ROUTES.CATEGORIES}/${category.id}`);
    dispatch(categoriesActions.initializeBreadcrumbs([category.name]));
  }

  const handleNavigateToSearchResultsPage = (category: ICategory, subcategory: ISubcategory) => {
    dispatch(productsActions.getFiltered({
      search: subcategory.name,
      ordering: filteringValues.ordering.ordering,
      page: 1
    }));
    navigate(`${MAIN_ROUTES.SEARCH_RESULTS}?search=${subcategory.name}`);
    dispatch(categoriesActions.addBreadcrumbs({
      subcategory: subcategory.name,
      category: category.name
    }));
  }

  return (
    <Accordion
      defaultExpanded={true}
      style={{
        background: "#1D1D1D",
        padding: 16,
        borderRadius: 4
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon style={{color: "white"}}/>}
        aria-controls="panel2-content"
        id="panel2-header"
        style={{
          color: "white",
          fontSize: 16,
          fontWeight: 600,
          lineHeight: "150%",
        }}
      >
        Категорії
      </AccordionSummary>
      <AccordionDetails
        style={{padding: 0}}
      >
        <section className={styles.categories}>
          {categories.map((category, index) =>
            <div key={index}>
              <h3
                className={styles.categories_title}
                onClick={() => handleNavigateToCategoryPage(category)}
              >{category.name}</h3>
              <ul className={styles.categories_subcategories}>
                {category.subcategories.map((subcategory, index) =>
                  <li
                    onClick={() => handleNavigateToSearchResultsPage(category, subcategory)}
                    className={styles.categories_subcategories_item}
                    key={index}
                  >{subcategory.name}</li>)}
              </ul>
            </div>)}
        </section>
      </AccordionDetails>
    </Accordion>
  );
};

export default CategoryExpandable;
