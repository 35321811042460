import React, {FC} from "react";

import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import ReferralItemList from "../../components/ReferralItemList/ReferralItemList";
import ReferralItemListDetailed from "../../components/ReferralItemListDetailed/ReferralItemListDetailed";
import ToggleReferralStructureView from "../../components/ToggleReferralStructureView/ToggleReferralStructureView";
import {useAppSelector} from "../../hooks/reduxHooks";
import styles from "./ReferralStructureListPage.module.scss";

interface IReferralStructureListPageProps {
  activeToggle: string;
  setActiveToggle: (value: "list" | "tree") => void;
}

const ReferralStructureListPage: FC<IReferralStructureListPageProps> = ({activeToggle, setActiveToggle}) => {
  const {chainStructure, currentUser, status} = useAppSelector(state => state.user);

  return (
    <div className={styles.referralStructureListPage}>
      <div className={styles.referralStructureListPage_breadcrumbs}>
        <Breadcrumbs type={"chain-structure"}/>
        <ToggleReferralStructureView activeToggle={activeToggle} setActiveToggle={setActiveToggle}/>
      </div>

      {/*<SearchFilterByName/>*/}

      <div className={styles.referralStructureListPage_content}>
        {currentUser && <ReferralItemListDetailed currentUser={currentUser}/>}

        <div className={styles.referralStructureListPage_content_referrals}>
          {(currentUser?.children || chainStructure!.children).map((referral, index) =>
            <ReferralItemList key={index} referral={referral} index={index + 1}/>)}
        </div>
      </div>
    </div>
  );
};

export default ReferralStructureListPage;
