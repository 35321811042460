import React, {FC} from "react";
import toast from "react-hot-toast";
import {useNavigate} from "react-router-dom";

import {useAppDispatch, useAppSelector} from "../../hooks/reduxHooks";
import {MAIN_ROUTES} from "../../routes/mainRoutes";
import {cartActions} from "../../store/slices/cartSlice";
import MainButtonRedBackground from "../MainButtonRedBackground/MainButtonRedBackground";
import MainButtonTransparentBackground from "../MainButtonTransparentBackground/MainButtonTransparentBackground";
import styles from "./MyOrderPrice.module.scss";

interface IMyOrderPriceProps {
  step: "order" | "cart";
  onClick: () => void;
  isDisabled: boolean;
}

const MyOrderPrice: FC<IMyOrderPriceProps> = ({step, onClick, isDisabled}) => {
  const dispatch = useAppDispatch();
  const {totalPrice, discountPrice, totalPoints} = useAppSelector(state => state.cart);

  const navigate = useNavigate();

  const handleResetCart = () => {
    dispatch(cartActions.resetCart());

    toast.success("Вашу корзину успішно очищено!", {id: "cart"});
    navigate(MAIN_ROUTES.CART);
  }

  const freeDelivery = 1300 - Number((totalPrice - discountPrice).toFixed(2));

  return (
    <div className={styles.myOrderPrice}>
      <div className={styles.myOrderPrice_price}>
        <div className={styles.wrapper}>
          <p className={styles.myOrderPrice_price_titleFull}>Вартість</p>
          <p className={styles.myOrderPrice_price_full}>{totalPrice} ₴</p>
        </div>
        <div className={styles.wrapper}>
          <p className={styles.myOrderPrice_price_titleSale}>Знижка</p>
          <p className={styles.myOrderPrice_price_sale}>{discountPrice} ₴</p>
        </div>
      </div>

      <div className={styles.divider}></div>

      <div className={styles.myOrderPrice_total}>
        <div className={styles.wrapper}>
          <p className={styles.myOrderPrice_total_titleTotal}>Підсумок</p>
          <p className={styles.myOrderPrice_total_price}>{(totalPrice - discountPrice).toFixed(2)} ₴</p>
        </div>
        <div className={styles.wrapper}>
          <p className={styles.myOrderPrice_total_titlePoints}>Бали</p>
          <p className={styles.myOrderPrice_total_points}>+ {totalPoints}</p>
        </div>
      </div>

      <div className={styles.divider}></div>

      <div className={`${styles.wrapper} ${styles.myOrderPrice_deliveryPayment}`}>
        <p className={styles.myOrderPrice_deliveryPayment_title}>Доставка</p>
        <p className={styles.myOrderPrice_deliveryPayment_description}>За тарифами перевізника</p>
      </div>

      <div
        className={styles.myOrderPrice_freeDelivery}
        style={{margin: "16px 0 32px"}}
      >
        {freeDelivery > 0 ?
          <p>Додайте товарів ще на {freeDelivery.toFixed(2)} ₴, щоб отримати безкоштовну доставку </p> :
          <p>Ви отримали безкоштовну доставку </p>}
      </div>

      <div className={styles.myOrderPrice_buttons}>
        <MainButtonRedBackground
          text={step === "cart" ? "Оформити Замовлення" : "Оплатити Замовлення"}
          width={"auto"}
          onClick={onClick}
          type={"button"}
          isDisabled={isDisabled}
        />
        <MainButtonTransparentBackground
          text={step === "cart" ? "Продовжити Покупки" : "Очистити Кошик"}
          width={"auto"}
          onClick={step === "cart" ? null : handleResetCart}
          type={"button"}
        />
      </div>
    </div>
  );
};

export default MyOrderPrice;
