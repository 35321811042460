import Joi from "joi";

export const passwordRecoveryValidator = Joi.object({
  password: Joi.string().required().min(8).messages({
    "string.empty": "Заповніть це поле",
    "string.min": "Мінімальна довжина 8 символів",
  }),
  confirm_password: Joi.string().required().min(8).valid(Joi.ref("password")).messages({
    "string.empty": "Заповніть це поле",
    "string.min": "Мінімальна довжина 8 символів",
    "any.only": "Паролі не збігаються",
  }),
});
