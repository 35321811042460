// import React from "react";
// import {RiseLoader} from "react-spinners";
//
// import styles from "./Loader.module.scss";
//
// const Loader = () => {
//     return (
//         <div className={styles.loader}>
//             <RiseLoader size={32} color="#9B232C"/>
//         </div>
//     );
// };
//
// export default Loader;
//
//
// // import React from "react";
// //
// // import styles from "./Loader.module.scss";
// //
// // const Loader = () => {
// //     return (
// //         <div className={styles.loader}>
// //             <div>
// //                 <span data-text="L">L</span>
// //                 <span data-text="I">I</span>
// //                 <span data-text="F">F</span>
// //                 <span data-text="E">E</span>
// //             </div>
// //
// //             <div>
// //                 <span data-text="S">S</span>
// //                 <span data-text="T">T</span>
// //                 <span data-text="Y">Y</span>
// //                 <span data-text="L">L</span>
// //                 <span data-text="L">L</span>
// //             </div>
// //         </div>
// //     );
// // };
// //
// // export default Loader;
import React from "react";

import styles from "./Loader.module.scss";

interface ILoaderProps {
}

const Loader: React.FC<ILoaderProps> = () => {
  return (
    <div className={styles.loader}>
      <div className={styles.loader_dot}></div>
    </div>
  );
};

export default Loader;
