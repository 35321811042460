import React, {FC, useState} from "react";

import copy from "../../assets/copy.svg";
import {levelNames} from "../../constants/levelNames";
import {months} from "../../constants/months";
import {useAppSelector} from "../../hooks/reduxHooks";
import AppCircularProgressBar from "../AppCircularProgressBar/AppCircularProgressBar";
import ProgressBarLinear from "../ProgressBarLinear/ProgressBarLinear";
import StatusActivity from "../StatusActivity/StatusActivity";
import styles from "./UserStatistics.module.scss";

interface IUserStatisticsProps {
}

const UserStatistics: FC<IUserStatisticsProps> = () => {
  const [month, setMonth] = useState<string>(months[0]);
  const [isCopiedToClipboard, setIsCopiedToClipboard] = useState<boolean>(false);

  const {user} = useAppSelector(state => state.user);

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(`${process.env.REACT_APP_APP_URL}/registration?ref_id=${user?.uuid}`)
      .then(() => {
        setIsCopiedToClipboard(true);
      })
      .catch(err => {
        console.error("Error copying text:", err);
      });
  }

  if (user) {
    const {
      personal_volume,
      current_level,
      count_of_children,
      count_of_points_for_the_next_level,
      count_of_children_for_the_next_level,
      count_of_branches_for_the_next_level,
      amount_of_branches_for_the_next_level,
      current_count_of_branches_with_required_amount,
      total_group_volume,
      count_of_active_children,
      is_user_activated,
      personal_period_points
    } = user;

    return (
      <div className={styles.userStatistics}>
        <div className={styles.userStatistics_level}>
          <p className={styles.userStatistics_level_number}>Мій рівень: {levelNames[current_level - 1]}</p>

          {/*<div className={styles.userStatistics_level_month}>*/}
          {/*    <p className={styles.userStatistics_level_month_label}>Місяць:</p>*/}
          {/*    <MonthsDropdown defaultMonth={months[0]} setMonth={setMonth} month={month}/>*/}
          {/*</div>*/}
          <StatusActivity
            status={is_user_activated ? "Активний" : "Неактивний"}
          />
        </div>

        <div className={styles.userStatistics_divider}></div>

        <div className={styles.userStatistics_pointsCircular}>
          <div className={styles.userStatistics_pointsCircular_progressBar}>
            <AppCircularProgressBar
              currentPoints={personal_volume}
              pointsForTheNextLevel={count_of_points_for_the_next_level}
              innerText={
                <>
                  <span>{personal_volume}</span>
                  <span>Накопичений Особистий Обʼєм</span>
                </>
              }
            />
          </div>

          <div className={styles.userStatistics_pointsCircular_points}>
            <div className={styles.userStatistics_pointsCircular_points_count}>
              <div className={styles.userStatistics_pointsCircular_points_count_wrapper}>
                <p className={styles.subtitle}>Обʼєм структури</p>
                <p className={styles.count}>{total_group_volume}</p>
              </div>
              <div className={styles.userStatistics_pointsCircular_points_count_wrapper}>
                <p className={styles.subtitle}>Партнери</p>
                <p className={styles.count}>{count_of_children}</p>
              </div>
            </div>

            <div className={styles.userStatistics_pointsCircular_points_invitation}>
              <p className={styles.userStatistics_pointsCircular_points_invitation_subtitle}>Запросіть
                партнера</p>
              <button
                className={`${styles.userStatistics_pointsCircular_points_invitation_button} ${isCopiedToClipboard ? styles.copied : ""}`}
                onClick={handleCopyToClipboard}
              >
                <p>{isCopiedToClipboard ? "Скопійовано!" : "Посилання"}</p>
                <img src={copy} alt="copy" draggable={false}/>
              </button>
            </div>
          </div>
        </div>

        {is_user_activated ?
          <div className={styles.userStatistics_nextLevel}>
            <p className={styles.userStatistics_nextLevel_title}>Для переходу на рівень: {levelNames[current_level]}</p>
            <div className={styles.userStatistics_divider}></div>

            <div className={styles.userStatistics_nextLevel_progress}>
              <ProgressBarLinear
                label={"Накопичений Особистий Обʼєм"}
                currentPoints={personal_volume}
                pointsForTheNextLevel={count_of_points_for_the_next_level}
              />
              <ProgressBarLinear
                label={"Активні Партнери"}
                currentPoints={count_of_active_children}
                pointsForTheNextLevel={count_of_children_for_the_next_level}
              />
              <ProgressBarLinear
                label={`Гілки з Оборотом Балів ${amount_of_branches_for_the_next_level}б`}
                currentPoints={current_count_of_branches_with_required_amount}
                pointsForTheNextLevel={count_of_branches_for_the_next_level}
              />
            </div>
          </div> :
          <div className={styles.userStatistics_nextLevel}>
            <p className={styles.userStatistics_nextLevel_title}>Для активації наберіть балів: 15</p>
            <div className={styles.userStatistics_divider}></div>

            <div className={styles.userStatistics_nextLevel_progress}>
              <ProgressBarLinear
                label={"Особистий обʼєм за період"}
                currentPoints={personal_period_points}
                pointsForTheNextLevel={15}
              />
            </div>
          </div>
        }

        {/*<ul className={styles.userStatistics_bonus}>*/}
        {/*    <li className={styles.userStatistics_bonus_item}>Зробіть особистих покупок*/}
        {/*        на <span>1000 ₴</span> щоб*/}
        {/*        отримати 30бб*/}
        {/*    </li>*/}
        {/*    <li className={styles.userStatistics_bonus_item}>Запросіть*/}
        {/*        ще <span>{count_of_children_for_the_next_level}</span> партнерів для переходу*/}
        {/*        на*/}
        {/*        новий рівень*/}
        {/*    </li>*/}
        {/*</ul>*/}
      </div>
    );
  }

  return null;
};

export default UserStatistics;
