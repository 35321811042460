import "../../utils/tree.scss";

import React, {FC, useCallback, useEffect, useRef, useState} from "react";
import {Tree} from "react-d3-tree";

import chevron from "../../assets/chevron-right.svg"
import renderForeignObjectNode from "../../components/ReferralStructureTreeItem/ReferralStructureTreeItem";
import ToggleReferralStructureView from "../../components/ToggleReferralStructureView/ToggleReferralStructureView";
import {useAppDispatch, useAppSelector} from "../../hooks/reduxHooks";
import {userActions} from "../../store/slices/userSlice";
import styles from "./ReferralStructureTreePage.module.scss";

interface ForeignObjectProps {
  width: number;
  height: number;
  x: number;
}

interface IReferralStructureTreePageProps {
  activeToggle: string;
  setActiveToggle: (value: "list" | "tree") => void;
}

// puts the structure in the center
export const useCenteredTree = (defaultTranslate = {x: 0, y: 0}) => {
  const [translate, setTranslate] = useState(defaultTranslate);

  const containerRef = useCallback((containerElem: any) => {
    if (containerElem !== null) {
      const {width, height} = containerElem.getBoundingClientRect();
      setTranslate({x: width / 2, y: height / 4});
    }
  }, []);

  return {translate, containerRef};
};

const ReferralStructureTreePage: FC<IReferralStructureTreePageProps> = ({activeToggle, setActiveToggle}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  const {translate, containerRef} = useCenteredTree();
  const nodeSize = {x: 250, y: 210};
  const separation = {siblings: 2, nonSiblings: 2};
  const foreignObjectProps: ForeignObjectProps = {
    width: nodeSize.x,
    height: nodeSize.y,
    x: -115
  };

  const dispatch = useAppDispatch();
  const {childrenTreeView, currentLevelDepth} = useAppSelector(state => state.user);

  const treeProps: any = {
    data: childrenTreeView,
    translate,
    nodeSize,
    separation,
    transitionDuration: 1000,
    pathFunc: "step",
    rootNodeClassName: "node__root",
    branchNodeClassName: "node__branch",
    leafNodeClassName: "node__leaf",
    renderCustomNodeElement: (rd3tProps: any) =>
      renderForeignObjectNode({...rd3tProps, foreignObjectProps}),
    orientation: "vertical"
  };

  const currentLevelsDepth = ["1", "1-2", "1-3", "1-4", "1-5", "1-6", "1-7", "1-8", "1-9", "1-10", "1-11", "1-12"];

  const dropdownRef = useRef<HTMLDivElement>(null);

  const getChildrenByCurrentUser = (level: string) => {
    dispatch(userActions.getChildrenByCurrentUserTreeView({level}));
  }

  const handleSelectLevelDepth = (level: string) => {
    dispatch(userActions.setCurrentLevelDepth(level));
    setIsDropdownOpen(false);

    const formattedLevel = level.split("-")[1];
    getChildrenByCurrentUser(formattedLevel);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsDropdownOpen(false);
    }
  };

  const handleScroll = () => {
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    if (isDropdownOpen) {
      document.addEventListener("scroll", handleScroll);
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("scroll", handleScroll);
    };
  }, [isDropdownOpen]);

  return (
    <div className={styles.referralStructureTreePage}>
      <div style={{display: "flex", alignItems: "center", alignSelf: "flex-end", gap: 16, flexWrap: "wrap"}}>
        <div
          ref={dropdownRef}
          className={styles.referralStructureTreePage_dropdown}
          onClick={() => setIsDropdownOpen(prev => !prev)}
        >
          <span>Фільтрувати за глибиною: {currentLevelDepth}</span>
          <img style={{rotate: "90deg"}} src={chevron} alt="chevron down"/>

          <ul className={`${styles.referralStructureTreePage_dropdown_list} ${isDropdownOpen ? styles.open : ""}`}>
            {currentLevelsDepth.map((item, index) =>
              <li
                onClick={() => handleSelectLevelDepth(item)}
                className={styles.referralStructureTreePage_dropdown_list_item}
                key={index}
              >{item}</li>)}
          </ul>
        </div>
        <ToggleReferralStructureView activeToggle={activeToggle} setActiveToggle={setActiveToggle}/>
      </div>

      <div className={styles.referralStructureTreePage_structure} ref={containerRef}>
        <Tree {...treeProps} />
      </div>
    </div>
  );
};

export default ReferralStructureTreePage;
