import React, {FC, useEffect} from "react";
import toast from "react-hot-toast";

import {calculateDiscountedPrice} from "../../helpers/calculateDiscountedPrice";
import {useAppDispatch} from "../../hooks/reduxHooks";
import {IProduct} from "../../interfaces/IProduct";
import {cartActions} from "../../store/slices/cartSlice";
import styles from "./ProductItemOrderRecommendations.module.scss";

interface IProductItemOrderRecommendationsProps {
  product: IProduct;
}

const ProductItemOrderRecommendations: FC<IProductItemOrderRecommendationsProps> = ({product}) => {
  const dispatch = useAppDispatch();
  const {main_image, pk, price, discount_percent, points_to_count, name} = product;

  const handleAddItemToCart = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    dispatch(cartActions.addProductId(pk)); // includes checking the product in the cart
    toast.success(`Продукт "${name}" успішно додано до корзини!`, {id: "cart"});
  }

  useEffect(() => {
    return () => {
      toast.remove("cart");
    }
  }, []);

  return (
    <div className={styles.productItemOrderRecommendations}>
      <img
        className={styles.productItemOrderRecommendations_photo}
        src={main_image}
        alt={`product ${name}`}
        draggable={false}
      />
      <div className={styles.productItemOrderRecommendations_description}>
        <div className={styles.productItemOrderRecommendations_description_title}>
          <div className={styles.productItemOrderRecommendations_description_title_name}>
            <p className={styles.productItemOrderRecommendations_description_title_name_text}>{name}</p>
            <p className={styles.productItemOrderRecommendations_description_title_name_code}>Код
              товару: <span>{pk}</span></p>
          </div>

          <p className={styles.productItemOrderRecommendations_description_title_points}>+ {points_to_count} балів</p>
        </div>

        <div className={styles.productItemOrderRecommendations_description_addGroup}>
          <div className={styles.productItemOrderRecommendations_description_addGroup_price}>
            <p className={styles.productItemOrderRecommendations_description_addGroup_price_old}>{Number(price)} ₴</p>
            <p
              className={styles.productItemOrderRecommendations_description_addGroup_price_new}>{calculateDiscountedPrice(Number(price), Number(discount_percent))} ₴</p>
          </div>

          <button onClick={handleAddItemToCart}
            className={styles.productItemOrderRecommendations_description_addGroup_button}>+
          </button>
        </div>

      </div>
    </div>
  );
};

export default ProductItemOrderRecommendations;
