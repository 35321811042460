import {endpoints} from "../configs/endpoints";
import {IDeliveryAddress} from "../interfaces/IDeliveryAddress";
import {instance, IRes} from "./axios.service";

interface IDeliveryAddressExtended extends IDeliveryAddress {
  description: string;
}

export const deliveryService = {
  create: (data: IDeliveryAddressExtended): IRes<IDeliveryAddress> => {
    const res = instance.post(endpoints.delivery.base, data);

    return res;
  },
  getAllByCurrentUser: () => {
    const res = instance.get(endpoints.delivery.base);

    return res;
  },
  deleteById: (id: number) => {
    const res = instance.delete(`${endpoints.delivery.base}${id}/`);

    return res;
  },
}
