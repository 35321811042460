import React, {FC, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import CartLoader from "../../components/CartLoader/CartLoader";
import CustomModal from "../../components/CustomModal/CustomModal";
import FrameSaleForUnauthUser from "../../components/FrameSaleForUnauthUser/FrameSaleForUnauthUser";
import ModalForUnauthUsers from "../../components/ModalForUnauthUsers/ModalForUnauthUsers";
import MyOrderListOfProducts from "../../components/MyOrderListOfProducts/MyOrderListOfProducts";
import MyOrderPrice from "../../components/MyOrderPrice/MyOrderPrice";
import MyOrderPriceGiftBonus from "../../components/MyOrderPriceGiftBonus/MyOrderPriceGiftBonus";
import OfferedProducts from "../../components/OfferedProducts/OfferedProducts";
import {useAppDispatch, useAppSelector} from "../../hooks/reduxHooks";
import {IProductDetailed} from "../../interfaces/IProductDetailed";
import {MAIN_ROUTES} from "../../routes/mainRoutes";
import {productsService} from "../../services/products.service";
import {cartActions} from "../../store/slices/cartSlice";
import {productsActions} from "../../store/slices/productSlice";
import EmptyCartPage from "../EmptyCartPage/EmptyCartPage";
import styles from "./CartPage.module.scss";

interface ICartPageProps {
}

const CartPage: FC<ICartPageProps> = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isCartLoaderShow, setIsCartLoaderShow] = useState<boolean>(true);

  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const {user} = useAppSelector(state => state.user);
  const {products, productsIds} = useAppSelector(state => state.cart);
  const {promoProducts} = useAppSelector(state => state.products);

  const handleNavigate = (path: string) => {
    navigate(path);
  }

  const getProductById = async (id: number) => {
    try {
      const res = await productsService.getById(id);
      const product = res.data as IProductDetailed;

      const productFromCart = products.find((product) => product.pk === id);

      dispatch(cartActions.addProductDescription({
        pk: product.pk,
        name: product.name,
        price: product.price,
        points_to_count: product.points_to_count,
        main_image: product.images[0],
        discount_percent: product.discount_percent,
        can_pay_with_bonus: product.can_pay_with_bonus,
        bonus_price: product.bonus_price,
        count: productFromCart?.count || 1, // count of this product
      }));
    } catch (error) {
      console.error(error);
    }
  }

  const handleRedirectToNextStep = (path: string) => {
    if (user) {
      handleNavigate(path);
    } else {
      setIsModalOpen(true); // modal for unauthorized users
    }
  }

  useEffect(() => {
    // scroll to the top
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });

    setTimeout(() => {
      setIsCartLoaderShow(false);
    }, 1000);
  }, []);

  useEffect(() => {
    const productIds = products.flatMap(obj => Array.from({length: obj.count}, () => obj.pk));
    const queryParams = productIds.map(id => `product=${id}`).join("&");

    dispatch(productsActions.getPromoProducts({queryParams, page: 1}));
  }, [products]);

  useEffect(() => {
    const getAllProductsDescription = async () => {
      setIsLoading(true);

      try {
        dispatch(cartActions.deleteAllProductsDescription());

        await Promise.all(productsIds.map(productId => getProductById(productId)));
      } catch (error) {
        console.error(error);
      }

      setIsLoading(false);
    };

    getAllProductsDescription()
      .then(() => dispatch(cartActions.calculateOrderSummary({isUser: !!user}))); // calculate total points, total price, total discount price
  }, [user, productsIds]);

  const canPayWithBonus = products.every(product => product.can_pay_with_bonus);
  const allProductsCanPayWithBonus = products.every(product => product.can_pay_with_bonus === products[0].can_pay_with_bonus);

  return (
    (isLoading || isCartLoaderShow) ?
      <CartLoader/> :
      <div className={styles.wrapper}>
        <div className={styles.wrapper_cartPage}>
          <h1 className={styles.wrapper_cartPage_title}>МОЯ <span>КОРЗИНА</span></h1>

          <Breadcrumbs/>

          {productsIds.length > 0 ?
            <div className={styles.wrapper_cartPage_content}>
              <div className={styles.wrapper_cartPage_content_products}>
                {!user && <FrameSaleForUnauthUser/>}
                <MyOrderListOfProducts/>
                {(promoProducts && promoProducts.length > 0)
                  && <OfferedProducts/>}
              </div>
              {canPayWithBonus ?
                <MyOrderPriceGiftBonus
                  onClick={() => handleRedirectToNextStep(MAIN_ROUTES.CONFIRM_ORDER)}
                  step={"cart"}
                  isDisabled={!allProductsCanPayWithBonus}
                /> :
                <MyOrderPrice
                  onClick={() => handleRedirectToNextStep(MAIN_ROUTES.CONFIRM_ORDER)}
                  step={"cart"}
                  isDisabled={!allProductsCanPayWithBonus}
                />}
            </div> :
            <EmptyCartPage/>}
        </div>

        {isModalOpen &&
          <CustomModal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            component={ModalForUnauthUsers}
          />}
      </div>
  );
};

export default CartPage;
