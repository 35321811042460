import React, {FC} from "react";

import avatar from "../../assets/user-avatar.svg";
import {levelNames} from "../../constants/levelNames";
import {IUserChainStructure} from "../../interfaces/IUserChainStructure";
import ProgressBarLinear from "../ProgressBarLinear/ProgressBarLinear";
import StatusActivity from "../StatusActivity/StatusActivity";
import styles from "./ReferralItemListDetailed.module.scss";

interface IReferralItemListDetailedProps {
  currentUser: IUserChainStructure;
}

const ReferralItemListDetailed: FC<IReferralItemListDetailedProps> = ({currentUser}) => {
  const {
    photo,
    first_name,
    last_name,
    uuid,
    mobile_nummer,
    current_level,
    total_group_volume,
    personal_period_points,
    is_user_activated,
  } = currentUser;

  return (
    <div className={styles.detailedItem}>
      <div className={styles.detailedItem_user}>
        <div className={styles.detailedItem_user_info}>
          <img
            className={styles.detailedItem_user_info_photo}
            src={photo || avatar}
            alt="user`s photo"
            draggable={false}
          />
          <div style={{display: "flex", flexDirection: "column"}}>
            <p className={styles.detailedItem_user_info_name}>{first_name} {last_name}</p>
            <p className={styles.detailedItem_user_info_rank}>{levelNames[current_level - 1]}</p>
          </div>
        </div>

        <div className={styles.detailedItem_user_block}>
          <p className={styles.detailedItem_user_block_title}>ID</p>
          <p className={styles.detailedItem_user_block_data}>{uuid}</p>
        </div>

        <div className={styles.detailedItem_user_block}>
          <p className={styles.detailedItem_user_block_title}>Статус</p>
          <StatusActivity status={is_user_activated ? "Активний" : "Неактивний"}/>
        </div>

        <div className={styles.detailedItem_user_block}>
          <p className={styles.detailedItem_user_block_title}>Номер телефону</p>
          <p className={styles.detailedItem_user_block_data}>{mobile_nummer}</p>
        </div>

        <div className={styles.detailedItem_user_block}>
          <p className={styles.detailedItem_user_block_title}>Об'єм Структури</p>
          <p className={styles.detailedItem_user_block_data}>{total_group_volume.toFixed(2)}б</p>
        </div>
      </div>

      <ProgressBarLinear
        label={"Особистий обʼєм за період"}
        currentPoints={personal_period_points}
        pointsForTheNextLevel={15}
      />
    </div>
  );
};

export default ReferralItemListDetailed;
