import {AccordionDetails, styled} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import React, {useEffect, useState} from "react";
import {FieldError, FieldErrors, FieldValues} from "react-hook-form";

import search from "../../assets/search-white.svg";
import styles from "./DepartmentAddressesAccordion.module.scss";

interface IDepartmentAddressesAccordionProps<T extends FieldValues> {
  errors: FieldErrors<T>;
  name: string;
  setValue: any;
  allDepartmentsInCity: {
    description: string;
    ref: string;
    cityRef: string;
  }[];
  filteredDepartmentsBySearchValue: {
    description: string;
    ref: string;
    cityRef: string;
  }[];
  searchValue: string;
  setSearchValue: (value: string) => void;
  department: {
    description: string;
    ref: string;
    cityRef: string;
  };
  setDepartment: (value: {
    description: string;
    ref: string;
    cityRef: string;
  }) => void;
  city: string;
}

const CustomAccordion = styled(Accordion)({
  width: "100%",
  background: "#1D1D1D",
  display: "flex",
  flexDirection: "column",
  gap: 8,
  boxShadow: "none",
  border: "none",
  "&:before": {
    backgroundColor: "transparent"
  },
  "& .Mui-focusVisible": {
    backgroundColor: "transparent"
  },
});

const CustomAccordionDetails = styled(AccordionDetails)({
  padding: 0,
  display: "flex",
  flexDirection: "column",
  gap: 16
});

const CustomAccordionSummary = styled(AccordionSummary)({
  display: "flex",
  gap: 16,
  padding: 0,
});

const DepartmentAddressesAccordion = <T extends FieldValues>({
  errors,
  name,
  setValue,
  searchValue,
  setSearchValue,
  filteredDepartmentsBySearchValue,
  allDepartmentsInCity,
  department,
  setDepartment,
  city
}: IDepartmentAddressesAccordionProps<T>) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState<boolean>(false);

  const handleSetDepartment = (department: { description: string; ref: string; cityRef: string; }) => {
    setDepartment(department);
    setValue(name, department.description, {shouldValidate: true});

    setIsAccordionOpen(false);
  }

  useEffect(() => {
    if (!isAccordionOpen) {
      setSearchValue("");
    }
  }, [isAccordionOpen]);

  useEffect(() => {
    if (isAccordionOpen) {
      setIsAccordionOpen(false);
      setDepartment({description: "", ref: "", cityRef: ""});
    }
  }, [city]);

  return (
    <CustomAccordion expanded={isAccordionOpen}>
      <CustomAccordionSummary
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <label
          className={styles.label}
          onClick={() => setIsAccordionOpen(!isAccordionOpen)}
        >
          Вулиця або номер відділення
          <div className={styles.label_input}>
            {department.description}
          </div>
          {errors[name] && <span className={styles.label_error}>{(errors[name] as FieldError).message}</span>}
        </label>

      </CustomAccordionSummary>
      <CustomAccordionDetails>
        <div className={styles.expandedAccordion}>
          <div className={styles.expandedAccordion_input}>
            <img
              src={search}
              alt="search icon"
            />
            <input
              type="text"
              placeholder={"Введіть номер відділення"}
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </div>

          <ul className={styles.expandedAccordion_list}>
            {(searchValue ? filteredDepartmentsBySearchValue : allDepartmentsInCity).map((item, index) =>
              <li
                onClick={() => handleSetDepartment(item)}
                key={index}
                className={styles.expandedAccordion_list_item}
              >{item.description}</li>)}
            {(searchValue ? filteredDepartmentsBySearchValue : allDepartmentsInCity).length === 0 &&
              <li
                className={styles.expandedAccordion_list_item}
              >На жаль, адреси по вашому запиту не знайдено</li>}
          </ul>
        </div>
      </CustomAccordionDetails>
    </CustomAccordion>
  );
};

export default DepartmentAddressesAccordion;
