import {FC, useEffect, useRef} from "react";
import {Outlet, useNavigate} from "react-router-dom";

import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import {isLoggedInListener} from "../../helpers/isLoggedInListener";
import {useAppDispatch, useAppSelector} from "../../hooks/reduxHooks";
import {MAIN_ROUTES} from "../../routes/mainRoutes";
import styles from "./MainLayout.module.scss";

interface IMainLayoutProps {
}

const MainLayout: FC<IMainLayoutProps> = () => {
  const bestsellers = useRef<HTMLDivElement | null>(null);
  const newProducts = useRef<HTMLDivElement | null>(null);
  const promotionalProducts = useRef<HTMLDivElement | null>(null);

  const dispatch = useAppDispatch();
  const {deliveryAddresses} = useAppSelector(state => state.delivery);
  const {bannerImages} = useAppSelector(state => state.images);

  const navigate = useNavigate();

  isLoggedInListener(dispatch, navigate);

  if (!deliveryAddresses) {
    navigate(MAIN_ROUTES.LOGIN);
  }

  useEffect(() => {
    // TODO
    if (!bannerImages) {
      navigate(MAIN_ROUTES.LOGIN);
    }

    // TODO
    if (!deliveryAddresses) {
      navigate(MAIN_ROUTES.LOGIN);
    }
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapper_mainLayout}>
        <div className={styles.wrapper_mainLayout_flex}>
          {/*{!user && <BonusFrame/>}*/}
          <Header
            bestsellersRef={bestsellers}
            newProductsRef={newProducts}
            promotionalProductsRef={promotionalProducts}
          />
          <Outlet context={{bestsellers, newProducts, promotionalProducts}}/>
        </div>
        <Footer/>
      </div>
    </div>
  );
};

export default MainLayout;
