import {endpoints} from "../configs/endpoints";
import {IOrder} from "../interfaces/IOrder";
import {instance, IRes} from "./axios.service";

export const orderService = {
  create: (data: IOrder): IRes<{ url: string }> => {
    const res = instance.post(endpoints.order.base, data);

    return res;
  },
  getByCurrentUser: (page: number) => {
    const res = instance.get(endpoints.order.base, {
      params: {
        page
      }
    });

    return res;
  },
  updateById: (uuid: string): IRes<{ url: string }> => {
    const res = instance.patch(`${endpoints.order.base}${uuid}/`);

    return res;
  },
  deleteById: (uuid: string) => {
    const res = instance.delete(`${endpoints.order.base}${uuid}/`);

    return res;
  },
}
