import React, {FC, useState} from "react";

import companyValues from "../../assets/company-values.svg";
import companyValuesHover from "../../assets/company-values-hover.svg";
import styles from "./CompanyValuesItem.module.scss";

interface ICompanyValuesItemProps {
  title: string;
  description: string;
}

const CompanyValuesItem: FC<ICompanyValuesItemProps> = ({title, description}) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className={styles.item}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <img src={isHovered ? companyValuesHover : companyValues} alt="finger print"/>

      <div className={styles.item_description}>
        <h3>{title}</h3>
        <p>{description}</p>
      </div>
    </div>
  );
};

export default CompanyValuesItem;
