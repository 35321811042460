import {createTheme, ThemeProvider} from "@mui/material/styles";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import React, {FC} from "react";

import {IDatePickerValue} from "../../interfaces/IDatePickerValue";
import styles from "./CustomDatePicker.module.scss";

interface ICustomDatePickerProps {
    label: string;
    value: IDatePickerValue | null,
    setValue: (value: IDatePickerValue | null) => void;
}

const CustomDatePicker: FC<ICustomDatePickerProps> = ({label, value, setValue}) => {
  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  return (
    <ThemeProvider theme={darkTheme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <label className={styles.label}>
          {label}
          <DatePicker
            className={styles.label_datePicker}
            label=""
            value={value}
            onChange={(newValue: IDatePickerValue | null) => setValue(newValue)}
          />
        </label>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default CustomDatePicker;
