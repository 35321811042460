import Joi from "joi";

import {REGEX} from "../constants/regex";

export const registrationValidator = Joi.object({
  last_name: Joi.string().required().messages({
    "string.empty": "Заповніть це поле",
  }),
  first_name: Joi.string().required().messages({
    "string.empty": "Заповніть це поле",
  }),
  email: Joi.string().pattern(REGEX.EMAIL).required().messages({
    "string.empty": "Заповніть це поле",
    "string.pattern.base": "Невалідний email",
  }),
  mobile_number: Joi.string().required().messages({
    "string.empty": "Заповніть це поле",
    "number.base": "Поле повинно містити тільки цифри",
    "string.pattern.base": "Невалідний номер телефону",
  }),
  city: Joi.string().required().messages({
    "string.empty": "Заповніть це поле",
  }),
  password: Joi.string().required().min(8).messages({
    "string.empty": "Заповніть це поле",
    "string.min": "Мінімальна довжина 8 символів",
  }),
  confirm_password: Joi.string().required().min(8).valid(Joi.ref("password")).messages({
    "string.empty": "Заповніть це поле",
    "string.min": "Мінімальна довжина 8 символів",
    "any.only": "Паролі не збігаються",
  }),
  invited_by: Joi.string().optional().allow("").messages({
    "string.empty": "Заповніть це поле",
  })
});
