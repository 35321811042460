import React from "react";
import {FieldError, FieldErrors, FieldValues, Path, UseFormRegister} from "react-hook-form";

import styles from "./InputField.module.scss";

interface IInputFieldProps<T extends FieldValues> {
    width: string;
    label: string;
    type: string;
    name: Path<T>;
    register: UseFormRegister<T>;
    errors: FieldErrors<T>;
    placeholder: string;
    requestErrorMessage?: string; //this is only for the last input in the form
    disabled?: boolean;
}

const InputField = <T extends FieldValues>({
  width,
  label,
  type,
  name,
  register,
  errors,
  placeholder,
  requestErrorMessage,
  disabled = false,
}: IInputFieldProps<T>) => {

  return (
    <div style={{width}} className={styles.wrapper}>
      <label className={styles.wrapper_label}>
        {label}
        <input
          placeholder={placeholder}
          type={type}
          {...register(name)}
          className={`${styles.wrapper_label_input} ${errors[name] ? styles.error : ""}`}
          disabled={disabled}
        />
      </label>

      {errors[name] &&
                <span className={styles.wrapper_error}>{(errors[name] as FieldError).message}</span>}
      {requestErrorMessage &&
                <span className={styles.wrapper_error}>{requestErrorMessage}</span>}
    </div>
  );
};

export default InputField;
