import React, {FC} from "react";

import styles from "./MainButtonWhiteBackground.module.scss";

interface IMainButtonWhiteBackgroundProps {
    text: string;
    width: string;
    onClick: any;
    type: "button" | "submit" | "reset";
}

const MainButtonWhiteBackground: FC<IMainButtonWhiteBackgroundProps> = ({text, width, onClick, type}) => {
  return (
    <button
      className={styles.button}
      style={{width}}
      onClick={onClick && onClick}
      type={type}
    >
      {text}
    </button>
  );
};

export default MainButtonWhiteBackground;
