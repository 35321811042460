import React, {FC, useEffect, useRef, useState} from "react";

import {IDatePickerValue} from "../../interfaces/IDatePickerValue";
import CustomCheckbox from "../CustomCheckbox/CustomCheckbox";
import CustomDatePicker from "../CustomDatePicker/CustomDatePicker";
import styles from "./FiltersListOfOrdersPage.module.scss";

interface IFiltersListOfOrdersPageProps {
}

const FiltersListOfOrdersPage: FC<IFiltersListOfOrdersPageProps> = () => {
  const [status, setStatus] = useState<string>("done");
  const [isFiltersOpen, setIsFiltersOpen] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<IDatePickerValue | null>(null);
  const [endDate, setEndDate] = useState<IDatePickerValue | null>(null);

  const filtersRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (filtersRef.current && !filtersRef.current.contains(event.target as Node)) {
      setIsFiltersOpen(false);
    }
  };

  const handleScroll = () => {
    setIsFiltersOpen(false);
  };

  // if the user scrolls or clicks outside the filters, it closes
  useEffect(() => {
    if (isFiltersOpen) {
      document.addEventListener("scroll", handleScroll);
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("scroll", handleScroll);
    };
  }, [isFiltersOpen]);

  return (
    <div className={styles.filters} ref={filtersRef}>
      <button
        className={styles.filters_button}
        onClick={() => setIsFiltersOpen(prev => !prev)}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path
            d="M1.33301 3.06667C1.33301 2.6933 1.33301 2.50661 1.40567 2.36401C1.46959 2.23856 1.57157 2.13658 1.69701 2.07266C1.83962 2 2.02631 2 2.39967 2H13.5997C13.973 2 14.1597 2 14.3023 2.07266C14.4278 2.13658 14.5298 2.23856 14.5937 2.36401C14.6663 2.50661 14.6663 2.6933 14.6663 3.06667V3.51293C14.6663 3.69213 14.6663 3.78173 14.6444 3.86504C14.625 3.93887 14.5931 4.00882 14.55 4.07184C14.5014 4.14295 14.4337 4.20164 14.2983 4.319L10.0344 8.01434C9.899 8.1317 9.83129 8.19038 9.78267 8.26149C9.73958 8.32451 9.70764 8.39446 9.68824 8.46829C9.66634 8.55161 9.66634 8.64121 9.66634 8.8204V12.3056C9.66634 12.436 9.66634 12.5011 9.64531 12.5575C9.62673 12.6073 9.59652 12.6519 9.55718 12.6877C9.51266 12.7281 9.45213 12.7524 9.33108 12.8008L7.06442 13.7074C6.81939 13.8054 6.69687 13.8545 6.59852 13.834C6.51252 13.8161 6.43704 13.765 6.38851 13.6918C6.33301 13.6081 6.33301 13.4762 6.33301 13.2122V8.8204C6.33301 8.64121 6.33301 8.55161 6.31111 8.46829C6.29171 8.39446 6.25977 8.32451 6.21668 8.26149C6.16806 8.19038 6.10035 8.1317 5.96493 8.01434L1.70108 4.319C1.56566 4.20164 1.49796 4.14295 1.44934 4.07184C1.40625 4.00882 1.37431 3.93887 1.35491 3.86504C1.33301 3.78173 1.33301 3.69213 1.33301 3.51293V3.06667Z"
            stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
        <span className={styles.filters_text}>Фільтри</span>
      </button>

      {isFiltersOpen &&
                <div className={styles.filters_open}>
                  <div className={styles.filters_open_block}>
                    <p className={styles.filters_text}>Статус</p>
                    <CustomCheckbox
                      id={"1"}
                      label={"Виконано"}
                      isChecked={status === "done"}
                      setIsChecked={() => setStatus("done")}
                      position={"center"}
                    />
                    <CustomCheckbox
                      id={"2"}
                      label={"Скасовано"}
                      isChecked={status === "cancelled"}
                      setIsChecked={() => setStatus("cancelled")}
                      position={"center"}
                    />
                    <CustomCheckbox
                      id={"3"}
                      label={"Повернено"}
                      isChecked={status === "returned"}
                      setIsChecked={() => setStatus("returned")}
                      position={"center"}
                    />
                  </div>

                  <div className={styles.filters_open_block}>
                    <p className={styles.filters_text}>Дата замовлення</p>

                    <div className={styles.filters_open_block_dates}>
                      <CustomDatePicker
                        label={"Від"}
                        value={startDate}
                        setValue={setStartDate}
                      />
                      <CustomDatePicker
                        label={"До"}
                        value={endDate}
                        setValue={setEndDate}
                      />
                    </div>
                  </div>

                  {/*<MainButtonRedBackground*/}
                  {/*    text={"Застосувати"}*/}
                  {/*    width={"100%"}*/}
                  {/*    onClick={null}*/}
                  {/*    type={"button"}*/}
                  {/*    isDisabled={false}/>*/}
                </div>}
    </div>
  );
};

export default FiltersListOfOrdersPage;
