import React, {useEffect, useState} from "react";
import {FieldErrors, FieldValues, UseFormRegister, UseFormSetValue} from "react-hook-form";

import {useAppDispatch, useAppSelector} from "../../hooks/reduxHooks";
import {IRecipientData} from "../../interfaces/IRecipientData";
import {deliveryActions} from "../../store/slices/deliverySlice";
import CustomDropdown from "../CustomDropdown/CustomDropdown";
import CustomModal from "../CustomModal/CustomModal";
import InputField from "../InputField/InputField";
import InputFieldMask from "../InputFieldMask/InputFieldMask";
import ModalNewDeliveryAddress from "../ModalNewDeliveryAddress/ModalNewDeliveryAddress";
import styles from "./PersonalOrderForm.module.scss";

interface IPersonalOrderFormProps extends FieldValues {
  register: UseFormRegister<IRecipientData>;
  errors: FieldErrors<IRecipientData>;
  mobileNumber: string;
  setValue: UseFormSetValue<IRecipientData>;
}

const PersonalOrderForm = <T extends FieldValues>({
  register,
  errors,
  mobileNumber,
  setValue
}: IPersonalOrderFormProps) => {
  const dispatch = useAppDispatch();
  const {deliveryAddresses} = useAppSelector(state => state.delivery);

  // array of choices delivery method names for dropdown
  const deliveryMethodNames = deliveryAddresses.map(item => item.delivery_method_name);
  deliveryMethodNames.push("Додати нову адресу...");

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [deliveryMethodName, setDeliveryMethodName] = useState<string>(deliveryMethodNames?.[0] || "");
  const [isNumberOfDepartment, setIsNumberOfDepartment] = useState<boolean>(false);

  useEffect(() => {
    setDeliveryMethodName(deliveryMethodNames?.[0]);
  }, [deliveryAddresses]);

  useEffect(() => {
    if (deliveryMethodName !== "Додати нову адресу...") {
      const deliveryAddress = deliveryAddresses[0];
      dispatch(deliveryActions.setDeliveryAddress(deliveryAddress));
      const {delivery_supplier_name, city, number_of_department, delivery_method_name} = deliveryAddress;

      setValue("delivery_supplier_name", delivery_supplier_name);
      setValue("city", city);
      setValue("delivery_method_name", delivery_method_name);
      if (number_of_department) {
        setValue("number_of_department", number_of_department);
        setIsNumberOfDepartment(true);
      }
    }
  }, []);

  const handleSetDeliveryMethodName = (deliveryMethodName: string) => {
    // find index this method name in available user`s delivery addresses
    const index = deliveryMethodNames.findIndex((methodName) => methodName === deliveryMethodName);

    if (deliveryMethodName !== "Додати нову адресу...") {
      setDeliveryMethodName(deliveryMethodName);

      const deliveryAddress = deliveryAddresses[index];
      dispatch(deliveryActions.setDeliveryAddress(deliveryAddress));
      const {delivery_supplier_name, city, number_of_department} = deliveryAddress;

      setValue("delivery_supplier_name", delivery_supplier_name);
      setValue("city", city);
      setValue("delivery_method_name", deliveryMethodName);

      if (number_of_department) {
        setValue("number_of_department", number_of_department);
        setIsNumberOfDepartment(true);
      } else {
        setIsNumberOfDepartment(false);
      }
    } else {
      setIsModalOpen(true);
    }
  }

  return (
    <form className={styles.form}>
      <div className={styles.form_personal}>
        <h3 className={styles.form_personal_title}>Особиста Інформація</h3>
        <div className={styles.flex2}>
          <InputField
            width={"100%"}
            label={"Прізвище"}
            type={"text"}
            name={"receiver_last_name"}
            register={register}
            errors={errors}
            placeholder={""}
          />
          <InputField
            width={"100%"}
            label={"Імʼя"}
            type={"text"}
            name={"receiver_first_name"}
            register={register}
            errors={errors}
            placeholder={""}
          />
        </div>
        <div className={styles.flex2}>
          <InputFieldMask
            name={"receiver_mobile"}
            register={register}
            errors={errors}
            placeholder={""}
            mobile_number_value={mobileNumber}
          />
          <InputField
            width={"100%"}
            label={"Email"}
            type={"email"}
            name={"receiver_email"}
            register={register}
            errors={errors}
            placeholder={""}
          />
        </div>
      </div>
      <div className={styles.form_shipping}>
        <h3 className={styles.form_shipping_title}>Інформація про Доставку</h3>
        <div className={styles.flex2}>
          <CustomDropdown
            width={"100%"}
            option={deliveryMethodName}
            setOption={handleSetDeliveryMethodName}
            defaultOption={""}
            data={deliveryMethodNames}
            label={"Обрати адресу"}
          />
          <InputField
            width={"100%"}
            label={"Спосіб доставки"}
            type={"text"}
            name={"delivery_supplier_name"}
            register={register}
            errors={errors}
            placeholder={""}
            disabled={true}
          />
        </div>
        <div className={styles.flex2}>
          <InputField
            width={"100%"}
            label={"Місто"}
            type={"text"}
            name={"city"}
            register={register}
            errors={errors}
            placeholder={""}
            disabled={true}
          />

          {isNumberOfDepartment &&
            <InputField
              width={"100%"}
              label={"Відділення"}
              type={"number"}
              name={"number_of_department"}
              register={register}
              errors={errors}
              placeholder={""}
              disabled={true}
            />}
        </div>
      </div>

      {isModalOpen &&
        <CustomModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          component={ModalNewDeliveryAddress}
        />}
    </form>
  );
};

export default PersonalOrderForm;
