import React, {FC, useEffect} from "react";

import CertificateItem from "../../components/CertificateItem/CertificateItem";
import styles from "./CertirficatesPage.module.scss";

interface ICertirficatesPageProps {
}

const CertirficatesPage: FC<ICertirficatesPageProps> = () => {
  useEffect(() => {
    // scroll to the top
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.certificatesPage}>
        <h1>СЕРТИФІКАТИ <span>ЯКОСТІ</span></h1>

        <div className={styles.certificatesPage_certificates}>
          <CertificateItem/>
        </div>
      </div>
    </div>
  );
};

export default CertirficatesPage;
