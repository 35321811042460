import React, {FC} from "react";

import styles from "./MainButtonTransparentBackground.module.scss";

interface IMainButtonTransparentBackgroundProps {
    text: string;
    width: string;
    onClick: any;
    type: "button" | "submit" | "reset";
}

const MainButtonTransparentBackground: FC<IMainButtonTransparentBackgroundProps> = ({text, width, onClick, type}) => {
  return (
    <button
      className={styles.button}
      style={{width}}
      onClick={onClick && onClick}
      type={type}
    >
      {text}
    </button>
  );
};

export default MainButtonTransparentBackground;
