import React, {FC, useState} from "react";

import edit from "../../assets/edit.svg";
import avatar from "../../assets/user-avatar.svg";
import {useAppSelector} from "../../hooks/reduxHooks";
import CustomModal from "../CustomModal/CustomModal";
import Loader from "../Loader/Loader";
import ModalEditUserData from "../ModalEditUserData/ModalEditUserData";
import styles from "./UserDataProfile.module.scss";

interface IUserDataProfileProps {
}

const UserDataProfile: FC<IUserDataProfileProps> = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const {user, status} = useAppSelector(state => state.user);

  if (user) {
    const {
      uuid,
      mobile_nummer,
      first_name,
      last_name,
      email,
      city,
      date_of_birth,
      photo,
    } = user!; // it indicates that user not null

    if (status === "loading") {
      return <Loader/>;
    }

    return (
      <>
        <div className={styles.userInfo}>
          <div className={styles.userInfo_basic}>
            <img src={photo || avatar} alt="user`s photo" draggable={false}/>
            <div style={{width: "100%", display: "flex"}}>
              <div className={styles.userInfo_basic_content}>
                <p className={styles.userInfo_basic_content_fullname}>{last_name} {first_name}</p>
                <p className={styles.userInfo_basic_content_id}>ID: {uuid}</p>
              </div>
              <button>
                <img
                  onClick={() => setIsModalOpen(true)}
                  src={edit}
                  alt="edit button"
                  draggable={false}
                />
              </button>
            </div>
          </div>

          <div className={styles.userInfo_divider}></div>

          <div className={styles.userInfo_details}>
            <div className={styles.userInfo_details_item}>
              <p className={styles.userInfo_details_item_title}>Місто Проживання</p>
              <p className={styles.userInfo_details_item_data}>{city}</p>
            </div>
            <div className={styles.userInfo_details_item}>
              <p className={styles.userInfo_details_item_title}>Дата Народження</p>
              <p className={styles.userInfo_details_item_data}>{new Date(date_of_birth).toLocaleDateString()}</p>
            </div>
            <div className={styles.userInfo_details_item}>
              <p className={styles.userInfo_details_item_title}>Email</p>
              <p className={styles.userInfo_details_item_data}>{email}</p>
            </div>
            <div className={styles.userInfo_details_item}>
              <p className={styles.userInfo_details_item_title}>Номер Телефону</p>
              <p className={styles.userInfo_details_item_data}>{mobile_nummer}</p>
            </div>
          </div>
        </div>

        <CustomModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          component={ModalEditUserData} // opened modal component
        />
      </>
    );
  }

  return null;
};

export default UserDataProfile;
