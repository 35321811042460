import {endpoints} from "../configs/endpoints";
import {ICredentials} from "../interfaces/ICredentials";
import {ITokens} from "../interfaces/ITokens";
import {IUserDetailed} from "../interfaces/IUserDetailed";
import {instance, IRes} from "./axios.service";

export const authService = {
  login: (data: ICredentials): IRes<ITokens> => {
    const res = instance.post(endpoints.auth.login, data);

    return res;
  },
  create: (data: FormData): IRes<IUserDetailed> => {
    const res = instance.post(endpoints.auth.create, data, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });

    return res;
  },
}
