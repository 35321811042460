import React, {FC} from "react";
import {useNavigate} from "react-router-dom";

import banner from "../../assets/banner-bottom.png";
import {MAIN_ROUTES} from "../../routes/mainRoutes";
import MainButtonWhiteBackground from "../MainButtonWhiteBackground/MainButtonWhiteBackground";
import styles from "./BannerJoinUs.module.scss";

interface IBannerJoinUsProps {
}

const BannerJoinUs: FC<IBannerJoinUsProps> = () => {
  const navigate = useNavigate();

  return (
    <>
      {/* desktop */}
      <div className={styles.desktop}>
        <img
          className={styles.desktop_backgroundImage}
          src={banner}
          alt="banner"
        />
        <div className={styles.desktop_content}>
          <h3 className={styles.desktop_content_description}>Приєднуйся до нашої сучасної маркетингової компанії, де
            креативність та інновації завжди на першому місці, а продажі - це захоплюючі виклики для досягнення
            успіху</h3>
          <MainButtonWhiteBackground
            text={"Приєднатись"}
            width={"auto"}
            onClick={() => navigate(MAIN_ROUTES.REGISTRATION)}
            type={"button"}
          />
        </div>
      </div>

      {/* mobile */}
      <div className={styles.mobile}>
        <img
          className={styles.mobile_backgroundImage}
          src={banner}
          alt="banner"
        />
        <h3 className={styles.mobile_description}>Приєднуйся до нашої сучасної маркетингової компанії</h3>
        <MainButtonWhiteBackground
          text={"Приєднатись"}
          width={"auto"}
          onClick={() => navigate(MAIN_ROUTES.REGISTRATION)}
          type={"button"}
        />
      </div>
    </>
  );
};

export default BannerJoinUs;
