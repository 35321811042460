import React, {FC, useState} from "react";

import CustomCheckbox from "../CustomCheckbox/CustomCheckbox";
import MainButtonRedBackground from "../MainButtonRedBackground/MainButtonRedBackground";
import styles from "./ModalPrivacyPolicy.module.scss";

interface IModalPrivacyPolicyProps {
    handleCreateOrder?: () => void;
    handleClose: () => void;
    type: "preview" | "create-order";
}

const ModalPrivacyPolicy: FC<IModalPrivacyPolicyProps> = ({handleClose, handleCreateOrder, type}) => {
  const [isAgreement, setIsAgreement] = useState<boolean>(false);

  return (
    <div className={styles.modal}>
      <div className={styles.modal_content}>
        <div className={styles.modal_content_titleContainer}>
          <h3>Публічний договір</h3>
          <div>
            <p>Корисна інформація про існуючі умови з організації купівлі-продажу через інтернет між особою,
                            яка вчиняє
                            покупку
                            (що іменується далі "Покупець") та ТОВ «ЛАЙФСТАЙЛЛ»</p>
            <p>1. Інформація про товар </p>
            <p>1.1. Товар, представлений в каталозі через фото-зразки, є оригінальною продукцією. </p>
            <p>1.2. Кожен фото-зразок може супроводжуватися текстовою інформацією: артикулом, ціною і описом
                            товару та іншими даними. </p>
            <p>Зазначена на Сайті ціна товару може бути змінена Інтернет-магазином в односторонньому
                            порядку. </p>
          </div>
        </div>

        <div className={styles.modal_content_block}>
          <p className={styles.subtitle}>Товар та порядок придбання товарів </p>

          <div>
            <p className={styles.description}>2.1 Супроводжуючі Товар фотографії можуть незначно
                            відрізнятися від
                            фактичного зовнішнього вигляду Товару, з
                            огляду на особливості колірної передачі різних пристроїв, за допомогою яких проглядається
                            Сайт.
                            Супроводжуючі
                            Товар описи/характеристики не претендують на вичерпну інформативність і можуть містити
                            друкарські помилки.
                            Оновлення інформації, представленої на Сайті, проводиться щодня. </p>
            <p className={styles.description}>2.2. Замовлення може бути оформлене Покупцем самостійно через
                            корзину
                            сайту.</p>
            <p className={styles.description}>2.3. Покупець має право оформити замовлення на будь-який
                            товар,
                            представлений на сайті. </p>
            <p className={styles.description}>2.4. При відсутності товару Покупець має право замінити його
                            аналогічним
                            товаром, відмовитися від даного товару,
                            анулювати замовлення. </p>
            <p className={styles.description}>2.5. У разі відсутності замовлених Покупцем Товарів на складі
                            Продавця,
                            останній має право виключити вказаний
                            Товар із Замовлення/анулювати Замовлення Покупця, повідомивши про це Покупця шляхом дзвінка
                            або
                            направлення
                            відповідного електронного повідомлення на адресу, вказану Покупцем при реєстрації, або іншим
                            способом. </p>
            <p className={styles.description}>2.6 У випадку ануляції замовлення повністю або передплаченого
                            Замовлення
                            вартість анульованого Товару
                            повертається Продавцем Покупцю способом, яким Товар був оплачений, на підставі Заяви згідно
                            за Законом
                            України
                            “Про захист прав споживачів”.</p>
            <p className={styles.description}>2.7 Покупець несе повну відповідальність за надання
                            неправдивих відомостей, що спричинило за собою неможливість
                            належного виконання Продавцем своїх зобов'язань перед Покупцем.</p>
            <p className={styles.description}>2.8. Очікувана дата передачі Замовлення в Службу доставки
                            повідомляється Покупцю на сайті, його можна
                            відслідкувати по товаро транспортній накладні. </p>
          </div>
        </div>

        <div className={styles.modal_content_block}>
          <p className={styles.subtitle}>Ціна і порядок оплати</p>

          <div>
            <p className={styles.description}>3.1. Ціна кожного окремого Товару визначається Продавцем і
                            вказується на сайті www.life-stylle.com . Ціна
                            Замовлення визначається шляхом додавання ціни всіх включених в Замовлення Товарів, яка
                            дорівнює сумі,
                            підлягаючої до сплати, що вказується на сайті Інтернет-магазину при оформленні Замовлення в
                            момент його
                            розміщення. Вартість послуги доставки або відправлення товару не вказана і не врахована в
                            замовленні. </p>
            <p className={styles.description}>3.2. Ціна договору дорівнює ціні Замовлення. Сума замовлення
                            може змінюватися в залежності від ціни, кількості і
                            номенклатури Товару. </p>
            <p className={styles.description}>3.3 Оплата Послуг здійснюється в національній валюті України -
                            гривні. </p>
            <p className={styles.description}>3.4 Замовлення вважається сплаченим з моменту надходження
                            грошових коштів на розрахунковий рахунок Продавця.
                            Факт оплати Замовлення свідчить про згоду Покупця з умовами роботи ТОВ”ЛАЙФСТАЙЛЛ”
                            зазначеними у цьому
                            Договорі. </p>
            <p className={styles.description}>3.5 До моменту зарахування коштів Покупця на розрахунковий
                            рахунок Продавця товар не резервується. Продавець
                            не може гарантувати доступність Товару Продавця в кількості, зазначеній в момент оформлення
                            Замовлення, в
                            результаті чого можуть збільшитися терміни обробки Замовлення. У разі необхідності
                            здійснення повернення коштів
                            Продавцем Покупцеві для здійснення повернення коштів Покупець зобов'язаний повідомити
                            Продавцеві реквізити
                            банківського рахунку, на який Продавець зобов'язаний перерахувати грошові кошти. </p>
            <p className={styles.description}>3.6 Видача і відправка Продукції Покупцеві здійснюється після
                            повної оплати вартості Замовлення. </p>
            <p className={styles.description}>3.7 Замовлення надсилається покупцю наступного робочого дня
                            після отримання передоплати.</p>
            <p className={styles.description}>3.8 При замовленні товару на суму понад 1300 грн - доставка
                            оплачується ТОВ «ЛАЙФСТАЙЛЛ» на відділення «Нової пошти» (Крім
                            товарів зі знижкою, що беруть участь в акціях і розпродажу!). </p>
            <p className={styles.description}>4. Відповідальність за якість і комплектність продукції несе
                            Продавець</p>
            <p className={styles.description}>4.1. Відповідальність за достовірність документів, що
                            підтверджують якість товару, несе Продавець. </p>
            <p className={styles.description}>4.2 У разі виявлення неякісної продукції після поставки її
                            Покупцю, останній має право заявити претензії щодо якості цього товару
                            у відповідності до положень Закону України «Про захист прав споживачів». </p>
            <p className={styles.description}>4.3 Відповідальність за якість і комплектність продукції
                            знімається з Продавця, якщо псування продукції сталося з вини Перевізника
                            або Кур'єра. </p>
            <p className={styles.description}>4.4 Відповідальність за якість продукції знімається з
                            Продавця, якщо псування продукції сталося з вини Покупця, коли останній
                            використовує товар не за призначенням. </p>
            <p className={styles.description}>4.5 При продажу товару за описом Продавець зобов'язаний
                            передати покупцеві товар, який відповідає опису на сайті www.life-
                            stylle.com. (Опис товару являє собою певний перелік споживчих властивостей (експлуатаційних
                            характеристик) товару, який
                            передається покупцеві. Опис товару може супроводжуватися його фотографією, графічним
                            зображенням і т. п.).</p>
            <p className={styles.description}>4.6 Гарантійні терміни (обов'язки) на товари, які продаються
                            складають 30 днів (на продукцію, за винятком продукції, яка згідно
                            діючого законодавства не підлягає поверненню). </p>
            <p className={styles.description}>4.7 Терміни на повернення і обмін товару, який підлягає
                            поверненню та обміну відповідно до Закону України «Про захист прав
                            споживачів», які продаються на сайті www.life-stylle.com складають 14 календарних днів.
                            Обмін товару проводиться, якщо він не
                            використовувався і збережено його товарний вигляд, споживчі властивості, пломби, ярлики,
                            упаковка, заповнений бланк-повернення
                            і розрахунковий документ.</p>
            <p className={styles.description}>4.8 Відповідно до закону України про “Захист прав споживачів»
                            обмін і повернення товару можливе в строк до 14 календарних днів
                            з моменту відправки замовлення клієнта, крім товарів, повний перелік яких зазначено у Законі
                            України “Про захист прав
                            споживачів”. </p>
          </div>
        </div>

        <div className={styles.modal_content_block}>
          <p className={styles.subtitle}>Доставка та передача товару Покупцеві </p>

          <div>
            <p className={styles.description}>5.1. Доставка товару, придбаного www.life-stylle.com,
                            здійснюється компанією "Нова Пошта" та «Укрпошта» по всій території
                            України, за виключенням тимчасово окупованих територій та АР Крим.</p>
            <p className={styles.description}>5.2. Відправка замовленого товару по Україні здійснюється
                            протягом 1-4 робочих днів з дня підтвердження замовлення Покупцем,
                            за умови наявності оплати, у разі якщо не обрано послугу накладений платіж. Неділя і
                            офіційні свята не входять в число робочих
                            днів. </p>
            <p className={styles.description}>5.3. Продавець не несе відповідальності за термін доставки
                            замовлення, тому що він залежить від дій третіх осіб (перевізників). </p>
            <p className={styles.description}>5.4. Покупець не зобов'язаний компенсувати витрати
                            www.life-stylle.com пов'язані з доставкою замовлення, якщо в процесі
                            приймання товару виявиться, що поставлений товар неналежної якості, кількості, асортименту
                            або комплектності з вини Інтернет-
                            магазину. </p>
            <p className={styles.description}>5.5. При відмові Покупця від товару належної якості або від
                            замовлення в момент доставки, Покупцем оплачуються витрати ТОВ
                            “ЛАЙФСТАЙЛЛ” на доставку замовлення. </p>
            <p className={styles.description}>5.6 Термін доставки замовлення залежить від Перевізника і
                            становить 1-7 календарних днів, в залежності від адреси населеного
                            пункту. </p>
          </div>
        </div>

        <div className={styles.modal_content_block}>
          <p className={styles.subtitle}>Порядок повернення товару</p>

          <div>
            <p className={styles.description}>6.1. Повернення або обмін товару можливий в разі дотримання
                            умов закону України "Про захист прав споживачів" - Розділ II, ст. 9 п. 1, і якщо збережено:
                            його товарний вигляд, споживчі властивості, оригінальна фабрична упаковка, ярлики. Товар,
                            який має сліди використання - поверненню не підлягає (сліди використання - будь-які зміни в
                            товарі, що свідчать про його експлуатацію за призначенням). Повернення товару зазначеного в
                            переліку товарів, які не підлягають поверненню неможливе. </p>
            <p className={styles.description}>6.2. Поверненню товару зумовлює звернення Покупця в
                            Інтернет-магазин через форму "Повернення товару", або написавши на електронну пошту чи
                            зв’язавшись з менеджером магазину. </p>
            <p className={styles.description}>6.3 Повернення Товару неналежної якості: </p>
            <p className={styles.description}>6.4. Під товаром неналежної якості мається на увазі товар,
                            який має явний дефект виробничого характеру. Отриманий Товар повинен відповідати опису на
                            Сайті. Відмінність елементів дизайну або оформлення від заявленого на Сайті опису не є
                            ознакою неналежної якості. </p>
            <p className={styles.description}>6.5. Зовнішній вигляд Товару, а також комплектність усього
                            Замовлення повинні бути перевірені Одержувачем в момент доставки Товару. </p>
            <p className={styles.description}>6.6. При доставці Товару Покупець ставить свій підпис в
                            квитанції про доставку. Після отримання Замовлення претензії до зовнішніх дефектів товару,
                            його кількості, комплектності та товарного вигляду не приймаються. Сторони погодили, що в
                            разі недотримання обов'язкових вимог зазначеної процедури, признається отримання Покупцем
                            Товару в належному стані - без будь-яких механічних пошкоджень і в повній комплектності.</p>
          </div>
        </div>

        <div className={styles.modal_content_block}>
          <p className={styles.subtitle}>Відповідальність</p>

          <div>
            <p className={styles.description}>7.1 Продавець не несе відповідальності за вибір Покупцем
                            Товару, він лише гарантує відповідність замовлених позицій. </p>
            <p className={styles.description}>7.2 Продавець не несе відповідальність за строк доставки
                            Товару після передачі його транспортній компанії. </p>
            <p className={styles.description}>8. Права, обов'язки і конфіденційність </p>
            <p className={styles.description}>8.1. www.life-stylle.com створений для організації
                            дистанційного способу продажу товарів через інтернет. </p>
            <p className={styles.description}>8.2. ТОВ “ЛАЙФСТАЙЛЛ” не несе відповідальності за зміст і
                            достовірність інформації, наданої Покупцем при оформленні замовлення. </p>
            <p className={styles.description}>8.3. Покупець несе відповідальність за достовірність
                            інформації, зазначеної при оформленні замовлення. Використання ресурсу www.life-stylle.com
                            для перегляду товару, а також для оформлення замовлення є для Покупця безоплатним. </p>
            <p className={styles.description}>8.4. Інформація, яку надає Покупець є конфіденційною.
                            використовує інформацію про Покупця виключно в цілях функціонування інтернет-магазину
                            (відправлення повідомлення Покупцю про виконання замовлення, відправки рекламних повідомлень
                            і т.д.). </p>
            <p className={styles.description}>8.5. Договором або реєстрацією на сайті ресурсу
                            www.life-stylle.com (заповнення реєстраційної анкети) Покупець добровільно дає згоду на збір
                            і обробку своїх персональних даних у зареєстрованій базі Продавця з подальшою метою: дані,
                            які стають відомі продавцеві будуть використані в комерційних цілях, в тому числі для
                            обробки замовлень на придбання товарів, отримання інформації про замовлення, передача
                            телекомунікаційними засобами зв'язку (по електронній пошті, мобільним зв'язком) рекламних та
                            спеціальних пропозицій, інформації про акції, розіграші або будь-яку іншу інформацію про
                            діяльність магазину. Для цілей, передбачених цим пунктом, Продавець має право направляти
                            листи, повідомлення та матеріали на поштову адресу, e-mail Покупця, а також відправляти
                            sms-повідомлення, здійснювати дзвінки на вказаний в анкеті телефонний номер. </p>
            <p className={styles.description}>8.6. Покупець дає Продавцю право здійснювати обробку його
                            персональних даних, в тому числі: розміщувати персональні дані в бази даних Покупця (без
                            додаткового повідомлення Учасника про це), здійснювати довічне зберігання даних, їх
                            накопичення, оновлення, зміну (у міру необхідності). Покупець зобов'язується забезпечити
                            захист даних від несанкціонованого доступу третіх осіб, не поширювати і не передавати дані
                            будь-якій третій стороні (крім передачі даних комерційним партнерам, особам, уповноваженим
                            Продавцем на здійснення безпосередньої обробки даних для зазначених цілей, а також на
                            обов'язковий запит компетентного державного органу). </p>
            <p className={styles.description}>8.7. У разі небажання отримувати розсилку, Покупець має право
                            звернутися до Продавця, написавши заяву про відмову від отримання рекламних матеріалів,
                            направивши її на поштову або електронну адресу.</p>
            <p className={styles.description}>8.8. У будь-яких конфліктних ситуаціях сторони зобов'язані не
                            піддаватися емоціям, а в першу чергу, зберігаючи спокій, вирішувати конфлікт.</p>
          </div>
        </div>

        {type === "create-order" &&
                    <div className={styles.modal_content_agreement}>
                      <CustomCheckbox
                        label={"Натискаючи кнопку \"Прийняти\" Ви погоджуєтесь з  з умовами конфіденційності та угодою користувача і підтверджуєте свою згоду."}
                        id={"isAgreement"}
                        isChecked={isAgreement}
                        setIsChecked={() => setIsAgreement(prev => !prev)}
                        position={"center"}
                      />

                      <MainButtonRedBackground
                        text={"Прийняти"}
                        width={"auto"}
                        onClick={() => {
                          handleCreateOrder?.();
                          handleClose();
                        }}
                        type={"button"}
                        isDisabled={!isAgreement}
                      />
                    </div>}
      </div>
    </div>
  );
};

export default ModalPrivacyPolicy;
