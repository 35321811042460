import React, {FC} from "react";

import styles from "./CustomRadioButton.module.scss";

interface ICustomRadioButtonProps {
    label: string;
    isChecked: boolean;
    setIsChecked: (value: string) => void;
}

const CustomRadioButton: FC<ICustomRadioButtonProps> = ({label, isChecked, setIsChecked}) => {
  return (
    <div
      className={styles.radioButton}
      onClick={() => setIsChecked(label)}
    >
      <div className={`${styles.radioButton_circle} ${isChecked ? styles.checked : ""}`}>
        {isChecked && <div className={styles.radioButton_circle_checked}></div>}
      </div>
      <span className={styles.radioButton_label}>{label}</span>
    </div>
  );
};

export default CustomRadioButton;
