import React, {FC, useEffect} from "react";
import {Toaster} from "react-hot-toast";
import {useNavigate} from "react-router-dom";

import chevron from "../../assets/chevron-left.svg";
import HistoryWithdrawalPoints from "../../components/HistoryWithdrawalPoints/HistoryWithdrawalPoints";
import Loader from "../../components/Loader/Loader";
import WithdrawalRequestItem from "../../components/WithdrawalRequestItem/WithdrawalRequestItem";
import {useAppSelector} from "../../hooks/reduxHooks";
import styles from "./WithdrawalRequestPage.module.scss";

interface IWithdrawalRequestPageProps {
}

const WithdrawalRequestPage: FC<IWithdrawalRequestPageProps> = () => {
  const {status} = useAppSelector(state => state.payment);

  const navigate = useNavigate();

  const handleNavigateBack = () => {
    navigate(-1);
  }

  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth"
    })
  }, []);

  if (status === "loading") {
    return <Loader/>;
  }

  return (
    <div className={styles.withdrawalRequestPage}>
      <div className={styles.withdrawalRequestPage_title}>
        <img onClick={handleNavigateBack} src={chevron} alt="chevron"/>
        <h1>ЗАЯВКА НА ВИВІД БОНУСНИХ <span>БАЛІВ</span></h1>
      </div>

      <div className={styles.withdrawalRequestPage_flex}>
        <WithdrawalRequestItem/>
        <HistoryWithdrawalPoints/>
      </div>

      <Toaster/>
    </div>
  );
};

export default WithdrawalRequestPage;
