import React, {FC, MutableRefObject, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

import cart from "../../assets/cart.svg";
import logoFull from "../../assets/full-logo.svg";
import logoShort from "../../assets/logo.svg";
import search from "../../assets/search-white.svg";
import avatar from "../../assets/user.svg";
import defaultUserPhoto from "../../assets/user-avatar.svg";
import {useAppDispatch, useAppSelector} from "../../hooks/reduxHooks";
import {MAIN_ROUTES} from "../../routes/mainRoutes";
import {cartActions} from "../../store/slices/cartSlice";
import {categoriesActions} from "../../store/slices/categoriesSlice";
import {deliveryActions} from "../../store/slices/deliverySlice";
import {orderActions} from "../../store/slices/orderSlice";
import {productsActions} from "../../store/slices/productSlice";
import {UIActions} from "../../store/slices/UISlice";
import {userActions} from "../../store/slices/userSlice";
import CartDetails from "../CartDetails/CartDetails";
import FavouriteDetails from "../FavouriteDetails/FavouriteDetails";
import HeaderNavLinks from "../HeaderNavLinks/HeaderNavLinks";
import LoginForm from "../LoginForm/LoginForm";
import SearchBar from "../SearchBar/SearchBar";
import UserDetails from "../UserDetails/UserDetails";
import styles from "./Header.module.scss";

interface IHeaderProps {
  bestsellersRef: MutableRefObject<HTMLDivElement | null>;
  newProductsRef: MutableRefObject<HTMLDivElement | null>;
  promotionalProductsRef: MutableRefObject<HTMLDivElement | null>;
}

const Header: FC<IHeaderProps> = ({bestsellersRef, newProductsRef, promotionalProductsRef}) => {
  const [isHamburgerOpen, setIsHamburgerOpen] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isSearchBarOpen, setIsSearchBarOpen] = useState<boolean>(false);
  const [deviceWidth, setDeviceWidth] = useState<number>(window.innerWidth);

  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const {productsIds} = useAppSelector(state => state.cart);
  const {user} = useAppSelector(state => state.user);

  const handleResize = () => {
    setDeviceWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleNavigate = (path: string) => {
    navigate(path);

    if (isHamburgerOpen) {
      setIsHamburgerOpen(false);
    }
  }

  const handleScrollIntoSection = (ref: MutableRefObject<HTMLDivElement | null>) => {
    navigate(MAIN_ROUTES.HOME);
    setIsHamburgerOpen(false);

    if (ref) {
      setTimeout(() => {
        ref?.current?.scrollIntoView({
          behavior: "smooth"
        });
      }, 300);
    }
  }

  useEffect(() => {
    if (deviceWidth <= 768 && isModalOpen) {
      setIsModalOpen(false);
      navigate(MAIN_ROUTES.LOGIN);
    }
  }, [deviceWidth]);

  const handleLogOut = () => {
    dispatch(userActions.reset());
    dispatch(categoriesActions.reset());
    dispatch(deliveryActions.reset());
    dispatch(orderActions.reset());
    dispatch(UIActions.reset());
    dispatch(cartActions.resetCart());
    dispatch(productsActions.reset());

    localStorage.removeItem("tokens"); //delete tokens from local storage

    handleNavigate(MAIN_ROUTES.HOME);
  }

  return (
    <>
      {/*desktop*/}
      <div className={styles.headerDesktopContainer}>
        <header className={styles.headerDesktopContainer_header}>
          <img
            style={{width: 200, height: 50}}
            onClick={() => navigate(MAIN_ROUTES.HOME)}
            src={logoFull}
            alt="life styll logo"
            draggable={false}
          />

          <SearchBar type={"desktop"}/>
          <div className={styles.headerDesktopContainer_header_details}>
            <FavouriteDetails/>
            <CartDetails/>
            <UserDetails setIsModalOpen={setIsModalOpen}/>
          </div>
        </header>
        <HeaderNavLinks
          bestsellersRef={bestsellersRef}
          newProductsRef={newProductsRef}
          promotionalProductsRef={promotionalProductsRef}
        />
      </div>
      {isModalOpen &&
        <LoginForm
          routingPath={""}
          type={"modal"}
          isModalOpen={isModalOpen}
          handleCloseModal={() => setIsModalOpen(false)}
        />}

      {/*mobile*/}
      <header className={`${styles.headerMobile} ${isHamburgerOpen ? styles.open : ""}`}>
        {isSearchBarOpen ?
          <SearchBar
            type={"mobile"}
            isSearchBarOpen={isSearchBarOpen}
            setIsSearchBarOpen={setIsSearchBarOpen}
            setIsHamburgerOpen={setIsHamburgerOpen}
          /> : <>
            <div className={styles.headerMobile_group}>
              <button
                className={`${styles.headerMobile_group_button} ${styles.hamburger} ${isHamburgerOpen ? styles.open : ""}`}
                onClick={() => setIsHamburgerOpen(prev => !prev)}
              >
                <div className={styles.bar}></div>
              </button>
              <button
                className={styles.headerMobile_group_button}
                onClick={() => setIsSearchBarOpen(true)}
              >
                <img src={search} alt="search" draggable={false}/>
              </button>
            </div>

            <img
              onClick={() => handleNavigate(MAIN_ROUTES.HOME)}
              src={logoShort}
              alt="life styll logo"
              draggable={false}
            />

            <div className={styles.headerMobile_group}>
              <button
                className={styles.headerMobile_group_button}
                onClick={() => handleNavigate(MAIN_ROUTES.CART)}
              >
                <img src={cart} alt="cart icon" draggable={false}/>
                <span className={styles.headerMobile_group_button_count}>{productsIds.length}</span>
              </button>

              <button
                className={styles.headerMobile_group_button}
                onClick={() => handleNavigate(user ? MAIN_ROUTES.USER_INFO : MAIN_ROUTES.LOGIN)}
              >
                <img
                  style={{
                    width: user ? 40 : 32,
                    height: user ? 40 : 32,
                    borderRadius: "50%"
                  }}
                  src={user ? (user?.photo || defaultUserPhoto) : avatar} alt="user`s photo"
                  draggable={false}
                />
              </button>
            </div>
          </>}
      </header>

      {user ?
        <ul className={`${styles.mobileNav} ${isHamburgerOpen ? styles.open : ""}`}>
          <li
            className={`${styles.mobileNav_link} ${styles.loggedIn}`}
            onClick={() => handleNavigate(MAIN_ROUTES.USER_INFO)}
          >ОСОБИСТА <span>ІНФОРМАЦІЯ</span>
          </li>
          <li
            className={`${styles.mobileNav_link} ${styles.loggedIn}`}
            onClick={() => handleNavigate(MAIN_ROUTES.REFERRAL_STRUCTURE)}
          >МОЯ <span>СТРУКТУРА</span>
          </li>
          <li
            className={`${styles.mobileNav_link} ${styles.loggedIn}`}
            onClick={() => handleNavigate(MAIN_ROUTES.MY_ORDERS)}
          >МОЇ <span>ЗАМОВЛЕННЯ</span>
          </li>
          {/*<li*/}
          {/*    className={`${styles.mobileNav_link} ${styles.loggedIn}`}*/}
          {/*    onClick={() => handleNavigate(MAIN_ROUTES.MY_ORDERS)}*/}
          {/*>ЗАМОВЛЕННЯ <span>ПАРТНЕРІВ</span>*/}
          {/*</li>*/}
          <li
            className={`${styles.mobileNav_link} ${styles.loggedIn}`}
            onClick={() => handleNavigate(MAIN_ROUTES.WITHDRAWAL_REQUESTS)}
          >ЗАЯВКА НА ВИВІД БОНУСНИХ <span>БАЛІВ</span>
          </li>
          {/*<li*/}
          {/*    className={`${styles.mobileNav_link} ${styles.loggedIn}`}*/}
          {/*    onClick={() => handleNavigate(MAIN_ROUTES.REPORTS)}*/}
          {/*>МОЇ <span>ЗВІТИ</span>*/}
          {/*</li>*/}
          {/*<li*/}
          {/*    className={`${styles.mobileNav_link} ${styles.loggedIn}`}*/}
          {/*    onClick={() => handleNavigate(MAIN_ROUTES.EVENTS_CALENDAR)}*/}
          {/*>КАЛЕНДАР <span>ПОДІЙ</span>*/}
          {/*</li>*/}
          <li
            className={`${styles.mobileNav_link} ${styles.loggedIn}`}
            onClick={handleLogOut}
          >ВИХІД
          </li>
        </ul> :
        <ul className={`${styles.mobileNav} ${isHamburgerOpen ? styles.open : ""}`}>
          <li
            onClick={() => handleScrollIntoSection(bestsellersRef)}
            className={styles.mobileNav_link}
          >Хіти продажів
          </li>
          <li
            onClick={() => handleScrollIntoSection(newProductsRef)}
            className={styles.mobileNav_link}
          >Новинки
          </li>
          <li
            onClick={() => handleScrollIntoSection(promotionalProductsRef)}
            className={styles.mobileNav_link}
          >Акційні товари
          </li>
          <li
            className={styles.mobileNav_link}
            onClick={() => handleNavigate(MAIN_ROUTES.ABOUT_US)}
          >Про нас
          </li>
          <li
            className={styles.mobileNav_link}
            // onClick={() => handleNavigate(MAIN_ROUTES.BLOG)}
          >Блог
          </li>
          <li
            className={styles.mobileNav_link}
            onClick={() => handleNavigate(MAIN_ROUTES.FAVOURITES)}
          >Уподобане
          </li>
        </ul>}
    </>
  );
};

export default Header;
