import Joi from "joi";

import {REGEX} from "../constants/regex";

export const contactsSupportValidator = Joi.object({
  last_name: Joi.string().required().messages({
    "string.empty": "Заповніть це поле",
  }),
  first_name: Joi.string().required().messages({
    "string.empty": "Заповніть це поле",
  }),
  email: Joi.string().required().pattern(REGEX.EMAIL).messages({
    "string.empty": "Заповніть це поле",
    "string.pattern.base": "Невалідний email",
  }),
  message: Joi.string().required().messages({
    "string.empty": "Заповніть це поле",
  }),
});

