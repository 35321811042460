import React, {FC} from "react";

import styles from "./Pagination.module.scss";

interface IPaginationProps {
  currentPage: number;
  onPageChange: (value: number) => void;
  totalItems: number;
  position: "center" | "flex-start";
}

const Pagination: FC<IPaginationProps> = ({currentPage, onPageChange, totalItems, position}) => {
  const itemsPerPage = 12;

  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const pages = Array.from({length: totalPages}, (_, i) => i + 1);

  return (
    <div className={styles.wrapper}>
      <div style={{justifyContent: position}} className={styles.pagination_desktop}>
        <button
          className={`${styles.page} ${currentPage === 1 ? styles.disabled : ""}`}
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Попередня
        </button>

        {pages.map((page: number) => (
          <button
            key={page}
            className={`${styles.page} ${currentPage === page ? styles.active : ""}`}
            onClick={() => page !== currentPage && onPageChange(page)}
          >
            {page}
          </button>
        ))}

        <button
          className={`${styles.page} ${currentPage === totalPages ? styles.disabled : ""}`}
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Наступна
        </button>
      </div>

      <div className={styles.pagination_mobile}>
        <button
          className={`${styles.page} ${currentPage === 1 ? styles.disabled : ""}`}
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Попередня
        </button>

        <button className={`${styles.page} ${styles.active}`}>
          {currentPage}
        </button>

        <button
          className={`${styles.page} ${currentPage === totalPages ? styles.disabled : ""}`}
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Наступна
        </button>
      </div>
    </div>
  );
};

export default Pagination;
