import {Zoom} from "@mui/material";
import React, {forwardRef, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

import search from "../../assets/search-dark.svg";
import {useAppDispatch, useAppSelector} from "../../hooks/reduxHooks";
import {MAIN_ROUTES} from "../../routes/mainRoutes";
import {productsActions} from "../../store/slices/productSlice";
import SearchResultsList from "../SearchResultsList/SearchResultsList";
import styles from "./SearchBar.module.scss";

interface ISearchBarProps {
  type: "mobile" | "desktop";
  setIsSearchBarOpen?: (value: boolean) => void; //for mobile version
  isSearchBarOpen?: boolean; //for mobile version
  setIsHamburgerOpen?: (value: boolean) => void; //for mobile version
}

const SearchBar = forwardRef<HTMLDivElement, ISearchBarProps>(({
  type,
  setIsSearchBarOpen,
  isSearchBarOpen,
  setIsHamburgerOpen
}, ref) => {
  const [hasSearched, setHasSearched] = useState<boolean>(false);
  const [isCrossOpen, setIsCrossOpen] = useState<boolean>(false);

  const getFilteredProductsBySearchName = async (search: string) => {
    dispatch(productsActions.getFiltered({search, ordering: filteringValues.ordering.ordering, page: 1}));
  };

  const dispatch = useAppDispatch();
  const {filteringValues, searchValue} = useAppSelector(state => state.products);

  const navigate = useNavigate();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref !== null) {
        if (ref && "current" in ref && ref.current && !ref.current.contains(event.target as Node)) {
          dispatch(productsActions.clearFilteredProducts());
          setHasSearched(false);
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);


  const handleInputChange = async (value: string) => {
    dispatch(productsActions.setSearchValue(value));
    await getFilteredProductsBySearchName(value);

    if (value !== "") {
      setIsCrossOpen(true);
      setHasSearched(true);
    } else {
      setHasSearched(false);
      setIsCrossOpen(false);
    }
  };

  const handleEnterKeyPress = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      await getFilteredProductsBySearchName(searchValue);
      setIsHamburgerOpen?.(true); // close hamburger menu for mobile version
      navigate(`${MAIN_ROUTES.SEARCH_RESULTS}?search=${searchValue}`);
      dispatch(productsActions.setSearchValue(""));
      // setHasSearched(false);
    }
  };

  const handleClear = () => {
    dispatch(productsActions.setSearchValue(""));
    dispatch(productsActions.clearFilteredProducts());
    setHasSearched(false);
    setIsCrossOpen(false);

    if (type === "mobile") {
      setIsSearchBarOpen?.(false);
    }
  }

  return (
    isSearchBarOpen ?
      <Zoom in={isSearchBarOpen} style={{transitionDelay: isSearchBarOpen ? "200ms" : "0ms"}}>
        <div className={`${styles.searchBar} ${styles[type]}`} ref={ref}>
          <div className={`${styles.searchBar_search} ${styles[type]}`}>
            <img src={search} alt="search" draggable={false}/>
            <input
              className={styles.searchBar_search_input}
              type="text"
              placeholder={"Пошук"}
              value={searchValue}
              onChange={(event) => handleInputChange(event.target.value)}
              onKeyDown={handleEnterKeyPress}
            />
            {(isCrossOpen || type === "mobile") &&
              <button className={styles[type]} onClick={handleClear}></button>}
          </div>

          {searchValue &&
            <SearchResultsList
              hasSearched={hasSearched}
              setHasSearched={setHasSearched}
              setIsHamburgerOpen={setIsHamburgerOpen}
            />}
        </div>
      </Zoom> :
      <div className={`${styles.searchBar} ${styles[type]}`} ref={ref}>
        <div className={`${styles.searchBar_search} ${styles[type]}`}>
          <img src={search} alt="search icon" draggable={false}/>
          <input
            className={styles.searchBar_search_input}
            type="text"
            placeholder={"Пошук"}
            value={searchValue}
            onChange={(event) => handleInputChange(event.target.value)}
            onKeyDown={handleEnterKeyPress}
          />
          {(isCrossOpen || type === "mobile") &&
            <button className={styles[type]} onClick={handleClear}></button>}
        </div>

        {searchValue &&
          <SearchResultsList
            hasSearched={hasSearched}
            setHasSearched={setHasSearched}
            setIsHamburgerOpen={setIsHamburgerOpen}
          />}
      </div>
  );
});

export default SearchBar;



