import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {AxiosError} from "axios";

import {imagesService} from "../../services/images.service";

interface IState {
  bannerImages: string[];
  status: "loading" | "success";
}

const initialState: IState = {
  bannerImages: [],
  status: "success"
}

const getBannerImages = createAsyncThunk<string[], void>("imagesSlice/getBannerImages",
  async (_, {rejectWithValue}) => {
    try {
      const {data} = await imagesService.getBannerImages();

      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  });

const imagesSlice = createSlice({
  name: "imagesSlice",
  initialState,
  reducers: {},
  extraReducers: builder => builder
    .addCase(getBannerImages.fulfilled, (state, action) => {
      state.bannerImages = action.payload;
      state.status = "success";
    })
    .addCase(getBannerImages.pending, (state, action) => {
      state.status = "loading";
    })
});

const {reducer: imagesReducer, actions} = imagesSlice;

const imagesActions = {
  ...actions,
  getBannerImages
}

export {
  imagesActions,
  imagesReducer,
}
