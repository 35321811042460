import {endpoints} from "../configs/endpoints";
import {instance, IRes} from "./axios.service";

export const imagesService = {
  getBannerImages: (): IRes<string[]> => {
    const res = instance.get(endpoints.images.base);

    return res;
  }
}
