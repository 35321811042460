import "react-image-gallery/styles/scss/image-gallery.scss";
import "../../utils/imageGallery.scss";

import React, {FC, useState} from "react";
import toast from "react-hot-toast";

import {calculateDiscountedPrice} from "../../helpers/calculateDiscountedPrice";
import {useAppDispatch, useAppSelector} from "../../hooks/reduxHooks";
import {IProductDetailed} from "../../interfaces/IProductDetailed";
import {cartActions} from "../../store/slices/cartSlice";
import CustomModal from "../CustomModal/CustomModal";
import DeliveryMethods from "../DeliveryMethods/DeliveryMethods";
// import ProductsPhotoSwiper from "../ProductsPhotoSwiper/ProductsPhotoSwiper";
import MainButtonRedBackground from "../MainButtonRedBackground/MainButtonRedBackground";
import MainButtonTransparentBackground from "../MainButtonTransparentBackground/MainButtonTransparentBackground";
import ModalAddedToFavourite from "../ModalAddedToFavourite/ModalAddedToFavourite";
import ProductPhotoZoom from "../ProductPhotoZoom/ProductPhotoZoom";
import styles from "./ProductDetailedInfo.module.scss";

interface IProductDetailedInfoProps {
  product: IProductDetailed
}

const ProductDetailedInfo: FC<IProductDetailedInfoProps> = ({product}) => {
  const [isPreviewOpen, setIsPreviewOpen] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const {
    pk,
    name,
    price,
    points_to_count,
    images,
    discount_percent,
    can_pay_with_bonus,
    bonus_price,
  } = product;

  const dispatch = useAppDispatch();
  const {user} = useAppSelector(state => state.user);

  const handleAddProductToCart = () => {
    dispatch(cartActions.addProductId(pk));

    toast.success(`Продукт "${name}" успішно додано до корзини!`, {
      id: "cart"
    });
  }

  // const imagesGallery = images.map((url) => ({
  //     original: url,
  //     thumbnail: url,
  // }));

  return (
    <div className={styles.productInfo}>
      <div className={styles.productInfo_photo}>
        {/*<ImageGallery*/}
        {/*    items={imagesGallery}*/}
        {/*    thumbnailPosition={"bottom"}*/}
        {/*    showThumbnails={true}*/}
        {/*    showFullscreenButton={true}*/}
        {/*    showNav={true}*/}
        {/*    showPlayButton={false}*/}
        {/*    showBullets={true}*/}
        {/*/>*/}
        <img onClick={() => setIsPreviewOpen(true)} src={images[0]} alt="product" draggable={false}/>

        {/*<ProductsPhotoSwiper/>*/}

        <CustomModal
          isOpen={isPreviewOpen}
          onClose={() => setIsPreviewOpen(false)}
          component={() =>
            <ProductPhotoZoom
              photoUrl={images[0]}
              name={name}
              handleClose={() => setIsPreviewOpen(false)}
            />}
        />
      </div>

      <div className={styles.productInfo_description}>
        <div className={styles.productInfo_description_basic}>
          {/*<div className={styles.productInfo_description_basic_rating}>*/}
          {/*  <Rating style={{fontSize: 16}} name="read-only" value={5} readOnly/>*/}
          {/*  <span className={styles.productInfo_description_basic_rating_count}>(16)</span>*/}
          {/*</div>*/}

          <h3 className={styles.productInfo_description_basic_name}>{name}</h3>

          <p className={styles.productInfo_description_basic_code}>Код товару: <span>{pk}</span></p>

          {can_pay_with_bonus ?
            <div className={styles.productInfo_description_basic_price}>
              <div className={styles.productInfo_description_basic_price_content}>
                <p
                  className={styles.productInfo_description_basic_price_content_regular}>{Number(bonus_price).toFixed(2)} ББ</p>
              </div>
            </div>
            : <>
              {(discount_percent && user) ?
                <div className={styles.productInfo_description_basic_price}>
                  <div className={styles.productInfo_description_basic_price_content}>
                    <p
                      className={styles.productInfo_description_basic_price_content_new}>{+calculateDiscountedPrice(Number(price), Number(discount_percent))} ₴</p>
                    <p
                      className={styles.productInfo_description_basic_price_content_old}>{Number(price).toFixed(2)} ₴</p>
                  </div>
                  <span
                    className={styles.productInfo_description_basic_price_points}>+ {Number(points_to_count).toFixed(2)} балів</span>
                </div> :
                <div className={styles.productInfo_description_basic_price}>
                  <div className={styles.productInfo_description_basic_price_content}>
                    <p
                      className={styles.productInfo_description_basic_price_content_regular}>{Number(price).toFixed(2)} ₴</p>
                  </div>

                  <span
                    className={styles.productInfo_description_basic_price_points}>+ {Number(points_to_count).toFixed(2)} балів</span>
                </div>}
            </>}
        </div>

        <div className={styles.productInfo_description_buttons}>
          <MainButtonRedBackground
            text={"Додати в Кошик"}
            width={"100%"}
            onClick={handleAddProductToCart}
            type={"button"}
            isDisabled={false}
          />
          <MainButtonTransparentBackground
            text={"До Списку Вподобаних"}
            width={"100%"}
            onClick={() => setIsModalOpen(true)}
            type={"button"}
          />
        </div>

        <CustomModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          component={ModalAddedToFavourite}
        />

        <DeliveryMethods hasBonusPrice={!!bonus_price}/>
      </div>
    </div>
  );
};

export default ProductDetailedInfo;
