import React, {FC, useEffect} from "react";

import ourStoryCircular from "../../assets/our-story-circular.svg";
import CommunityPhotos from "../../components/CommunityPhotos/CommunityPhotos";
import CompanyValuesItem from "../../components/CompanyValuesItem/CompanyValuesItem";
import OurStory from "../../components/OurStory/OurStory";
import TextSpinnerCircle from "../../components/TextSpinnerCircle/TextSpinnerCircle";
import WhoAreWe from "../../components/WhoAreWe/WhoAreWe";
import styles from "./AboutUsPage.module.scss";

interface IAboutUsPageProps {
}

const AboutUsPage: FC<IAboutUsPageProps> = () => {
  useEffect(() => {
    // scroll to the top
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.aboutUsPage}>
        <div className={styles.aboutUsPage_titles}>
          <div className={styles.aboutUsPage_titles_main}>
            <h1>МИ НЕ ПРОСТО <span>ПРОДУКТ</span> МИ ТВОЄ ДЖЕРЕЛО НАТХНЕННЯ.</h1>
            <p>MADE IN UKRAINE</p>
          </div>

          <div className={styles.aboutUsPage_titles_minor}>
            <h2>НАША ФІЛОСОФІЯ <span>ЦЕ</span> ГАРМОНІЯ</h2>
            <p>ПРИЄДНАТИСЬ +</p>
          </div>

          <div className={styles.aboutUsPage_titles_circular}>
            <TextSpinnerCircle imageUrl={ourStoryCircular}/>
          </div>
        </div>

        <WhoAreWe/>
        <div className={styles.aboutUsPage_block}>
          <h2>НАША ІСТОРІЯ</h2>
          <OurStory/>
        </div>

        <div className={`${styles.aboutUsPage_block} ${styles.fullWidth}`}>
          <div>
            <h2>СПІЛЬНОТА</h2>
            <p className={styles.text}>
              В LIFE STYLLE ми віримо в силу спільноти і важливість того, щоб подорож красою була спільною
              пригодою. Наша спільнота - це не просто об'єднання ентузіастів краси, але і місце, де
              індивідуальності стають невичерпним джерелом натхнення.
              Тут ми ділимося секретами краси, обмінюємося досвідом та підтримуємо один одного на шляху до
              самовираження. Наша спільнота - це місце, де кожен може знайти відгук, пораду чи навіть
              нового друга, який поділяє ваші цінності.
            </p>
          </div>

          <CommunityPhotos/>
        </div>

        <div className={styles.aboutUsPage_block}>
          <h2>ФІЛОСОФІЯ КОМПАНІЇ</h2>

          <div className={styles.aboutUsPage_block_companyValues}>
            <CompanyValuesItem
              title={"Індивідуальність"}
              description={"У світі, де кожен має свій власний шлях історії, ми віримо, що справжня краса народжується з індивідуальності."}
            />
            <CompanyValuesItem
              title={"Гармонія"}
              description={"Ми пропонуємо продукти, які допомагають знаходити баланс та гармонію між зовнішнім виглядом та внутрішнім самопочуттям."}
            />
            <CompanyValuesItem
              title={"Інновація"}
              description={"Інновації - це ключ до постійного розвитку. У Lifestyll ми приносимо  новаторські продукти, які змінюють уявлення про красу."}
            />
          </div>
        </div>

        {/*<div className={styles.aboutUsPage_block}>*/}
        {/*  <h2>ПОДІЇ</h2>*/}
        {/*  <EventsCalendarItem/>*/}
        {/*  <EventsCalendarItem/>*/}
        {/*</div>*/}
      </div>
    </div>
  );
};

export default AboutUsPage;
