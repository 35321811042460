// This the most popular cities selected by users,
// Usage - for adding new delivery address

export const popularCities = [
  "Київ (Київська обл.)",
  "Одеса",
  "Дніпро",
  "Харків",
  "Львів (Львівська обл.)",
  "Запоріжжя (Запорізька обл., Запорізький р-н.)",
  "Кривий Ріг (Дніпропетровська обл.)",
  "Миколаїв (Миколаївська обл.)",
  "Вінниця",
  "Полтава (Полтавська обл.)",
  "Черкаси (Черкаська обл.)",
  "Хмельницький (Хмельницька обл.)",
  "Чернівці (Чернівецька обл.)",
  "Чернігів",
  "Житомир",
  "Івано-Франківськ",
  "Кропивницький",
  "Рівне (Рівненська обл.)",
  "Тернопіль",
  "Кременчук",
]
