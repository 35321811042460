import React, {FC} from "react";
import {useLocation} from "react-router-dom";

import LoginForm from "../../components/LoginForm/LoginForm";
import styles from "./LoginPage.module.scss";

interface ILoginPageProps {
}

const LoginPage: FC<ILoginPageProps> = () => {
  const {state} = useLocation();

  return (
    <div className={styles.loginPage}>
      <h1 className={styles.loginPage_title}>ВХІД ДО <span>ОСОБИСТОГО КАБІНЕТУ</span></h1>

      <LoginForm type={"page"} routingPath={state?.routingPath}/>
    </div>
  );
};

export default LoginPage;
