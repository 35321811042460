import React, {FC, useEffect} from "react";
import {Outlet, useLocation, useNavigate} from "react-router-dom";

import Loader from "../../components/Loader/Loader";
import Sidebar from "../../components/Sidebar/Sidebar";
import IsAuth from "../../HOC/IsAuth";
import {useAppDispatch, useAppSelector} from "../../hooks/reduxHooks";
import {MAIN_ROUTES} from "../../routes/mainRoutes";
import {deliveryActions} from "../../store/slices/deliverySlice";
import {orderActions} from "../../store/slices/orderSlice";
import {paymentActions} from "../../store/slices/paymentSlice";
import {userActions} from "../../store/slices/userSlice";
import styles from "./MyAccountLayout.module.scss";

interface IMyAccountLayoutProps {
}

const MyAccountLayout: FC<IMyAccountLayoutProps> = () => {
  const {state} = useLocation();

  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const {user, status: statusUser, chainStructure, currentLevelDepth} = useAppSelector(state => state.user);
  const {deliveryAddresses} = useAppSelector(state => state.delivery);
  const {status: statusPayment} = useAppSelector(state => state.payment);

  const getUserById = () => {
    if (state) {
      const {user_id} = state;
      dispatch(userActions.getById({user_id}));
    } else {
      dispatch(userActions.getById({user_id: user!.uuid}));
    }
  };

  const getDeliveryAddressesByCurrentUser = () => {
    dispatch(deliveryActions.getAllByCurrentUser());
  };

  const getChainStructureByCurrentUser = () => {
    dispatch(userActions.getChainByCurrentUser());
  }

  const getPaymentInfoByCurrentUser = () => {
    dispatch(paymentActions.getByCurrentUser());
  }

  const getChildrenByCurrentUserTreeView = () => {
    dispatch(userActions.setCurrentLevelDepth("1"));
    dispatch(userActions.getChildrenByCurrentUserTreeView({level: "1"}));
  }

  const getOrdersByCurrentUser = () => {
    dispatch(orderActions.getByCurrentUser({page: 1}));
  }

  useEffect(() => {
    getUserById();
    getDeliveryAddressesByCurrentUser();
    getChainStructureByCurrentUser();
    getChildrenByCurrentUserTreeView();
    getOrdersByCurrentUser();
    getPaymentInfoByCurrentUser();

    // TODO
    if (!deliveryAddresses) {
      navigate(MAIN_ROUTES.LOGIN);
    }

    // TODO
    if (!statusPayment) {
      navigate(MAIN_ROUTES.LOGIN);
    }

    return () => {
      dispatch(userActions.setCurrentLevelDepth("1"));

      // reset user referral structure
      dispatch(userActions.setCurrentUser(null));
      dispatch(userActions.setCurrentLevel(0));
      dispatch(userActions.initializeBreadcrumbs([]));
      dispatch(userActions.resetUserChainDetails());
    }
  }, []);

  if (statusUser === "loading" || statusPayment === "loading" || !user || !chainStructure) {
    return <Loader/>;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapper_myAccountLayout}>
        <Sidebar/>
        <Outlet/>
      </div>
    </div>
  );
};

export default IsAuth(MyAccountLayout);
