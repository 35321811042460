import React, {FC} from "react";

import community1 from "../../assets/community-1.svg";
import community2 from "../../assets/community-2.svg";
import community4 from "../../assets/community-4.jpg";
import community3 from "../../assets/community-4.svg";
import community5 from "../../assets/community-5.svg";
import community6 from "../../assets/community-6.svg";
import styles from "./CommunityPhotos.module.scss";

interface ICommunityPhotosProps {
}

const CommunityPhotos: FC<ICommunityPhotosProps> = () => {
  return (
    <>
      <div className={styles.photos_desktop}>
        <div className={styles.photos_desktop_block}>
          <img src={community1} alt="community" draggable={false}/>
          <img src={community2} alt="community" draggable={false}/>
        </div>
        <img style={{width: 324, height: 464}} src={community4} alt="community" draggable={false}/>
        <img src={community3} alt="community" draggable={false}/>
        <div className={styles.photos_desktop_block}>
          <img src={community5} alt="community" draggable={false}/>
          <img src={community6} alt="community" draggable={false}/>
        </div>
      </div>

      <div className={styles.photos_mobile}>
        <div className={styles.photos_mobile_block}>
          <img src={community1} alt="community" draggable={false}/>
          <img src={community2} alt="community" draggable={false}/>
        </div>
        <div className={styles.photos_mobile_block}>
          <img src={community5} alt="community" draggable={false}/>
          <img src={community6} alt="community" draggable={false}/>
        </div>
      </div>
    </>
  );
};

export default CommunityPhotos;
