import React, {FC} from "react";
import {useLocation, useNavigate} from "react-router-dom";

import home from "../../assets/home-breadcrumbs.svg";
import {breadcrumbsTranslationUkr} from "../../constants/breadcrumbsTranslationUkr";
import {useAppDispatch, useAppSelector} from "../../hooks/reduxHooks";
import {MAIN_ROUTES} from "../../routes/mainRoutes";
import {categoriesActions} from "../../store/slices/categoriesSlice";
import {productsActions} from "../../store/slices/productSlice";
import {userActions} from "../../store/slices/userSlice";
import styles from "./Breadcrumbs.module.scss"

interface IBreadcrumbsProps {
  type?: "chain-structure" | "categories";
}

const Breadcrumbs: FC<IBreadcrumbsProps> = ({type}) => {
  const {pathname} = useLocation();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const {breadcrumbs: breadcrumbsUserChain, usersChainDetails} = useAppSelector(state => state.user);
  const {breadcrumbs: breadcrumbsCategories} = useAppSelector(state => state.categories);
  const {filteringValues} = useAppSelector(state => state.products);

  const handleNavigate = (path: string) => {
    navigate(path);
  }

  const handleRedirectToCurrentLevelChain = (index: number) => {
    const newChainBreadcrumbs = breadcrumbsUserChain.slice(0, index + 1); //we leave a new array including that element
    const newUserChainDetails = usersChainDetails.slice(0, index + 1); //we leave a new array including that element
    const currentUser = usersChainDetails[index];

    dispatch(userActions.initializeBreadcrumbs(newChainBreadcrumbs));
    dispatch(userActions.initializeUserChainDetails(newUserChainDetails));
    dispatch(userActions.setCurrentUser(currentUser));
    dispatch(userActions.setCurrentLevel(index + 1));
  }

  const handleResetUserChainStructure = () => {
    dispatch(userActions.setCurrentUser(null));
    dispatch(userActions.setCurrentLevel(0));
    dispatch(userActions.initializeBreadcrumbs([]));
    dispatch(userActions.resetUserChainDetails());
  }

  const handleRedirectToCategories = (index: number) => {
    const newBreadcrumbs = breadcrumbsCategories.slice(0, index + 1); //we leave a new array including that element
    dispatch(categoriesActions.initializeBreadcrumbs(newBreadcrumbs));
    dispatch(productsActions.getFiltered({
      search: newBreadcrumbs[0],
      page: 1,
      ordering: filteringValues.ordering.ordering
    }));
  }

  return (
    <div className={styles.breadcrumbs}>
      <img
        draggable={false}
        style={{cursor: "pointer"}}
        src={home}
        alt="home"
        onClick={() => handleNavigate(MAIN_ROUTES.HOME)}
      />
      {type !== "categories" && <span className={styles.breadcrumbs_divider}>/</span>}

      <span
        className={styles.breadcrumbs_path}
        onClick={() => breadcrumbsTranslationUkr[pathname] === "Моя структура" && handleResetUserChainStructure()}
        style={{cursor: breadcrumbsTranslationUkr[pathname] === "Моя структура" ? "pointer" : "auto"}}
      >{breadcrumbsTranslationUkr[pathname]}</span>

      {/* categories -> category page */}
      {type === "categories" &&
        <>
          {breadcrumbsCategories.map((path, index) =>
            <div style={{display: "flex", flexWrap: "nowrap"}} key={index}>
              <span className={styles.breadcrumbs_divider}>/</span>
              <span
                key={index}
                className={styles.breadcrumbs_path}
                style={{cursor: "pointer"}}
                onClick={() => handleRedirectToCategories(index)}
              >{path}</span>
            </div>)}
        </>}

      {/* referral structure -> user profile page */}
      {type === "chain-structure" &&
        <>
          {breadcrumbsUserChain.map((path, index) =>
            <div style={{display: "flex", flexWrap: "nowrap"}} key={index}>
              <span className={styles.breadcrumbs_divider}>/</span>
              <span
                key={index}
                className={styles.breadcrumbs_path}
                style={{cursor: "pointer"}}
                onClick={() => handleRedirectToCurrentLevelChain(index)}
              >{path}</span>
            </div>)}
        </>}
    </div>
  );
};

export default Breadcrumbs;
