import React, {FC, useEffect} from "react";

import styles from "./ConfirmEmailPage.module.scss";

interface IConfirmEmailPageProps {
}

const ConfirmEmailPage: FC<IConfirmEmailPageProps> = () => {
  useEffect(() => {
    // scroll to the top
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapper_confirmEmailPage}>
        <h1 className={styles.wrapper_confirmEmailPage_title}>ПРИЄДНУЙТЕСЬ У СВІТ <span>КРАСИ</span></h1>

        <div className={styles.wrapper_confirmEmailPage_content}>
          <div className={styles.wrapper_confirmEmailPage_content_rectangle}></div>
          <div className={styles.wrapper_confirmEmailPage_content_block}>
            <h2>Підтвердіть Ваш Email</h2>

            <p>Перейдіть за вказаною Вами поштою та підтвердіть Ваш Email, ми надіслали Вам лист</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmEmailPage;
