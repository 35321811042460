import {Backdrop, Box, Fade, Modal} from "@mui/material";
import React, {ElementType, FC} from "react";

interface ICustomModalProps {
    isOpen: boolean;
    onClose: () => void;
    component: ElementType;
}

const CustomModal: FC<ICustomModalProps> = ({isOpen, onClose, component: ModalComponent}) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 4
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      style={{borderRadius: 16}}
      open={isOpen}
      onClose={onClose}
      closeAfterTransition
      slots={{backdrop: Backdrop}}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={isOpen}>
        <Box sx={style}>
          <ModalComponent handleClose={onClose}/>
        </Box>
      </Fade>
    </Modal>

  );
};

export default CustomModal;
