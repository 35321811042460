import React, {FC, useEffect} from "react";
import {useNavigate} from "react-router-dom";

import chevron from "../../assets/chevron-left.svg";
import EventsCalendarItem from "../../components/EventsCalendarItem/EventsCalendarItem";
import styles from "./EventsCalendarPage.module.scss";

interface IEventsCalendarPageProps {
}

const EventsCalendarPage: FC<IEventsCalendarPageProps> = () => {
  const navigate = useNavigate();

  const handleNavigateBack = () => {
    navigate(-1);
  }

  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth"
    });
  }, []);

  return (
    <div>
      <div className={styles.eventsPage}>
        <div className={styles.eventsPage_title}>
          <img onClick={handleNavigateBack} src={chevron} alt="chevron"/>
          <h1>КАЛЕНДАР <span>ПОДІЙ</span></h1>
        </div>

        <div className={styles.eventsPage_events}>
          <EventsCalendarItem/>
          <EventsCalendarItem/>
          <EventsCalendarItem/>
        </div>
      </div>
    </div>
  );
};

export default EventsCalendarPage;
