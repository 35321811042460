import React, {forwardRef} from "react";

import hitText from "../../assets/hit-text.svg";
import innovations from "../../assets/new-products.svg";
import {IProduct} from "../../interfaces/IProduct";
import MainButtonTransparentBackground from "../MainButtonTransparentBackground/MainButtonTransparentBackground";
import ProductCard from "../ProductCard/ProductCard";
import ProductsBlockContainer from "../ProductsBlockContainer/ProductsBlockContainer";
import TextSpinnerCircle from "../TextSpinnerCircle/TextSpinnerCircle";
import styles from "./NewProducts.module.scss";

interface INewProductsProps {
  products: IProduct[],
}

const NewProducts = forwardRef<HTMLTableSectionElement, INewProductsProps>(({products}, ref) => {
  const newProducts = products.slice(-6);

  return (
    <>
      {/* desktop */}
      <section ref={ref} className={styles.newProducts_desktop}>
        <h2 className={styles.newProducts_desktop_title}>Новинки</h2>

        <div className={styles.newProducts_desktop_content}>
          <div className={styles.newProducts_desktop_content_products}>
            {newProducts.slice(0, 4).map((product, index) =>
              <ProductCard
                width={"300px"}
                product={product}
                key={index}
                type={+product.discount_percent ? "sale" : "usual"}
                isFavourite={false}
              />
            )}
            <div className={styles.newProducts_desktop_content_products_six}>
              {newProducts.map((product, index) =>
                <ProductCard
                  width={"300px"}
                  product={product}
                  key={index}
                  type={product.is_promo ? "promo" : (+product.discount_percent ? "sale" : "usual")}
                  isFavourite={false}
                />
              )}
            </div>
          </div>

          <div className={styles.newProducts_desktop_content_innovations}>
            <img className={styles.newProducts_desktop_content_innovations_icon} src={innovations}
              alt="innovations vibe" draggable={false}/>

            <div className={styles.newProducts_desktop_content_innovations_description}>
              <div className={styles.newProducts_desktop_content_innovations_description_text}>
                <p>У цьому святковому сезоні ми пропонуємо вам не тільки вибір продуктів високої якості,
                  але
                  і
                  неперевершений дизайн, який прикрасить ваш святковий дім. Знаходьте ідеальні
                  подарунки
                  для
                  своїх
                  близьких та друзів у нашому каталозі. Від ексклюзивних наборів краси до вишуканих
                  предметів
                  для
                  дому.</p>
                <div style={{width: 150, height: 150}}>
                  <TextSpinnerCircle imageUrl={hitText}/>
                </div>
              </div>
              <MainButtonTransparentBackground
                text={"Переглянути Каталог"}
                width={"auto"}
                onClick={null}
                type={"button"}
              /></div>
          </div>
        </div>
      </section>

      {/* mobile */}
      <section className={styles.newProducts_mobile}>
        <div>
          <ProductsBlockContainer products={products} title={"Новинки"}/>
        </div>

        <div className={styles.newProducts_mobile_spinner}>
          <TextSpinnerCircle imageUrl={hitText}/>
        </div>

        <div className={styles.newProducts_mobile_innovations}>
          <img className={styles.newProducts_mobile_innovations_icon} src={innovations}
            alt="innovations vibe" draggable={false}/>
          <p>
            У цьому святковому сезоні ми пропонуємо вам не тільки вибір продуктів високої якості,
            але
            і
            неперевершений дизайн, який прикрасить ваш святковий дім. Знаходьте ідеальні
            подарунки
            для
            своїх
            близьких та друзів у нашому каталозі. Від ексклюзивних наборів краси до вишуканих
            предметів
            для
            дому.
          </p>

          <MainButtonTransparentBackground
            text={"Переглянути Каталог"}
            width={"auto"}
            onClick={null}
            type={"button"}
          /></div>
      </section>
    </>
  );
});

export default NewProducts;
