import React, {FC} from "react";

import close from "../../assets/close-white.svg";
import styles from "./ProductPhotoZoom.module.scss";

interface IProductPhotoZoomProps {
  photoUrl: string;
  name: string;
  handleClose: () => void;
}

const ProductPhotoZoom: FC<IProductPhotoZoomProps> = ({photoUrl, name, handleClose}) => {
  return (
    <div
      className={styles.backgroundOverlay}
      onClick={handleClose}
    >
      <span
        className={styles.backgroundOverlay_close}
        onClick={handleClose}
      >×</span>

      <div className={styles.backgroundOverlay_product}>
        <h2 className={styles.backgroundOverlay_product_name}>{name}</h2>
        <div className={styles.backgroundOverlay_product_photo}>
          <img
            src={photoUrl}
            alt={name}
            draggable={false}
          />
        </div>
      </div>
    </div>
  );
};

export default ProductPhotoZoom;
