import React, {FC} from "react";
import {useNavigate} from "react-router-dom";

import {MAIN_ROUTES} from "../../routes/mainRoutes";
import styles from "./FrameSaleForUnauthUser.module.scss";

interface IFrameSaleForUnauthUserProps {
}

const FrameSaleForUnauthUser: FC<IFrameSaleForUnauthUserProps> = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <p className={styles.container_condition}>
        <span
          onClick={() => navigate(MAIN_ROUTES.LOGIN, {state: {routingPath: MAIN_ROUTES.CART}})}
        > Увійдіть </span>
        або
        <span
          onClick={() => navigate(MAIN_ROUTES.REGISTRATION)}
        > Зареєструйтесь, </span>
        щоб отримати знижку на замовлення.
      </p>
    </div>
  );
};

export default FrameSaleForUnauthUser;
