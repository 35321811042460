import React, {FC} from "react";

import styles from "./ProgressBarLinear.module.scss";

interface IProgressBarLinearProps {
  label: string;
  currentPoints: number;
  pointsForTheNextLevel: number;
}

const ProgressBarLinear: FC<IProgressBarLinearProps> = ({label, pointsForTheNextLevel, currentPoints}) => {
  // if currentPoints = 0 and pointsForTheNextLevel = 0,
  // then this sets the progress bar to 100%
  // otherwise, it sets to the specified values
  const isProgressBarFull = pointsForTheNextLevel === 0;

  return (
    <div className={styles.progressBar}>
      <div className={styles.progressBar_label}>
        <p className={styles.progressBar_label_name}>{label}</p>
        <p className={styles.progressBar_label_count}>{currentPoints} / {pointsForTheNextLevel}</p>
      </div>

      <progress
        max={isProgressBarFull ? 1 : pointsForTheNextLevel}
        value={isProgressBarFull ? 1 : currentPoints}
      ></progress>
    </div>
  );
};

export default ProgressBarLinear;
