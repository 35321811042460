import React, {FC} from "react";
import {useNavigate} from "react-router-dom";

import {useAppDispatch} from "../../hooks/reduxHooks";
import {ICategory} from "../../interfaces/ICategory";
import {MAIN_ROUTES} from "../../routes/mainRoutes";
import {categoriesActions} from "../../store/slices/categoriesSlice";
import styles from "./CategoryBadge.module.scss";

interface ICategoryBadgeProps {
  category: ICategory;
}

const CategoryBadge: FC<ICategoryBadgeProps> = ({category}) => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const handleNavigate = (path: string) => {
    dispatch(categoriesActions.initializeBreadcrumbs([category.name]));
    navigate(path);
  }

  const {name, id} = category;

  return (
    <div
      className={styles.badge}
      onClick={() => handleNavigate(`${MAIN_ROUTES.CATEGORIES}/${id}`)}
    >{name}</div>
  );
};

export default CategoryBadge;
