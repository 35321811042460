import "react-circular-progressbar/dist/styles.css";

import React, {FC} from "react";

import styles from "./AppCircularProgressBar.module.scss";

interface ICircularProgressBarProps {
    currentPoints: number;
    pointsForTheNextLevel: number;
    innerText: JSX.Element;
}

const CircularProgressBar: FC<ICircularProgressBarProps> = ({currentPoints, pointsForTheNextLevel, innerText}) => {
  const radius = 74;
  const circumference = 2 * Math.PI * radius;
  let percentage = (currentPoints / pointsForTheNextLevel) * 100;
  if (percentage > 100) {
    percentage = 100; // limit the percentage to 100 if currentPoints exceeds pointsForTheNextLevel
  }
  const strokeDashoffset = circumference - ((percentage / 100) * circumference);

  return (
    <div className={styles.progressBar}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="150"
        height="150"
        viewBox="0 0 158 158" fill="none"
        style={{transform: "rotate(-90deg)"}}
      >
        <circle
          cx="79"
          cy="79"
          r={radius}
          stroke="white"
          strokeWidth="10"
        />
        <circle
          cx="79"
          cy="79"
          r={radius}
          stroke="#239B85"
          strokeWidth="10"
          strokeDasharray={circumference}
          strokeDashoffset={strokeDashoffset}
          strokeLinecap="round"
        />
      </svg>
      <div className={styles.progressBar_text}>
        {innerText}
      </div>
    </div>
  );
};

export default CircularProgressBar;
