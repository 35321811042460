import React, {FC, useEffect} from "react";

import SuccessStoryItem from "../../components/SuccessStoryItem/SuccessStoryItem";
import styles from "./SuccessStoriesPage.module.scss";

interface ISuccessStoryPageProps {
}

const SuccessStoriesPage: FC<ISuccessStoryPageProps> = () => {
  useEffect(() => {
    // scroll to the top
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapper_successStoryPage}>
        <h1>ІСТОРІЇ УСПІХУ <span>НАДИХАЙСЯ</span></h1>

        <div className={styles.wrapper_successStoryPage_storiesBlock}>
          <h3>більше історій</h3>

          <div className={styles.wrapper_successStoryPage_storiesBlock_items}>
            <SuccessStoryItem/>
            <SuccessStoryItem/>
            <SuccessStoryItem/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessStoriesPage;
