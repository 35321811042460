import React, {FC} from "react";
import {useNavigate} from "react-router-dom";

import shoppingBag from "../../assets/no-products-cart.svg";
import MainButtonTransparentBackground
  from "../../components/MainButtonTransparentBackground/MainButtonTransparentBackground";
import {MAIN_ROUTES} from "../../routes/mainRoutes";
import styles from "./EmptyCartPage.module.scss";

interface INoProductsCartPageProps {
}

const EmptyCartPage: FC<INoProductsCartPageProps> = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.emptyCartPage}>
      <img className={styles.emptyCartPage_icon} src={shoppingBag} alt="shopping bag" draggable={false}/>
      <div className={styles.emptyCartPage_description}>
        <h2 className={styles.emptyCartPage_description_title}>Корзина порожня</h2>
        <p className={styles.emptyCartPage_description_subtitle}>Щоб додати товари до корзини, натисніть кнопку
          “Додати до корзини” на сторінці товару</p>
        <MainButtonTransparentBackground
          text={"Перейти до Каталогу"}
          width={"auto"}
          onClick={() => navigate(MAIN_ROUTES.HOME)}
          type={"button"}
        />
      </div>
    </div>
  );
};

export default EmptyCartPage;
