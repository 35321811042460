import {Collapse} from "@mui/material";
import React, {FC, useEffect, useState} from "react";
import {TransitionGroup} from "react-transition-group";

import {useAppDispatch, useAppSelector} from "../../hooks/reduxHooks";
import {IProductCart} from "../../interfaces/IProductCart";
import {productsService} from "../../services/products.service";
import ProductItemOrder from "../ProductItemOrder/ProductItemOrder";
import styles from "./MyOrderListOfProducts.module.scss";

interface IMyOrderListOfProductsProps {
}

const MyOrderListOfProducts: FC<IMyOrderListOfProductsProps> = () => {
  const {products} = useAppSelector(state => state.cart);
  const [product, setProduct] = useState<IProductCart | null>(null);

  const dispatch = useAppDispatch();

  const getServiceFeeProduct = async () => {
    const res = await productsService.getById(16);
    setProduct(res.data as IProductCart);
    // const product = await instance.get<IProductCart>(`${endpoints.product.base}/16/`);
    // const serviceFee: IProductCart = {...product};
    // setProduct(serviceFee);
  }

  useEffect(() => {
    getServiceFeeProduct();

    // dispatch(cartActions.addProductId(16));
  }, []);

  return (
    <div className={styles.listOfProducts}>
      <h3 className={styles.listOfProducts_title}>Ваше замовлення</h3>

      <div className={styles.listOfProducts_list}>
        <TransitionGroup>
          {products.map((product, index) => (
            <Collapse key={index}>
              <ProductItemOrder key={index} product={product}/>
            </Collapse>
          ))}
          {product && <ProductItemOrder can_be_counted={false} product={product}/>}
        </TransitionGroup>
        {/*{products.map((product, index) =>*/}
        {/*    <ProductItemOrder key={index} product={product}/>)}*/}
      </div>
    </div>
  );
};

export default MyOrderListOfProducts;
