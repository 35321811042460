const myAccount = "/my-account";

export const MAIN_ROUTES = {
  HOME: "/home",
  REGISTRATION: "/registration",
  CONFIRM_EMAIL: "/confirm-email",
  VERIFY_EMAIL: "/verify-email",
  MY_ACCOUNT: myAccount,
  LOGIN: "/login",
  CART: "/cart",
  PRODUCT: "/product",
  CONTACTS: "/contacts",
  CONFIRM_ORDER: "/confirm-order",
  USER_INFO: `${myAccount}/user`,
  WITHDRAWAL_REQUESTS: `${myAccount}/withdrawal-requests`,
  REFERRAL_STRUCTURE: `${myAccount}/referrals`,
  REPORTS: `${myAccount}/reports`,
  EVENTS_CALENDAR: `${myAccount}/events-calendar`,
  MY_ORDERS: `${myAccount}/orders`,
  CATEGORIES: "/categories",
  FAVOURITES: "/favourites",
  SEARCH_RESULTS: "/results",
  CERTIFICATES: "/certificates",
  ABOUT_US: "/about-us",
  SUCCESS_STORIES: "/success-stories",
  MARKETING_PLAN: "/marketing-plan",
  BLOG: "/blog",
  PASSWORD_RECOVERY_STEP_1: "/password-recovery-step-1",
  PASSWORD_RECOVERY_STEP_2: "/password-recovery-step-2",
}
