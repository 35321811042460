import React from "react";

import avatar from "../../assets/user-avatar.svg";
import {levelNames} from "../../constants/levelNames";
import {IUserChainStructure} from "../../interfaces/IUserChainStructure";
import styles from "./ReferralStructureTreeItem.module.scss";

interface IForeignObjectProps {
  width: number;
  height: number;
  x: number;
}

const renderForeignObjectNode = ({
  nodeDatum,
  toggleNode,
  foreignObjectProps,
}: {
  nodeDatum: IUserChainStructure;
  toggleNode: () => void;
  foreignObjectProps: IForeignObjectProps;
}) => {
  const {
    first_name,
    last_name,
    mobile_nummer,
    photo,
    current_level,
    total_group_volume,
    personal_period_points,
    is_user_activated
  } = nodeDatum;

  return (
    <foreignObject style={{overflow: "visible"}} {...foreignObjectProps}>
      <div
        className={styles.item}
        style={{
          borderTopWidth: 7,
          borderTopStyle: "solid",
          borderTopColor: is_user_activated ? "#239B85" : "#E7121F"
        }}
      >
        <div className={styles.item_top}>
          <img className={styles.item_top_photo} src={photo || avatar} alt="user`s photo"/>
        </div>

        <div className={styles.item_user}>
          <h3 className={styles.item_user_fullname}>{last_name} {first_name}</h3>
          <p className={styles.item_user_phoneNumber}>{mobile_nummer}</p>
          <div className={styles.item_top_rank}>{levelNames[current_level - 1]}</div>
          <p className={styles.item_user_activity}>Об'єм Структури: {total_group_volume.toFixed(2)}б</p>
          <p className={styles.item_user_activity}>Особистий Об'єм: {personal_period_points.toFixed(2)}б</p>
        </div>
      </div>
    </foreignObject>
  )
};

export default renderForeignObjectNode;
