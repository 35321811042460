import React, {FC} from "react";

import avatar from "../../assets/user-avatar.svg";
import {levelNames} from "../../constants/levelNames";
import {useAppDispatch, useAppSelector} from "../../hooks/reduxHooks";
import {IUserChainStructure} from "../../interfaces/IUserChainStructure";
import {userActions} from "../../store/slices/userSlice";
import styles from "./ReferralItemList.module.scss";

interface IReferralItemListProps {
  referral: IUserChainStructure;
  index: number;
}

const ReferralItemList: FC<IReferralItemListProps> = ({referral, index}) => {
  const dispatch = useAppDispatch();
  const {currentLevel} = useAppSelector(state => state.user);

  const handleSetCurrentUser = () => {
    dispatch(userActions.setCurrentLevel(currentLevel + 1));
    dispatch(userActions.addBreadcrumbs(`${referral.first_name} ${referral.last_name} (Рівень ${currentLevel + 1}.${index})`));
    dispatch(userActions.getChildrenByCurrentUserListView({uuid: referral.uuid}));
  }

  const {first_name, last_name, photo, current_level, is_user_activated} = referral;

  return (
    <div
      className={styles.item}
      onClick={handleSetCurrentUser}
    >
      <div
        style={{
          borderLeftWidth: 2,
          borderLeftStyle: "solid",
          borderLeftColor: is_user_activated ? "#0DBD14" : "#E7121F"
        }}
        className={styles.item_user}
      >
        <img className={styles.item_user_photo} src={photo || avatar} alt="user`s photo" draggable={false}/>
        <div className={styles.item_user_info}>
          <p className={styles.item_user_info_name}>{first_name} {last_name}</p>
          <p className={styles.item_user_info_rank}>{levelNames[current_level - 1]}</p>
        </div>
      </div>
    </div>
  );
};

export default ReferralItemList;
