import React, {FC} from "react";
import UseAnimations from "react-useanimations";
import trash2 from "react-useanimations/lib/trash2";

import {calculateDiscountedPrice} from "../../helpers/calculateDiscountedPrice";
import {useAppDispatch, useAppSelector} from "../../hooks/reduxHooks";
import {IProductCart} from "../../interfaces/IProductCart";
import {cartActions} from "../../store/slices/cartSlice";
import styles from "./ProductItemOrder.module.scss";

interface IProductItemOrderProps {
  product: IProductCart;
  can_be_counted?: boolean;
}

const ProductItemOrder: FC<IProductItemOrderProps> = ({product, can_be_counted = true}) => {
  const {
    pk,
    points_to_count,
    count,
    name,
    main_image,
    price,
    discount_percent,
    images,
    can_pay_with_bonus,
    bonus_price
  } = product;

  const dispatch = useAppDispatch();
  const {user} = useAppSelector(state => state.user);
  const {products} = useAppSelector(state => state.cart);

  const handleChangeCount = (action: string) => {
    if (action === "add") {
      dispatch(cartActions.changeCountOfProductById({pk, count: count + 1, isUser: !!user}));
    }

    if (action === "subtract") {
      if (count > 1) {
        dispatch(cartActions.changeCountOfProductById({pk, count: count - 1, isUser: !!user}));
      }
    }
  }

  const handleDeleteProductFromCart = () => {
    dispatch(cartActions.deleteProductById(pk));
  }

  return (
    <div className={styles.productItemOrder}>
      <div
        className={styles.productItemOrder_photo}
      >
        <img
          src={main_image || images?.[0]}
          alt="product"
          draggable={false}
        />
      </div>
      <div className={styles.productItemOrder_description}>
        <div className={styles.productItemOrder_description_title}>
          <div className={styles.productItemOrder_description_title_name}>
            <p className={styles.productItemOrder_description_title_name_text}>{name}</p>
            <p className={styles.productItemOrder_description_title_name_code}>Код
              товару: <span>{pk}</span></p>
          </div>

          <p
            className={styles.productItemOrder_description_title_points}> {Number(points_to_count).toFixed(2)} балів</p>
        </div>

        {can_be_counted &&
          <div className={styles.productItemOrder_description_count}>
            <button
              className={styles.productItemOrder_description_count_button}
              onClick={() => handleChangeCount("subtract")}
            >-
            </button>
            <span className={styles.productItemOrder_description_count_number}>{count}</span>
            <button
              className={styles.productItemOrder_description_count_button}
              onClick={() => handleChangeCount("add")}
            >+
            </button>
          </div>}

        {(Number(discount_percent) && user) ?
          <div className={styles.productItemOrder_description_price}>
            <p className={styles.productItemOrder_description_price_old}>{Number(price)} ₴</p>
            <p
              className={styles.productItemOrder_description_price_new}>{calculateDiscountedPrice(Number(price), Number(discount_percent))} ₴</p>
          </div> :
          <div className={styles.productItemOrder_description_price}>
            <p
              className={styles.productItemOrder_description_price_full}>{can_pay_with_bonus ? `${Number(bonus_price).toFixed(2)} ПБ` : `${Number(price)}₴`}</p>
          </div>}

        {can_be_counted &&
          <button
            className={styles.productItemOrder_description_delete}
            onClick={handleDeleteProductFromCart}
          >
            <UseAnimations strokeColor="#FFF" animation={trash2} size={24}/>
          </button>}
      </div>
    </div>
  );
};

export default ProductItemOrder;
