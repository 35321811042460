import React, {FC, useState} from "react";
import toast from "react-hot-toast";
import {useNavigate} from "react-router-dom";

import favourite from "../../assets/favourite-dark.svg";
import shoppingCart from "../../assets/shopping-cart.svg";
import {handleTruncateText} from "../../helpers/truncateText";
import {useAppDispatch, useAppSelector} from "../../hooks/reduxHooks";
import {IProduct} from "../../interfaces/IProduct";
import {MAIN_ROUTES} from "../../routes/mainRoutes";
import {cartActions} from "../../store/slices/cartSlice";
import {productsActions} from "../../store/slices/productSlice";
import CustomModal from "../CustomModal/CustomModal";
import MainButtonRedBackground from "../MainButtonRedBackground/MainButtonRedBackground";
import ModalAddedToFavourite from "../ModalAddedToFavourite/ModalAddedToFavourite";
import styles from "./ProductCard.module.scss";

interface IProductCardProps {
  width: string;
  type: "usual" | "sale" | "promo";
  isFavourite: boolean;
  product: IProduct;
}

const ProductCard: FC<IProductCardProps> = ({type, isFavourite, product, width}) => {
  const {
    price,
    name,
    main_image,
    points_to_count,
    pk,
    discounted_price,
    can_pay_with_bonus,
    bonus_price
  } = product;

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {user} = useAppSelector(state => state.user);

  const handleAddProductToCart = (event: React.MouseEvent<HTMLButtonElement>, action: "add" | "navigate") => {
    event.stopPropagation();

    dispatch(cartActions.addProductId(pk)); // includes checking the product in the cart
    if (action === "navigate") {
      navigate(MAIN_ROUTES.CART);
    }

    toast.success(`Продукт "${name}" успішно додано до корзини!`, {id: "cart"});
  }

  const handleAddProductToFavourite = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setIsModalOpen(true);
  }

  const handleClickOnProductCard = () => {
    if (!isModalOpen) {
      navigate(`${MAIN_ROUTES.PRODUCT}/${pk}`); //redirect to detailed product info page
      dispatch(productsActions.addRecentlyViewedProduct(product));
    }
  }

  return (
    <div
      style={{width}}
      className={styles.productCard}
      onClick={handleClickOnProductCard}
    >
      <div className={styles.productCard_photo}>
        <img
          src={main_image}
          alt="product`s photo"
          draggable={false}
          loading={"lazy"}
        />
      </div>
      {!isFavourite &&
        <div
          className={styles.productCard_favourite}
          onClick={handleAddProductToFavourite}
        >
          <img src={favourite} alt="heart" draggable={false}/>
        </div>}

      {/* modal window that the product is added to favourites */}
      <CustomModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        component={ModalAddedToFavourite}
      />

      {/* For PROMO rename!!! */}
      {((type === "sale" || type === "promo") && user) &&
        <div className={styles.productCard_sale}>
          <p>SALE</p>
        </div>}

      {/*{(type === "promo" && user) &&*/}
      {/*  <div className={styles.productCard_promo}>*/}
      {/*    <p>2 + 1</p>*/}
      {/*  </div>}*/}

      <div className={styles.productCard_description}>
        <div className={styles.productCard_description_title}>
          {!can_pay_with_bonus &&
            <p className={styles.productCard_description_title_points}>{Number(points_to_count).toFixed(2)} балів</p>}
          <p className={styles.productCard_description_title_content}>{handleTruncateText(name, 60)}</p>
        </div>

        {/* if the user is logged in - he sees the product with a discount price*/}
        {((type === "sale" || type === "promo") && user) &&
          <div className={styles.productCard_description_row}>
            <div className={styles.productCard_description_pricesSale}>
              <p className={styles.productCard_description_pricesSale_old}>{Number(price).toFixed(2)} ₴</p>
              <p className={styles.productCard_description_pricesSale_new}>{discounted_price.toFixed(2)} ₴</p>
            </div>

            <button
              className={styles.productCard_description_buttons_cart}
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => handleAddProductToCart(event, "add")}
            >
              <img src={shoppingCart} alt="shopping cart" draggable={false} loading={"lazy"}/>
            </button>
          </div>}

        {((type === "sale" || type === "promo") && !user) &&
          <div className={styles.productCard_description_pricesSale}>
            <p className={styles.productCard_description_pricesSale_new}>{Number(price).toFixed(2)} ₴</p>
          </div>}

        {(type !== "sale" && type !== "promo") &&
          <p
            className={styles.productCard_description_price}>{can_pay_with_bonus ? `${Number(bonus_price).toFixed(2)} ПБ` : `${Number(price).toFixed(2)} ₴`}</p>}

        <div className={styles.productCard_description_buttons}>
          <MainButtonRedBackground
            text={"Купити"}
            width={"100%"}
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => handleAddProductToCart(event, "navigate")}
            type={"button"}
            isDisabled={false}
          />
          <button
            className={styles.productCard_description_buttons_cart}
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => handleAddProductToCart(event, "add")}
          >
            <img src={shoppingCart} alt="shopping cart" draggable={false} loading={"lazy"}/>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
