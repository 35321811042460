import Cookies from "universal-cookie";

import {ITokens} from "../interfaces/ITokens";

export const storeTokens = (tokens: ITokens) => {
  localStorage.setItem("tokens", JSON.stringify(tokens));
  // const cookies = new Cookies();

  // token expires after 7 days
  // const expiresDate = new Date(new Date().getTime() + (7 * 24 * 60 * 60 * 1000));
  // cookies.set("jwt_auth", tokens, {
  //     expires: expiresDate,
  //     // secure: true,
  //     httpOnly: true, // to prevent XXS attack, to disable cookies for JavaScript
  //     path: "/", // to make it available throughout the site.
  //     sameSite: "strict" // to prevent CSRF attack
  // });
}
