import React, {FC} from "react";

import styles from "./StatusActivity.module.scss";

interface IStatusActivityProps {
    status: "Активний" | "Неактивний";
}

const StatusActivity: FC<IStatusActivityProps> = ({status}) => {
  return (
    <div className={`${styles.status} ${status === "Активний" ? styles.active : styles.inactive}`}>{status}</div>
  );
};

export default StatusActivity;
