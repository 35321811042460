import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import React, {ChangeEvent, FC, useState} from "react";

import MainButtonRedBackground from "../MainButtonRedBackground/MainButtonRedBackground";
import styles from "./PriceRangeExpandable.module.scss";

interface IPriceRangeProps {
}

const PriceRangeExpandable: FC<IPriceRangeProps> = () => {
  const [minPrice, setMinPrice] = useState<number>(0);
  const [maxPrice, setMaxPrice] = useState<number>(0);

  const handleChangeMinPrice = (event: ChangeEvent<HTMLInputElement>) => {
    const price = event.target.value;

    setMinPrice(Number(price));
  }

  const handleChangeMaxPrice = (event: ChangeEvent<HTMLInputElement>) => {
    const price = event.target.value;

    setMaxPrice(Number(price));
  }

  return (
    <Accordion
      style={{
        padding: 16,
        background: "#1D1D1D",
        borderRadius: 4,
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon style={{color: "white"}}/>}
        aria-controls="panel2-content"
        id="panel2-header"
        style={{
          color: "white",
          fontSize: 16,
          fontWeight: 600,
          lineHeight: "150%",
        }}
      >
                Цінова категорія
      </AccordionSummary>
      <AccordionDetails style={{padding: 0}}>
        <form className={styles.priceRange}>
          <div className={styles.priceRange_inputs}>
            <label className={styles.priceRange_inputs_label}>
              <input
                className={styles.priceRange_inputs_label_input}
                type="text"
                placeholder={"Від"}
                value={minPrice || ""}
                onChange={handleChangeMinPrice}
              />
            </label>
            <label className={styles.priceRange_inputs_label}>
              <input
                className={styles.priceRange_inputs_label_input}
                type="text"
                placeholder={"До"}
                value={maxPrice || ""}
                onChange={handleChangeMaxPrice}
              />
            </label>
          </div>

          <MainButtonRedBackground
            text={"Застосувати"}
            width={"100%"}
            onClick={null}
            type={"button"}
            isDisabled={false}
          />
        </form>
      </AccordionDetails>
    </Accordion>
  );
};

export default PriceRangeExpandable;
