import React, {FC, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

import avatar from "../../assets/login-registration.svg";
import {MAIN_ROUTES} from "../../routes/mainRoutes";
import MainButtonRedBackground from "../MainButtonRedBackground/MainButtonRedBackground";
import MainButtonTransparentBackground from "../MainButtonTransparentBackground/MainButtonTransparentBackground";
import styles from "./ModalForUnauthUsers.module.scss";

interface IModalForUnauthUsersProps {
}

const ModalForUnauthUsers: FC<IModalForUnauthUsersProps> = () => {
  const [deviceWidth, setDeviceWidth] = useState<number>(window.innerWidth);

  const navigate = useNavigate();

  const handleResize = () => {
    setDeviceWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className={styles.modal}>
      <img src={avatar} alt="user avatar" draggable={false}/>

      <div className={styles.modal_content}>
        <div className={styles.modal_content_description}>
          <h2>Увійдіть або Зареєструйтесь</h2>
          <p>Для того, щоб оформити замовлення, вам потрібно увійти в особистий кабінет.</p>
        </div>

        <div className={styles.modal_content_buttons}>
          <MainButtonTransparentBackground
            text={"Реєстрація"}
            width={deviceWidth > 768 ? "auto" : "100%"}
            onClick={() => navigate(MAIN_ROUTES.REGISTRATION)}
            type={"button"}
          />
          <MainButtonRedBackground
            text={"На Головну"}
            width={deviceWidth > 768 ? "auto" : "100%"}
            onClick={() => navigate(MAIN_ROUTES.HOME)}
            type={"button"}
            isDisabled={false}
          />
        </div>
      </div>
    </div>
  );
};

export default ModalForUnauthUsers;
