import {AccordionDetails, styled} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import React, {useEffect, useState} from "react";
import {FieldError, FieldErrors, FieldValues} from "react-hook-form";

import search from "../../assets/search-white.svg";
import {popularCities} from "../../constants/popularCities";
import {novaPoshtaService} from "../../services/novaPoshta.service";
import styles from "./PopularCitiesAccordion.module.scss";

interface IPopularCitiesAccordionProps<T extends FieldValues> {
  errors: FieldErrors<T>;
  name: string;
  location: string;
  setValue: any;
  setAllDepartmentsInCity: (value: any) => void;
  department: {
    description: string;
    ref: string;
    cityRef: string;
  };
  setDepartment: (value: {
    description: string;
    ref: string;
    cityRef: string;
  }) => void;
  setIsLoading: (value: boolean) => void;
}

const CustomAccordion = styled(Accordion)({
  width: "100%",
  background: "#1D1D1D",
  display: "flex",
  flexDirection: "column",
  gap: 8,
  boxShadow: "none",
  border: "none",
  "&:before": {
    backgroundColor: "transparent"
  },
  "& .Mui-focusVisible": {
    backgroundColor: "transparent"
  },
});

const CustomAccordionDetails = styled(AccordionDetails)({
  padding: 0,
  display: "flex",
  flexDirection: "column",
  gap: 16
});

const CustomAccordionSummary = styled(AccordionSummary)({
  display: "flex",
  gap: 16,
  padding: 0,
});

const PopularCitiesAccordion = <T extends FieldValues>({
  name,
  errors,
  location,
  setValue,
  setAllDepartmentsInCity,
  department,
  setDepartment,
  setIsLoading
}: IPopularCitiesAccordionProps<T>) => {
  const [cityRef, setCityRef] = useState<string>("");
  const [addressesByCityName, setAddressesByCityName] = useState<{ description: string; cityRef: string }[]>([])
  const [isAccordionOpen, setIsAccordionOpen] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>("");

  const getCityRefByName = async (location: string) => {
    if (location) {
      const city = location.split(" (")[0]; // get city from string, ex. Київ (Київська обл.)

      try {
        const res = await novaPoshtaService.getCityRefByName(city);
        const cityRef = res.data.data?.[0]?.Ref;
        setCityRef(cityRef);
      } catch (e) {
        console.error(e);
      }
    }
  }

  const handleSelectCity = async (type: "popular" | "manual", location: string = "", item: {
    description: string;
    cityRef: string
  } = {description: "", cityRef: ""}) => {
    if (department) {
      setDepartment({description: "", cityRef: "", ref: ""});
    }

    if (type === "popular") {
      setValue(name, location);

      await getCityRefByName(location);
    }
    if (type === "manual") {
      const {description, cityRef} = item;

      setValue(name, description);
      setCityRef(cityRef);
    }

    setIsAccordionOpen(false);
  }

  const getAllDepartmentsByCityRef = async () => {
    try {
      setIsLoading(true);

      const res = await novaPoshtaService.getAllAddressesByCityRef(cityRef);

      setAllDepartmentsInCity(res.data.data.map((department: any) => ({
        description: department.Description,
        ref: department.Ref,
        cityRef: department.CityRef,
      })));
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  }

  const getAllAddressesByCityName = async () => {
    try {
      const res = await novaPoshtaService.getCityRefByName(inputValue);

      setAddressesByCityName(res.data.data.map((department: any) => ({
        description: department.Description,
        cityRef: department.Ref,
      })));
    } catch (e) {
      console.error(e);
    }
  }

  useEffect(() => {
    if (cityRef) {
      getAllDepartmentsByCityRef();
    }
  }, [cityRef]);

  useEffect(() => {
    if (!isAccordionOpen) {
      setInputValue("");
    }
  }, [isAccordionOpen]);

  useEffect(() => {
    if (inputValue) {
      getAllAddressesByCityName();
    }
  }, [inputValue]);

  return (
    <CustomAccordion expanded={isAccordionOpen}>
      <CustomAccordionSummary
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <label
          className={styles.label}
          onClick={() => setIsAccordionOpen(!isAccordionOpen)}
        >
          Місто
          <div className={styles.label_input}>
            {location}
          </div>
          {errors[name] && <span className={styles.label_error}>{(errors[name] as FieldError).message}</span>}
        </label>

      </CustomAccordionSummary>
      <CustomAccordionDetails>
        <div className={styles.expandedAccordion}>
          <div className={styles.expandedAccordion_input}>
            <img
              src={search}
              alt="search icon"
            />
            <input
              type="text"
              placeholder={"Введіть населений пункт"}
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
            />
          </div>

          {!inputValue && <p className={styles.expandedAccordion_title}>Популярні населені пункти</p>}
          {!inputValue ? <ul className={styles.expandedAccordion_list}>
            {popularCities.map((location, index) =>
              <li
                onClick={() => handleSelectCity("popular", location)}
                key={index}
                className={styles.expandedAccordion_list_item}
              >{location}</li>)}
          </ul> :
            <ul className={styles.expandedAccordion_list}>
              {addressesByCityName.map((item, index) =>
                <li
                  onClick={() => handleSelectCity("manual", "", item)}
                  key={index}
                  className={styles.expandedAccordion_list_item}
                >{item.description}</li>)}
              {addressesByCityName.length === 0 &&
                <li
                  className={styles.expandedAccordion_list_item}
                >На жаль, адреси по вашому запиту не знайдено</li>}
            </ul>}
        </div>
      </CustomAccordionDetails>
    </CustomAccordion>
  );
};

export default PopularCitiesAccordion;
