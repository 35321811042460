import React, {FC, useEffect, useState} from "react";
import toast, {Toaster} from "react-hot-toast";
import {useLocation} from "react-router-dom";

import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import CategoryExpandable from "../../components/CategoryExpandable/CategoryExpandable";
import EmptyStateProductsSearch from "../../components/EmptyStateProductsSearch/EmptyStateProductsSearch";
import Loader from "../../components/Loader/Loader";
import OrderingBy from "../../components/OrderingBy/OrderingBy";
import PriceRangeExpandable from "../../components/PriceRangeExpandable/PriceRangeExpandable";
import ProductCard from "../../components/ProductCard/ProductCard";
import {useAppDispatch, useAppSelector} from "../../hooks/reduxHooks";
import {categoriesActions} from "../../store/slices/categoriesSlice";
import {productsActions} from "../../store/slices/productSlice";
import styles from "./SearchResultsPage.module.scss";

interface ISearchResultsPageProps {
}

const SearchResultsPage: FC<ISearchResultsPageProps> = () => {
  const {search} = useLocation();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchParams, setSearchParams] = useState<string | null>(null);

  const dispatch = useAppDispatch();
  const {
    totalCountFilteredProducts,
    filteredProducts,
    filteringValues,
    searchValue,
    status
  } = useAppSelector(state => state.products);

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    setSearchParams(searchParams.get("search"));
  }, [search]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })
  }, [searchParams, searchValue]);

  useEffect(() => {
    if (searchValue) {
      getFilteredProducts(searchValue);
    }
  }, [searchValue]);

  const getAllCategories = () => {
    dispatch(categoriesActions.getAll());
  }

  const getFilteredProducts = async (search: string) => {
    dispatch(productsActions.getFiltered({search, ordering: filteringValues.ordering.ordering, page: 1}));
  };

  useEffect(() => {
    return () => {
      toast.remove("cart");
    }
  }, []);

  if (status === "loading") {
    return <Loader/>;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapper_searchResultsPage}>
        <div className={styles.wrapper_searchResultsPage_title}>
          <h1 className={styles.wrapper_searchResultsPage_title_main}>РЕЗУЛЬТАТИ <span>ПОШУКУ</span></h1>
          <p className={styles.wrapper_searchResultsPage_title_description}>Результати пошуку за запитом
            “{searchValue || searchParams}”. Знайдено {totalCountFilteredProducts} товарів</p>
        </div>

        <div className={styles.wrapper_searchResultsPage_ordering}>
          <div className={styles.wrapper_searchResultsPage_ordering_breadcrumbs}>
            <Breadcrumbs type={"categories"}/>
            <OrderingBy/>
          </div>

          <div className={styles.wrapper_searchResultsPage_ordering_content}>
            <div className={styles.wrapper_searchResultsPage_ordering_content_products}>
              <aside className={styles.wrapper_searchResultsPage_ordering_content_products_sidebar}>
                <CategoryExpandable/>
                <PriceRangeExpandable/>
              </aside>
              <div className={styles.wrapper_searchResultsPage_ordering_content_products_list}>
                {filteredProducts.length === 0 ?
                  <EmptyStateProductsSearch/> :
                  <div
                    className={styles.wrapper_searchResultsPage_ordering_content_products_list_pagination}>
                    <div className={styles.products}>
                      {filteredProducts.map((product, index) =>
                        <ProductCard
                          key={index}
                          width={"100%"}
                          product={product}
                          type={product.is_promo ? "promo" : (+product.discount_percent ? "sale" : "usual")}
                          isFavourite={false}
                        />)}
                    </div>

                    {/*<Pagination*/}
                    {/*    totalItems={data?.data?.count || 1}*/}
                    {/*    currentPage={currentPage}*/}
                    {/*    onPageChange={setCurrentPage}*/}
                    {/*/>*/}
                  </div>}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Toaster/>
    </div>
  );
};

export default SearchResultsPage;
