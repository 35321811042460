import {joiResolver} from "@hookform/resolvers/joi";
import {Grow} from "@mui/material";
import {AxiosError} from "axios";
import {FC, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import toast, {Toaster} from "react-hot-toast";
import {useLocation, useNavigate} from "react-router-dom";

import CustomDatePicker from "../../components/CustomDatePicker/CustomDatePicker";
import CustomCheckbox from "../../components/CustomRadioButton/CustomRadioButton";
import InputField from "../../components/InputField/InputField";
import InputFieldMask from "../../components/InputFieldMask/InputFieldMask";
import Loader from "../../components/Loader/Loader";
import MainButtonRedBackground from "../../components/MainButtonRedBackground/MainButtonRedBackground";
import MainButtonTransparentBackground
  from "../../components/MainButtonTransparentBackground/MainButtonTransparentBackground";
import {IDatePickerValue} from "../../interfaces/IDatePickerValue";
import {IUser} from "../../interfaces/IUser";
import {MAIN_ROUTES} from "../../routes/mainRoutes";
import {authService} from "../../services/auth.service";
import {registrationValidator} from "../../validators/registration.validator";
import styles from "./RegistrationPage.module.scss";

interface IRegistrationPageProps {
}

const RegistrationPage: FC<IRegistrationPageProps> = () => {
  const {search} = useLocation();

  const [invitationOption, setInvitationOption] = useState<string>("Так");
  const [dateOfBirth, setDateOfBirth] = useState<IDatePickerValue | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [refId, setRefId] = useState<string | null>(null);
  const [error, setError] = useState<string>("");

  const navigate = useNavigate();

  const {
    register,
    watch,
    handleSubmit,
    setValue,
    formState: {errors, isValid},
  } = useForm<IUser>({
    mode: "all",
    resolver: joiResolver(registrationValidator),
  });

  const mobileNumber = watch("mobile_number");

  useEffect(() => {
    if (invitationOption === "Ні" && !refId) {
      setValue("invited_by", "");
    }
  }, [invitationOption]);

  useEffect(() => {
    // get referral invitation code from url
    const searchParams = new URLSearchParams(search);
    const refId = searchParams.get("ref_id");
    setRefId(refId);

    if (refId) {
      setValue("invited_by", refId);
    }

    // scroll to the top
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });

    return () => {
      toast.remove("create-user");
    }
  }, []);

  const handleCreateFormData = (user: IUser) => {
    let formData = new FormData();

    const {
      mobile_number,
      date_of_birth,
      city,
      email,
      first_name,
      last_name,
      invited_by,
      password,
      confirm_password
    } = user;

    formData.append("mobile_nummer", mobile_number);
    formData.append("date_of_birth", date_of_birth);
    formData.append("email", email);
    formData.append("first_name", first_name);
    formData.append("last_name", last_name);
    formData.append("city", city);
    formData.append("password", password);
    formData.append("confirm_password", confirm_password!);
    formData.append("callback_url", `${process.env.REACT_APP_APP_URL}${MAIN_ROUTES.VERIFY_EMAIL}`);
    if (invited_by) {
      formData.append("invited_by", invited_by);
    }

    return formData;
  }

  const handleFormatDateToDbFormat = (fullDate: Date | undefined) => {
    if (!fullDate) {
      return;
    }
    const date = new Date(fullDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // added "1", because months in JavaScript are indexed from 0
    const day = String(date.getDate()).padStart(2, "0");

    const yearsDifference = new Date().getFullYear() - year;
    if (yearsDifference < 12) {
      setError("Вік має бути більшим ніж 12 років!");
      return;
    }

    return `${year}-${month}-${day}`; // ex. 2023-02-01
  }

  const handleCreateUser = async (data: IUser) => {
    setIsLoading(true);

    const formattedDate = handleFormatDateToDbFormat(dateOfBirth?.$d);
    if (!formattedDate) {
      setIsLoading(false);
      return;
    }

    const newUser = {
      ...data,
      date_of_birth: formattedDate,
    };

    const formData = handleCreateFormData(newUser);

    try {
      const res = await authService.create(formData);

      if (res.status === 200) {
        navigate(MAIN_ROUTES.CONFIRM_EMAIL);
      }
    } catch (e) {
      const error = e as AxiosError;
      setError(JSON.stringify(error?.response?.data || error?.message));
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  const handleNavigate = (path: string) => {
    if (path) {
      navigate(path);
    } else {
      navigate(-1); // navigate back
    }
  }

  useEffect(() => {
    if (error) {
      toast.error((error), {id: "create-user", duration: 5000});
      setError("");
    }
  }, [error]);

  return (
    isLoading ?
      <Loader/> :
      <div className={styles.wrapper}>
        <div className={styles.wrapper_registrationPage}>
          <h1 className={styles.wrapper_registrationPage_title}>ПРИЄДНУЙТЕСЬ У СВІТ <span>КРАСИ</span></h1>

          <div className={styles.wrapper_registrationPage_content}>
            <div className={styles.wrapper_registrationPage_content_rectangle}></div>
            <form className={styles.wrapper_registrationPage_content_form}>
              <div className={styles.wrapper_registrationPage_content_form_top}>
                <h2>Реєстрація</h2>

                <p>Вже зареєстровані?
                  <span onClick={() => handleNavigate(MAIN_ROUTES.LOGIN)}> Увійти</span>
                </p>
              </div>

              <div className={styles.wrapper_registrationPage_content_form_block}>
                <p className={styles.subtitle}>Особиста інформація</p>
                <div className={styles.wrapper_registrationPage_content_form_block_user}>
                  <div className={styles.wrapper_registrationPage_content_form_block_user_flex2}>
                    <InputField
                      width={"100%"}
                      placeholder={""}
                      label={"Прізвище"}
                      type={"text"} //type of input (password, text ...)
                      name={"last_name"}
                      register={register}
                      errors={errors}
                    />
                    <InputField
                      width={"100%"}
                      placeholder={""}
                      label={"Імʼя"}
                      type={"text"} //type of input (password, text ...)
                      name={"first_name"}
                      register={register}
                      errors={errors}
                    />
                  </div>
                  <div className={styles.wrapper_registrationPage_content_form_block_user_flex2}>
                    <InputField
                      width={"100%"}
                      placeholder={""}
                      label={"Email"}
                      type={"email"} //type of input (password, text ...)
                      name={"email"}
                      register={register}
                      errors={errors}
                    />

                    <InputFieldMask<IUser>
                      errors={errors}
                      name={"mobile_number"}
                      placeholder={"+38 (0XX) XXX-XX-XX"}
                      mobile_number_value={mobileNumber}
                      register={register}
                    />
                  </div>
                  <div className={styles.wrapper_registrationPage_content_form_block_user_flex2}>
                    <CustomDatePicker
                      label={"Дата народження"}
                      value={dateOfBirth}
                      setValue={setDateOfBirth}
                    />

                    <InputField
                      width={"100%"}
                      placeholder={""}
                      label={"Населений пункт"}
                      type={"text"} //type of input (password, text ...)
                      name={"city"}
                      register={register}
                      errors={errors}
                    />
                  </div>
                  <div className={styles.wrapper_registrationPage_content_form_block_user_flex2}>
                    <InputField
                      width={"100%"}
                      placeholder={""}
                      label={"Пароль"}
                      type={"password"} //type of input (password, text ...)
                      name={"password"}
                      register={register}
                      errors={errors}
                    />
                    <InputField
                      width={"100%"}
                      placeholder={""}
                      label={"Повторіть пароль"}
                      type={"password"} //type of input (password, text ...)
                      name={"confirm_password"}
                      register={register}
                      errors={errors}
                    />
                  </div>

                  <div className={styles.wrapper_registrationPage_content_form_block_user_invite}>
                    <p>Вас запросили у Life Stylle?</p>
                    <div
                      className={styles.wrapper_registrationPage_content_form_block_user_invite_checkboxes}>
                      <CustomCheckbox
                        label={"Так"}
                        isChecked={invitationOption === "Так"}
                        setIsChecked={setInvitationOption}
                      />
                      <CustomCheckbox
                        label={"Ні"}
                        isChecked={invitationOption === "Ні"}
                        setIsChecked={setInvitationOption}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {invitationOption === "Так" &&
                <Grow in={invitationOption === "Так"}
                  style={{transformOrigin: "0 0 0"}}
                  {...(invitationOption === "Так" ? {timeout: 1000} : {})}
                >
                  <div className={styles.wrapper_registrationPage_content_form_block}>
                    <p className={styles.subtitle}>Введіть інформацію про вашого консультанта</p>
                    <div className={styles.wrapper_registrationPage_content_form_block_advisor}>
                      <InputField
                        width={"100%"}
                        label={"Реферальний ID"}
                        type={"text"}
                        name={"invited_by"}
                        register={register}
                        errors={errors}
                        placeholder={""}
                        disabled={!!refId}
                      />
                    </div>
                  </div>
                </Grow>}

              <div className={styles.wrapper_registrationPage_content_form_buttons}>
                <MainButtonTransparentBackground
                  text={"Повернутись"}
                  width={"auto"}
                  onClick={() => handleNavigate("")}
                  type={"button"}
                />
                <MainButtonRedBackground
                  text={"Зареєструватись"}
                  width={"auto"}
                  onClick={handleSubmit(handleCreateUser)}
                  type={"submit"}
                  isDisabled={!isValid || mobileNumber.includes("_") || !dateOfBirth}
                />
              </div>
            </form>
          </div>
        </div>

        <Toaster/>
      </div>
  );
};

export default RegistrationPage;
