import React, {FC, useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";

import {useAppDispatch} from "../../hooks/reduxHooks";
import {MAIN_ROUTES} from "../../routes/mainRoutes";
import {cartActions} from "../../store/slices/cartSlice";
import {categoriesActions} from "../../store/slices/categoriesSlice";
import {deliveryActions} from "../../store/slices/deliverySlice";
import {orderActions} from "../../store/slices/orderSlice";
import {productsActions} from "../../store/slices/productSlice";
import {UIActions} from "../../store/slices/UISlice";
import {userActions} from "../../store/slices/userSlice";
import styles from "./Sidebar.module.scss";

interface ISidebarProps {
}

const Sidebar: FC<ISidebarProps> = () => {
  const {pathname} = useLocation();

  const [activeLink, setActiveLink] = useState<string>(pathname);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const links = [
    {name: "Особиста Інформація", path: MAIN_ROUTES.USER_INFO},
    {name: "Моя Структура", path: MAIN_ROUTES.REFERRAL_STRUCTURE},
    {name: "Історія Замовлень", path: MAIN_ROUTES.MY_ORDERS},
    // {name: 'Замовлення Партнерів', path: MAIN_ROUTES.PERSONAL_INFO},
    {name: "Подача Заяви на Вивід Балів", path: MAIN_ROUTES.WITHDRAWAL_REQUESTS},
    // {name: "Мої Звіти", path: MAIN_ROUTES.REPORTS},
    // {name: "Календар Подій", path: MAIN_ROUTES.EVENTS_CALENDAR},
  ]

  const handleNavigate = (path: string) => {
    navigate(path);
  }

  useEffect(() => {
    setActiveLink(pathname);
  }, [pathname]);

  const handleLogout = () => {
    dispatch(userActions.reset());
    dispatch(categoriesActions.reset());
    dispatch(deliveryActions.reset());
    dispatch(orderActions.reset());
    dispatch(UIActions.reset());
    dispatch(cartActions.resetCart());
    dispatch(productsActions.reset());

    localStorage.removeItem("tokens"); //delete tokens from local storage
    // localStorage.clear(); // clear all data from local storage

    // const cookies = new Cookies();
    // cookies.remove("jwt_auth"); // delete tokens from cookies

    // window.location.reload(); // reloads page after clearing localstorage
    handleNavigate(MAIN_ROUTES.HOME);
  }

  return (
    <aside className={styles.sidebar}>
      <ul className={styles.sidebar_links}>
        {links.map(({name, path}, index) => (
          <li
            key={index}
            className={`${styles.sidebar_links_item} ${activeLink === path ? styles.active : ""}`}
            onClick={() => handleNavigate(path)}
          >
            {name}
          </li>))}
      </ul>

      <p
        className={styles.sidebar_logout}
        onClick={handleLogout}
      >Вихід</p>
    </aside>
  );
};

export default Sidebar;
