import React, {FC, useEffect, useRef, useState} from "react";

import chevron from "../../assets/chevron-right.svg";
import styles from "./CustomDropdown.module.scss";

interface ICustomDropdownProps {
  width: string;
  option: string;
  setOption: (value: string) => void;
  defaultOption: string;
  data: any;
  label: string;
}

const CustomDropdown: FC<ICustomDropdownProps> = ({option, setOption, defaultOption, data, label, width}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleSelectOption = (option: string) => {
    if (option !== defaultOption) {
      setOption(option);
    }

    setIsDropdownOpen(false);
  }

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    if (isDropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDropdownOpen]);

  return (
    <div
      style={{width}}
      className={styles.dropdown_wrapper}
      ref={dropdownRef}
    >
      <span className={styles.dropdown_wrapper_label}>{label}</span>

      <div
        onClick={() => setIsDropdownOpen(prev => !prev)}
        className={styles.dropdown_wrapper_button}
      >
        <span>{option || defaultOption}</span>

        <img
          className={styles.chevron}
          src={chevron}
          alt="chevron down"
          draggable={false}
        />
      </div>

      {isDropdownOpen &&
        <ul className={styles.dropdown_wrapper_options}>
          {data.map((option: string, index: number) =>
            <li
              key={index}
              onClick={() => handleSelectOption(option)}
              style={{background: `${option === defaultOption && "rgba(204, 204, 204, 0.3)"} `}}
            >{option}</li>)}
        </ul>}
    </div>
  );
};

export default CustomDropdown;
