import React, {FC, useEffect} from "react";
import toast, {Toaster} from "react-hot-toast";
import {useQuery} from "react-query";
import {useOutletContext} from "react-router-dom";

import BannerJoinUs from "../../components/BannerJoinUs/BannerJoinUs";
import CategorySwiper from "../../components/CategorySwiper/CategorySwiper";
import Loader from "../../components/Loader/Loader";
import MainSwiper from "../../components/MainSwiper/MainSwiper";
import NewProducts from "../../components/NewProducts/NewProducts";
import ProductsBlockContainer from "../../components/ProductsBlockContainer/ProductsBlockContainer";
import {useAppDispatch, useAppSelector} from "../../hooks/reduxHooks";
import {productsService} from "../../services/products.service";
import {categoriesActions} from "../../store/slices/categoriesSlice";
import {imagesActions} from "../../store/slices/imagesSlice";
import styles from "./HomePage.module.scss";

interface IHomePageProps {
}

const HomePage: FC<IHomePageProps> = () => {
  const dispatch = useAppDispatch();
  const {categories, status: categoryStatus} = useAppSelector(state => state.categories);
  const {allPromoProducts, status: productStatus} = useAppSelector(state => state.products);
  const {status: imageStatus} = useAppSelector(state => state.images);

  //get all products
  const {isLoading, data, error} = useQuery(["all-products"],
    async () => await productsService.getAll(), {
      refetchInterval: 5000
    });

  const {
    bestsellers,
    newProducts,
    promotionalProducts,
  } = useOutletContext<any>();

  const getAllCategories = () => {
    dispatch(categoriesActions.getAll());
  };

  const getBannerImages = () => {
    dispatch(imagesActions.getBannerImages());
  };

  // const getAllPromoProducts = () => {
  //   dispatch(productsActions.getAllPromoProducts());
  // }

  useEffect(() => {
    // scroll to the top
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });

    getAllCategories();
    getBannerImages();
    // getAllPromoProducts();

    return () => {
      toast.remove("cart");
    }
  }, []);

  if (isLoading || categoryStatus === "loading" || productStatus === "loading" || imageStatus === "loading") {
    return <Loader/>;
  }

  const discountedProducts = data!.data!.results.filter((product) => +product.discount_percent > 0);

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapper_homePage}>
        <MainSwiper/>
        <CategorySwiper categories={categories}/>
        <NewProducts ref={newProducts} products={data!.data!.results}/>
        <ProductsBlockContainer ref={promotionalProducts} products={discountedProducts} title={"Акційні товари"}/>
        <ProductsBlockContainer ref={bestsellers} products={data!.data!.results} title={"Топ продаж"}/>
        <BannerJoinUs/>
      </div>

      <Toaster/>
    </div>
  );
};

export default HomePage;
