import React, {FC} from "react";
import {useNavigate} from "react-router-dom";

import MainButtonTransparentBackground
  from "../../components/MainButtonTransparentBackground/MainButtonTransparentBackground";
import {MAIN_ROUTES} from "../../routes/mainRoutes";
import styles from "./ErrorPage.module.scss"

interface IErrorPageProps {
}

const ErrorPage: FC<IErrorPageProps> = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapper_errorPage}>
        <div className={styles.wrapper_errorPage_description}>
          <h1 className={styles.wrapper_errorPage_description_404}><span>404</span></h1>
          <p className={styles.wrapper_errorPage_description_text}>На жаль, сторінку не знайдено... </p>
        </div>

        <MainButtonTransparentBackground
          text={"На Головну"}
          width={"auto"}
          onClick={() => navigate(MAIN_ROUTES.HOME)}
          type={"button"}
        />
      </div>
    </div>
  );
};

export default ErrorPage;
