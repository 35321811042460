import React, {FC} from "react";

import styles from "./MainButtonRedBackground.module.scss";

interface IMainButtonRedBackgroundProps {
    text: string;
    width: string;
    onClick: any;
    type: "button" | "submit" | "reset";
    isDisabled: boolean;
}

const MainButtonRedBackground: FC<IMainButtonRedBackgroundProps> = ({text, width, onClick, type, isDisabled}) => {
  return (
    <button
      className={`${styles.button} ${isDisabled ? styles.disabled : ""}`}
      style={{width}}
      onClick={onClick && onClick}
      type={type}
      disabled={isDisabled}
    >
      <span className={`${styles.ripple} ${isDisabled ? styles.disabled : ""}`}></span>
      {text}
    </button>
  );
};

export default MainButtonRedBackground;
