import React, {FC} from "react";

import styles from "./ProductDescription.module.scss";

interface IProductDescriptionProps {
    description: string;
}

const ProductDescription: FC<IProductDescriptionProps> = ({description}) => {
  return (
    <section
      className={styles.description}
      dangerouslySetInnerHTML={{__html: description}}
    />
  );
};

export default ProductDescription;
