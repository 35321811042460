import React, {FC, useEffect, useState} from "react";
import {Toaster} from "react-hot-toast";
import {useNavigate} from "react-router-dom";

import chevron from "../../assets/chevron-left.svg";
import AccordionOrderItem from "../../components/AccordionOrderItem/AccordionOrderItem";
import FiltersListOfOrdersPage from "../../components/FiltersListOfOrdersPage/FiltersListOfOrdersPage";
import Pagination from "../../components/Pagination/Pagination";
import {useAppDispatch, useAppSelector} from "../../hooks/reduxHooks";
import {orderActions} from "../../store/slices/orderSlice";
import styles from "./PersonalOrdersPage.module.scss";

interface IPersonalOrdersPageProps {
}

const PersonalOrdersPage: FC<IPersonalOrdersPageProps> = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const {orders, totalCountOfOrders} = useAppSelector(state => state.order);

  const handleNavigateBack = () => {
    navigate(-1);
  }

  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth"
    });
  }, []);

  useEffect(() => {
    dispatch(orderActions.getByCurrentUser({page: currentPage}));
  }, [currentPage]);

  return (
    <div className={styles.personalOrdersPage}>
      <div className={styles.personalOrdersPage_title}>
        <img onClick={handleNavigateBack} src={chevron} alt="chevron"/>
        <h1>ІСТОРІЯ <span>ЗАМОВЛЕНЬ</span></h1>
      </div>

      <div className={styles.personalOrdersPage_tools}>
        <FiltersListOfOrdersPage/>
        {/*<ExportButton/>*/}
      </div>

      <div className={styles.personalOrdersPage_orders}>
        {orders.map((order, index) =>
          <AccordionOrderItem key={index} order={order} type={"personal"}/>)}
      </div>

      <div className={styles.personalOrdersPage_center}>
        <Pagination
          currentPage={currentPage}
          onPageChange={setCurrentPage}
          totalItems={totalCountOfOrders}
          position={"center"}
        />
      </div>

      <Toaster/>
    </div>
  );
};

export default PersonalOrdersPage;
