import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {AxiosError} from "axios";

import {endpoints} from "../../configs/endpoints";
import {orderingBy} from "../../constants/orderingBy";
import {IFilteringValues} from "../../interfaces/IFilteringValues";
import {IProduct} from "../../interfaces/IProduct";
import {IProductDetailed} from "../../interfaces/IProductDetailed";
import {IResApiPagination} from "../../interfaces/IResApiPagination";
import {instance} from "../../services/axios.service";
import {productsService} from "../../services/products.service";

interface IState {
  product: IProductDetailed | null; //detailed info about product by id
  status: "loading" | "success" | "error";
  recentlyViewed: IProduct[];
  filteringValues: IFilteringValues,
  filteredProducts: IProduct[];

  totalCountPromoProducts: number;
  promoProducts: IProduct[]; // for cart page
  allPromoProducts: IProduct[];

  totalCountFilteredProducts: number;
  searchValue: string;
  errorStatus: number | null;
}

const initialState: IState = {
  product: null,
  status: "success",
  recentlyViewed: [],
  filteringValues: {
    min_price: 0,
    max_price: 0,
    ordering: orderingBy[0]
  },
  filteredProducts: [],

  totalCountPromoProducts: 0,
  promoProducts: [],
  allPromoProducts: [],

  totalCountFilteredProducts: 0,
  searchValue: "",
  errorStatus: null,
}

const getById = createAsyncThunk<IProductDetailed, { id: number }>("productsSlice/getById",
  async ({id}, {rejectWithValue}) => {
    try {
      const {data} = await productsService.getById(id);

      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.status);
    }
  });

const getFiltered = createAsyncThunk<IResApiPagination<IProduct[]>, {
  search: string,
  ordering: string,
  page: number
}>("productsSlice/getFiltered",
  async ({search, page, ordering}, {rejectWithValue}) => {
    try {
      const {data} = await productsService.getFiltered(search, ordering, page);

      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  });

const getPromoProducts = createAsyncThunk<IResApiPagination<IProduct[]>, {
  queryParams: string;
  page: number;
}>("productsSlice/getPromoProducts",
  async ({queryParams, page}, {rejectWithValue}) => {
    try {
      const {data} = await instance.get<IResApiPagination<IProduct[]>>(`${endpoints.product.promo}?${queryParams}&page=${page}`);
      // const {count, results} = data;

      // if (count > 10) {
      //   const additionalRequests = Math.ceil(count / 10) - 1;
      //   const additionalProducts = [];
      //
      //   for (let i = 1; i <= additionalRequests; i++) {
      //     const {data} = await instance.get<IResApiPagination<IProduct[]>>(`${endpoints.product.promo}?${queryParams}&page=${i + 1}`);
      //     additionalProducts.push(...data.results);
      //   }
      //
      //   return [...results, ...additionalProducts];
      // }

      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  });

const getAllPromoProducts = createAsyncThunk<IResApiPagination<IProduct[]>>("productsSlice/getAllPromoProducts",
  async (_, {rejectWithValue}) => {
    try {
      const {data} = await productsService.getAllPromoProducts();

      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  });

const productsSlice = createSlice({
  name: "productsSlice",
  initialState,
  reducers: {
    addRecentlyViewedProduct: (state, action) => {
      const newProduct = action.payload;

      // if no product in "recently viewed products"
      if (state.recentlyViewed.findIndex((product) => product.pk === newProduct.pk) === -1) {
        if (state.recentlyViewed.length < 10) {
          state.recentlyViewed.unshift(newProduct); // add element to start of array
        } else {
          state.recentlyViewed.shift(); // delete first element of array
          state.recentlyViewed.unshift(newProduct); // add element to start of array
        }
      }
    },
    setFilteringValues: (state, action) => {
      state.filteringValues = action.payload;
    },
    clearFilteredProducts: (state) => {
      state.filteredProducts = [];
    },
    setSearchValue: (state, action) => {
      state.searchValue = action.payload;
    },
    reset: () => initialState
  },
  extraReducers: builder => builder
    .addCase(getById.fulfilled, (state, action) => {
      state.product = action.payload;
      state.status = "success";
    })
    .addCase(getById.pending, (state, action) => {
      state.status = "loading";

      // if there was an errorStatus, then we clear it
      if (state.errorStatus) {
        state.errorStatus = null;
      }
    })
    .addCase(getById.rejected, (state, action) => {
      state.errorStatus = action.payload as number;
      state.status = "error";
    })

    .addCase(getFiltered.fulfilled, (state, action) => {
      const {count, results} = action.payload;

      state.filteredProducts = results;
      state.totalCountFilteredProducts = count;
      state.status = "success";
    })
    .addCase(getFiltered.pending, (state, action) => {
      state.status = "loading";
    })

    .addCase(getPromoProducts.fulfilled, (state, action) => {
      if (!action.payload) {
        state.totalCountPromoProducts = 0;
        state.promoProducts = [];
        return;
      }

      const {results, count} = action.payload;
      state.promoProducts = results;
      state.totalCountPromoProducts = count;
      state.status = "success";
    })
    .addCase(getPromoProducts.pending, (state, action) => {
      state.status = "loading";
    })

    .addCase(getAllPromoProducts.fulfilled, (state, action) => {
      const {results} = action.payload;

      state.allPromoProducts = results;
      state.status = "success";
    })
    .addCase(getAllPromoProducts.pending, (state, action) => {
      state.status = "loading";
    })
});

const {reducer: productsReducer, actions} = productsSlice;

const productsActions = {
  ...actions,
  getById,
  getFiltered,
  getPromoProducts,
  getAllPromoProducts
}

export {
  productsActions,
  productsReducer,
}
