import CircularProgress from "@mui/material/CircularProgress";
import {AxiosError} from "axios";
import React, {FC, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

import MainButtonRedBackground from "../../components/MainButtonRedBackground/MainButtonRedBackground";
import MainButtonTransparentBackground
  from "../../components/MainButtonTransparentBackground/MainButtonTransparentBackground";
import {endpoints} from "../../configs/endpoints";
import {REGEX} from "../../constants/regex";
import {MAIN_ROUTES} from "../../routes/mainRoutes";
import {instance} from "../../services/axios.service";
import styles from "./PasswordRecoveryStep1Page.module.scss";

interface IPasswordRecoveryStep1PageProps {
}

const PasswordRecoveryStep1Page: FC<IPasswordRecoveryStep1PageProps> = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [isEmailSent, setIsEmailSent] = useState<boolean>(false);
  const [isTimerActive, setIsTimerActive] = useState<boolean>(false);
  const [timer, setTimer] = useState<number>(30);

  const navigate = useNavigate();

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setEmail(inputValue);

    if (!REGEX.EMAIL.test(inputValue)) {
      setError("Невалідний email");
    } else {
      setError("");
    }
  };

  const handleSendLink = async () => {
    try {
      setIsLoading(true);

      const res = await instance.post(endpoints.passwordRecovery.sendLink, {
        email,
        password_recovery_url: process.env.REACT_APP_APP_URL + MAIN_ROUTES.PASSWORD_RECOVERY_STEP_2
      });

      if (res.status === 200) {
        setIsEmailSent(true);
        setIsTimerActive(true);
        setTimer(30); // Reset the timer
      }
    } catch (e) {
      const error = e as AxiosError;
      console.error(error);
      setError(JSON.stringify(error?.response?.data || error?.message));
    } finally {
      setIsLoading(false);
    }
  }

  // timer for resend email(user can send only every 30 seconds)
  useEffect(() => {
    let interval: NodeJS.Timeout;

    if (isTimerActive) {
      interval = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer === 1) {
            clearInterval(interval);
            setIsTimerActive(false);
          }
          return prevTimer - 1;
        });
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [isTimerActive]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapper_recoveryPasswordPage}>
        <h1 className={styles.wrapper_recoveryPasswordPage_title}>ПРИЄДНУЙТЕСЬ У СВІТ <span>КРАСИ</span></h1>

        <div className={styles.wrapper_recoveryPasswordPage_content}>
          <div className={styles.wrapper_recoveryPasswordPage_content_rectangle}></div>
          <div className={styles.wrapper_recoveryPasswordPage_content_form}>
            <div>
              <h2>{isEmailSent ? "Перевірте свою пошту" : "Забули пароль?"}</h2>

              {isEmailSent ?
                <p>Ми надіслали лист на <span>{email}</span></p> :
                <p>Не хвилюйтеся, ми надішлемо Вам посилання, щоб скинути пароль</p>}
            </div>

            {isEmailSent ?
              <p>Не отримали листа?
                <span
                  style={{
                    cursor: isTimerActive ? "auto" : "pointer",
                    opacity: isTimerActive ? 0.5 : 1
                  }}
                  onClick={() => !isTimerActive && handleSendLink()}
                > Надіслати ще раз {isTimerActive && `${timer}c`}</span>
              </p> :
              <div className={styles.wrapper_recoveryPasswordPage_content_form_block}>
                <label
                  className={styles.wrapper_recoveryPasswordPage_content_form_block_label}
                  htmlFor="email"
                >
                    Email
                  <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={handleEmailChange}
                  />
                  {error && <span>{error}</span>}
                </label>
              </div>}

            <div className={styles.wrapper_recoveryPasswordPage_content_form_buttons}>
              <MainButtonTransparentBackground
                text={"Повернутись Назад"}
                width={"auto"}
                onClick={() => navigate(-1)}
                type={"button"}
              />
              {!isEmailSent &&
                  (isLoading ?
                    <CircularProgress style={{color: "#9B232C"}} disableShrink/> :
                    <MainButtonRedBackground
                      text={"Надіслати"}
                      width={"auto"}
                      onClick={handleSendLink}
                      type={"submit"}
                      isDisabled={(!!error || !email)}
                    />)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
;

export default PasswordRecoveryStep1Page;
