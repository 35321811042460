import "react-image-gallery/styles/scss/image-gallery.scss";

import React, {FC, MouseEventHandler, useEffect} from "react";
import ImageGallery from "react-image-gallery";

import slide1 from "../../assets/slide-1.png";
import slide2 from "../../assets/slide-2.png";
import slide3 from "../../assets/slide-3.png";
import slide4 from "../../assets/slide-4.png";
import slide5 from "../../assets/slide-5.png";
import slide6 from "../../assets/slide-6.png";
import slide7 from "../../assets/slide-7.png";
import slide8 from "../../assets/slide-8.png";
import slide9 from "../../assets/slide-9.png";
import slide10 from "../../assets/slide-10.png";
import MainButtonTransparentBackground
  from "../../components/MainButtonTransparentBackground/MainButtonTransparentBackground";
import styles from "./MarketingPlanPage.module.scss";

interface IMarketingPlanPageProps {
}

interface NavButtonProps {
  onClick: MouseEventHandler<HTMLElement>;
  disabled: boolean;
}

const LeftNavButton: FC<NavButtonProps> = ({onClick, disabled}) => {
  return (
    <button onClick={onClick} disabled={disabled}
      className={`${styles.leftBtn} ${styles.button} ${disabled ? styles.disabled : ""}`}></button>
  );
}

const RightNavButton: FC<NavButtonProps> = ({onClick, disabled}) => {
  return (
    <button onClick={onClick} disabled={disabled}
      className={`${styles.rightBtn} ${styles.button} ${disabled ? styles.disabled : ""}`}></button>
  );
}

const MarketingPlanPage: React.FC<IMarketingPlanPageProps> = () => {
  const images = [
    {
      original: slide1,
      thumbnail: slide1,
    },
    {
      original: slide2,
      thumbnail: slide2,
    },
    {
      original: slide3,
      thumbnail: slide3,
    },
    {
      original: slide4,
      thumbnail: slide4,
    },
    {
      original: slide5,
      thumbnail: slide5,
    },
    {
      original: slide6,
      thumbnail: slide6,
    },
    {
      original: slide7,
      thumbnail: slide7,
    },
    {
      original: slide8,
      thumbnail: slide8,
    },
    {
      original: slide9,
      thumbnail: slide9,
    },
    {
      original: slide10,
      thumbnail: slide10,
    },
  ];

  const handleDownloadFile: MouseEventHandler<HTMLAnchorElement> = () => {
    // using JavaScript method to get PDF file
    fetch("/marketing-plan.pdf").then((response) => {
      response.blob().then((blob) => {

        // Creating new object of PDF file
        const fileURL =
          window.URL.createObjectURL(blob);

        // Setting various property values
        let link = document.createElement("a");
        link.href = fileURL;
        link.download = "marketing-plan-life-stylle";
        link.click();
      });
    });
  };

  useEffect(() => {
    // scroll to the top
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.marketingPlanPage}>
        <h1 className={styles.marketingPlanPage_title}>МАРКЕТИНГОВИЙ <span>ПЛАН</span></h1>

        <div className={styles.marketingPlanPage_content}>
          <div className={styles.marketingPlanPage_content_plan}>
            <ImageGallery
              showFullscreenButton={false}
              showPlayButton={false}
              items={images}
              infinite={false}
              renderLeftNav={(onClick, disabled) =>
                <LeftNavButton onClick={onClick} disabled={disabled}/>}
              renderRightNav={(onClick, disabled) =>
                <RightNavButton onClick={onClick} disabled={disabled}/>}
            />
          </div>

          {/*<a href={require("/marketing-plan.pdf")} download="marketing-plan-life-stylle" target='_blank'>*/}
          <MainButtonTransparentBackground
            text={"Завантажити Маркетинговий План"}
            width={"auto"}
            onClick={handleDownloadFile}
            type={"button"}
          />
          {/*</a>*/}
        </div>
      </div>
    </div>
  );
};

export default MarketingPlanPage;
