import Joi from "joi";

import {REGEX} from "../constants/regex";

export const newDeliveryAddressValidator = Joi.object({
  delivery_method_name: Joi.string().required().messages({
    "string.empty": "Заповніть це поле"
  }),
  city: Joi.string().regex(REGEX.LATIN_ALPHABET).required().messages({
    "string.empty": "Заповніть це поле",
    "string.pattern.base": "Поле повинно містити тільки латинські символи",
  }),
  number_of_department: Joi.string().required().messages({
    "string.empty": "Заповніть це поле",
  })
});
