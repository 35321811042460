import React, {forwardRef} from "react";

import {IProduct} from "../../interfaces/IProduct";
import ProductsSwiper from "../ProductsSwiper/ProductsSwiper";
import styles from "./ProductsBlockContainer.module.scss";

interface IProductsBlockContainerProps {
  title: string;
  products: IProduct[];
}

const ProductsBlockContainer = forwardRef<HTMLDivElement, IProductsBlockContainerProps>(({
  title,
  products,
}, ref) => {
  return (
    <div ref={ref} className={styles.productsBlockContainer}>
      <h2 className={styles.productsBlockContainer_title}>{title}</h2>
      <ProductsSwiper products={products}/>
    </div>
  );
});

export default ProductsBlockContainer;
