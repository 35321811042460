import React, {FC, useState} from "react";
import {useNavigate} from "react-router-dom";

import logo from "../../assets/full-logo.svg";
import {MAIN_ROUTES} from "../../routes/mainRoutes";
import CustomModal from "../CustomModal/CustomModal";
import ModalPrivacyPolicy from "../ModalPrivacyPolicy/ModalPrivacyPolicy";
import styles from "./Footer.module.scss";

interface IFooterProps {
}

const Footer: FC<IFooterProps> = () => {
  const [isOpenPrivacyPolicy, setIsOpenPrivacyPolicy] = useState<boolean>(false);

  const navigate = useNavigate();

  const handleNavigate = (path: string) => {
    navigate(path);
  }

  return (
    <>
      {/*footer for desktop*/}
      <footer className={styles.footerDesktop}>
        <div className={styles.footerDesktop_blocks}>
          <img
            draggable={false}
            onClick={() => navigate(MAIN_ROUTES.HOME)}
            className={styles.footerDesktop_blocks_logo}
            src={logo}
            alt="life stylle logo"
          />
          <div className={styles.footerDesktop_blocks_listWrapper}>
            <h3 className={styles.footerDesktop_blocks_listWrapper_subtitle}>Компанія</h3>
            <ul className={styles.footerDesktop_blocks_listWrapper_lists}>
              <li
                className={styles.footerDesktop_blocks_listWrapper_lists_item}
                onClick={() => handleNavigate(MAIN_ROUTES.ABOUT_US)}
              >Про нас
              </li>
              <li
                onClick={() => handleNavigate(MAIN_ROUTES.MARKETING_PLAN)}
                className={styles.footerDesktop_blocks_listWrapper_lists_item}
              >Маркетинговий план
              </li>
              {/*<li className={styles.footerDesktop_blocks_listWrapper_lists_item}>Новини</li>*/}
              {/*<li*/}
              {/*    className={styles.footerDesktop_blocks_listWrapper_lists_item}*/}
              {/*    onClick={() => handleNavigate(MAIN_ROUTES.SUCCESS_STORIES)}*/}
              {/*>Історії успіху*/}
              {/*</li>*/}
            </ul>
          </div>

          <div className={styles.footerDesktop_blocks_listWrapper}>
            <h3 className={styles.footerDesktop_blocks_listWrapper_subtitle}>Life Stylle</h3>
            <ul className={styles.footerDesktop_blocks_listWrapper_lists}>
              {/*<li className={styles.footerDesktop_blocks_listWrapper_lists_item}>Каталог</li>*/}
              {/*<li className={styles.footerDesktop_blocks_listWrapper_lists_item}>Для дому</li>*/}
              {/*<li className={styles.footerDesktop_blocks_listWrapper_lists_item}>Косметика</li>*/}
              {/*<li*/}
              {/*    className={styles.footerDesktop_blocks_listWrapper_lists_item}*/}
              {/*    onClick={() => handleNavigate(MAIN_ROUTES.BLOG)}*/}
              {/*>Блог*/}
              {/*</li>*/}
              <li
                className={styles.footerDesktop_blocks_listWrapper_lists_item}
                onClick={() => setIsOpenPrivacyPolicy(true)}
              >Договір оферти
              </li>
            </ul>
          </div>
          <div className={styles.footerDesktop_blocks_listWrapper}>
            <h3 className={styles.footerDesktop_blocks_listWrapper_subtitle}>Інформація</h3>
            <ul className={styles.footerDesktop_blocks_listWrapper_lists}>
              {/*<li className={styles.footerDesktop_blocks_listWrapper_lists_item}>Мапа сайту</li>*/}
              <li
                className={styles.footerDesktop_blocks_listWrapper_lists_item}
                onClick={() => handleNavigate(MAIN_ROUTES.CONTACTS)}
              >Контакти
              </li>
              {/*<li*/}
              {/*    className={styles.footerDesktop_blocks_listWrapper_lists_item}*/}
              {/*    onClick={() => handleNavigate(MAIN_ROUTES.CERTIFICATES)}*/}
              {/*>Сертифікати*/}
              {/*</li>*/}
              {/*<li className={styles.footerDesktop_blocks_listWrapper_lists_item}>Географія</li>*/}
            </ul>
          </div>
        </div>

        <div className={styles.footerDesktop_divider}></div>

        <div className={styles.footerDesktop_bottom}>
          <p className={styles.footerDesktop_bottom_rights}>© {new Date().getFullYear()} I Life Stylle. All
            rights
            reserved.</p>
          {/*<div className={styles.footerDesktop_bottom_social}>*/}
          {/*    <button><img src={instagram} alt="instagram logo" draggable={false}/></button>*/}
          {/*    <button><img src={facebook} alt="facebook logo" draggable={false}/></button>*/}
          {/*    <button><img src={telegram} alt="telegram logo" draggable={false}/></button>*/}
          {/*</div>*/}
        </div>


        {isOpenPrivacyPolicy &&
          <CustomModal
            isOpen={isOpenPrivacyPolicy}
            onClose={() => setIsOpenPrivacyPolicy(false)}
            component={() => <ModalPrivacyPolicy
              type={"preview"}
              handleClose={() => setIsOpenPrivacyPolicy(false)}
            />}
          />}
      </footer>

      {/*footer for mobile*/}
      <footer className={styles.footerMobile}>
        <img className={styles.footerMobile_logo} src={logo} alt="logo" draggable={false}/>

        <div className={styles.footerMobile_blocks}>
          <div className={styles.footerMobile_blocks_listWrapper}>
            <h3 className={styles.footerMobile_blocks_listWrapper_subtitle}>Компанія</h3>
            <ul className={styles.footerMobile_blocks_listWrapper_lists}>
              <li
                className={styles.footerMobile_blocks_listWrapper_lists_item}
                onClick={() => handleNavigate(MAIN_ROUTES.ABOUT_US)}
              >Про нас
              </li>
              <li
                onClick={() => handleNavigate(MAIN_ROUTES.MARKETING_PLAN)}
                className={styles.footerMobile_blocks_listWrapper_lists_item}
              > Маркетинговий план
              </li>
              {/*<li className={styles.footerMobile_blocks_listWrapper_lists_item}>Новини</li>*/}
              {/*<li*/}
              {/*    className={styles.footerMobile_blocks_listWrapper_lists_item}*/}
              {/*    onClick={() => handleNavigate(MAIN_ROUTES.SUCCESS_STORIES)}*/}
              {/*>Історії успіху*/}
              {/*</li>*/}
            </ul>
          </div>
          <div className={styles.footerMobile_blocks_listWrapper}>
            <h3 className={styles.footerMobile_blocks_listWrapper_subtitle}>Life Stylle</h3>
            <ul className={styles.footerMobile_blocks_listWrapper_lists}>
              {/*<li className={styles.footerMobile_blocks_listWrapper_lists_item}>Каталог</li>*/}
              {/*<li className={styles.footerMobile_blocks_listWrapper_lists_item}>Для дому</li>*/}
              {/*<li className={styles.footerMobile_blocks_listWrapper_lists_item}>Косметика</li>*/}
              {/*<li*/}
              {/*    className={styles.footerMobile_blocks_listWrapper_lists_item}*/}
              {/*    onClick={() => handleNavigate(MAIN_ROUTES.BLOG)}*/}
              {/*>Блог*/}
              {/*</li>*/}
              <li
                className={styles.footerMobile_blocks_listWrapper_lists_item}
                onClick={() => setIsOpenPrivacyPolicy(true)}
              >Договір оферти
              </li>
            </ul>
          </div>
          <div className={styles.footerMobile_blocks_listWrapper}>
            <h3 className={styles.footerMobile_blocks_listWrapper_subtitle}>Інформація</h3>
            <ul className={styles.footerMobile_blocks_listWrapper_lists}>
              {/*<li className={styles.footerMobile_blocks_listWrapper_lists_item}>Мапа сайту</li>*/}
              <li
                className={styles.footerMobile_blocks_listWrapper_lists_item}
                onClick={() => handleNavigate(MAIN_ROUTES.CONTACTS)}
              >Контакти
              </li>
              {/*<li*/}
              {/*    className={styles.footerMobile_blocks_listWrapper_lists_item}*/}
              {/*    onClick={() => handleNavigate(MAIN_ROUTES.CERTIFICATES)}*/}
              {/*>Сертифікати*/}
              {/*</li>*/}
              {/*<li className={styles.footerMobile_blocks_listWrapper_lists_item}>Географія</li>*/}
            </ul>
          </div>
          {/*<div className={styles.footerMobile_blocks_listWrapper}>*/}
          {/*    <h3 className={styles.footerMobile_blocks_listWrapper_subtitle}>Соціальні Мережі</h3>*/}
          {/*    <ul className={styles.footerMobile_blocks_listWrapper_lists}>*/}
          {/*        <li className={styles.footerMobile_blocks_listWrapper_lists_item}>Інстаграм</li>*/}
          {/*        <li className={styles.footerMobile_blocks_listWrapper_lists_item}>Фейсбук</li>*/}
          {/*        <li className={styles.footerMobile_blocks_listWrapper_lists_item}>Телеграм</li>*/}
          {/*        <li className={styles.footerMobile_blocks_listWrapper_lists_item}>Ютуб</li>*/}
          {/*    </ul>*/}
          {/*</div>*/}
        </div>

        <div className={styles.footerMobile_divider}></div>

        <p className={styles.footerMobile_rights}>© {new Date().getFullYear()} I Life Stylle. All rights
          reserved.</p>

        {isOpenPrivacyPolicy &&
          <CustomModal
            isOpen={isOpenPrivacyPolicy}
            onClose={() => setIsOpenPrivacyPolicy(false)}
            component={() => <ModalPrivacyPolicy
              type={"preview"}
              handleClose={() => setIsOpenPrivacyPolicy(false)}
            />}
          />}
      </footer>
    </>
  );
};

export default Footer;
