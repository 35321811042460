import React, {FC} from "react";
import {useNavigate} from "react-router-dom";

import {useAppSelector} from "../../hooks/reduxHooks";
import {MAIN_ROUTES} from "../../routes/mainRoutes";
import MainButtonRedBackground from "../MainButtonRedBackground/MainButtonRedBackground";
import styles from "./WithdrawalPoints.module.scss";

interface IWithdrawalPointsProps {
}

const WithdrawalPoints: FC<IWithdrawalPointsProps> = () => {
  const {user} = useAppSelector(state => state.user);
  const {current_available_balance} = useAppSelector(state => state.payment);

  const navigate = useNavigate();

  if (user) {
    const {
      current_gift_balance,
      current_balance,
    } = user!;

    return (
      <div className={styles.withdrawalPoints}>
        <p className={styles.title}>Баланс</p>

        <div className={styles.withdrawalPoints_divider}></div>

        <div className={styles.withdrawalPoints_block}>
          <p className={styles.withdrawalPoints_block_subtitle}>Подарунковий Баланс</p>
          <p className={styles.withdrawalPoints_block_count}>{current_gift_balance.toFixed(2)}</p>
        </div>

        <div
          style={{margin: "16px 0 8px"}}
          className={styles.withdrawalPoints_divider}
        ></div>

        <div className={styles.withdrawalPoints_points}>
          <div className={styles.withdrawalPoints_block}>
            <p className={styles.withdrawalPoints_block_subtitle}>Розрахунковий Баланс</p>
            <p className={styles.withdrawalPoints_block_count}>{current_balance.toFixed(2)}</p>
          </div>
          <div className={styles.withdrawalPoints_block}>
            <p className={styles.withdrawalPoints_block_subtitle}>Доступно для виведення</p>
            <p className={styles.withdrawalPoints_block_count}>{current_available_balance}</p>
          </div>
        </div>

        <MainButtonRedBackground
          text={"Вивести Бали"}
          width={"100%"}
          onClick={() => navigate(MAIN_ROUTES.WITHDRAWAL_REQUESTS)}
          type={"button"}
          isDisabled={false}
        />
      </div>
    );
  }

  return null;
};

export default WithdrawalPoints;
