import {AxiosError} from "axios";
import React, {FC} from "react";
import toast from "react-hot-toast";

import {useAppDispatch} from "../../hooks/reduxHooks";
import {IDeliveryAddress} from "../../interfaces/IDeliveryAddress";
import {deliveryService} from "../../services/delivery.service";
import {deliveryActions} from "../../store/slices/deliverySlice";
import styles from "./DeliveryAddressItem.module.scss";

interface IDeliveryAddressItemProps {
  type: "view" | "delete" | "select";
  address: IDeliveryAddress;
}

const DeliveryAddressItem: FC<IDeliveryAddressItemProps> = ({type, address}) => {
  const {delivery_method_name, city, number_of_department, delivery_supplier_name, pk} = address;

  const dispatch = useAppDispatch();

  const handleDeleteDeliveryMethod = async () => {
    try {
      await deliveryService.deleteById(pk!); // it indicates that pk not undefined

      // if request was successful - delete delivery method from redux store
      dispatch(deliveryActions.deleteDeliveryAddressById(pk));

      toast.success("Адресу успішно видалено!");
    } catch (e) {
      console.error(e);
      const error = e as AxiosError;
      toast.error(JSON.stringify(error?.response?.data || error?.message));
    }
  }

  return (
    <div className={styles.deliveryAddress}>
      <div className={styles.deliveryAddress_title}>
        <p className={styles.deliveryAddress_title_name}>{delivery_method_name}</p>
        {type === "delete" &&
          <p onClick={handleDeleteDeliveryMethod}
            className={styles.deliveryAddress_title_delete}>Видалити</p>}
      </div>
      <div className={styles.deliveryAddress_block}>
        <p className={styles.deliveryAddress_block_subtitle}>Місто</p>
        <p className={`${styles.deliveryAddress_block_data} ${styles.city}`}>{city}</p>
      </div>
      <div className={styles.deliveryAddress_block}>
        <p className={styles.deliveryAddress_block_subtitle}>Спосіб Доставки</p>
        <p className={styles.deliveryAddress_block_data}>{delivery_supplier_name}</p>
      </div>

      {number_of_department &&
        <div className={styles.deliveryAddress_block}>
          <p
            className={styles.deliveryAddress_block_subtitle}>{delivery_supplier_name === "Нова пошта" ? "Відділення Пошти" : "Поштовий Індекс"}</p>
          <p className={styles.deliveryAddress_block_data}>{number_of_department}</p>
        </div>}
    </div>
  );
};

export default DeliveryAddressItem;
