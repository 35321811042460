import React, {FC, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

import chevron from "../../assets/chevron-left.svg";
import ReferralStructureListPage from "../ReferralStructureListPage/ReferralStructureListPage";
import ReferralStructureTreePage from "../ReferralStructureTreePage/ReferralStructureTreePage";
import styles from "./ReferralsStructurePage.module.scss";

interface IReferralsStructurePageProps {
}

const ReferralsStructurePage: FC<IReferralsStructurePageProps> = () => {
  const [activeToggle, setActiveToggle] = useState<"list" | "tree">("list");

  const navigate = useNavigate();

  const handleNavigateBack = () => {
    navigate(-1);
  }

  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth"
    });
  }, []);

  return (
    <div className={styles.referralsPage}>
      <div className={styles.referralsPage_title}>
        <img onClick={handleNavigateBack} src={chevron} alt="chevron"/>
        <h1 className={styles.referralsPage_title}>МОЯ <span>СТРУКТУРА</span></h1>
      </div>

      {activeToggle === "list" ?
        <ReferralStructureListPage activeToggle={activeToggle} setActiveToggle={setActiveToggle}/> :
        <ReferralStructureTreePage activeToggle={activeToggle} setActiveToggle={setActiveToggle}/>}
    </div>
  );
};

export default ReferralsStructurePage;
