import axios, {AxiosError, AxiosResponse} from "axios";

import {baseURL} from "../configs/urls";

type IRes<DATA> = Promise<AxiosResponse<DATA>>

export type {
  IRes,
}

export const instance = axios.create({baseURL});

// Set auth token by default
instance.interceptors.request.use(
  config => {
    const tokens = localStorage.getItem("tokens");

    if (tokens) {
      const parsedTokens = JSON.parse(tokens);

      if (parsedTokens?.access) {
        config.headers["Authorization"] = `JWT ${parsedTokens.access}`;
      }
    }
    return config;
  },
  (error: AxiosError) => {
    console.error(error);
  }
);

// Error handler for 401 status code(unauthorized user)
instance.interceptors.response.use(
  response => {
    return response;
  },
  (error: AxiosError) => {
    console.error(error);
    if (error?.response?.status === 401) {
      const tokens = localStorage.getItem("tokens");

      if (tokens) {
        localStorage.clear();
        window.location.reload();
      }
    }

    return Promise.reject(error);
  }
);
