import React, {FC, useState} from "react";

import close from "../../assets/close-white.svg";
import edit from "../../assets/edit.svg";
import {useAppSelector} from "../../hooks/reduxHooks";
import CustomModal from "../CustomModal/CustomModal";
import DeliveryAddressItem from "../DeliveryAddressItem/DeliveryAddressItem";
import ModalNewDeliveryAddress from "../ModalNewDeliveryAddress/ModalNewDeliveryAddress";
import styles from "./DeliveryAddresses.module.scss";

interface IDeliveryAddressesProps {
  type: "view" | "delete" | "select";
  title: string;
  handleClose?: () => void; // close modal
}

const DeliveryAddresses: FC<IDeliveryAddressesProps> = ({type, title, handleClose}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const {deliveryAddresses} = useAppSelector(state => state.delivery);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  }

  return (
    <div className={`${styles.deliveryAddresses} ${styles[type]}`}>
      <div className={styles.deliveryAddresses_title}>
        <h4 className={styles.deliveryAddresses_title_name}>{title}</h4>
        {type === "view" && <img src={edit} alt={"edit"} onClick={handleOpenModal} draggable={false}/>}
        {type !== "view" && <img src={close} alt={"close"} onClick={handleClose} draggable={false}/>}
      </div>

      {type === "view" && <div className={styles.deliveryAddresses_divider}></div>}

      {deliveryAddresses.map((address, index) =>
        <DeliveryAddressItem key={index} type={type} address={address}/>)}

      {isModalOpen &&
        <CustomModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          component={ModalNewDeliveryAddress}
        />}
    </div>
  );
};

export default DeliveryAddresses;
