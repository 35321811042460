import React, {FC} from "react";

import styles from "./TextSpinnerCircle.module.scss";

interface ITextSpinnerCircleProps {
    imageUrl: string;
}

const TextSpinnerCircle: FC<ITextSpinnerCircleProps> = ({imageUrl}) => {
  return (
    <div className={styles.text}>
      <img src={imageUrl} alt="" draggable={false}/>
    </div>
  );
};

export default TextSpinnerCircle;
