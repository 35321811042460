import React from "react";

import {useAppSelector} from "../../hooks/reduxHooks";
import styles from "./HistoryWithdrawalPoints.module.scss";

interface IHistoryWithdrawalPointsProps {
}

const HistoryWithdrawalPoints: React.FC<IHistoryWithdrawalPointsProps> = () => {
  const {history} = useAppSelector(state => state.payment);

  return (
    <div className={styles.historyWithdrawalPoints}>
      <h4 className={styles.title}>Історія Виведення</h4>
      <div className={styles.historyWithdrawalPoints_divider}></div>

      <ul className={styles.historyWithdrawalPoints_list}>
        {history.length === 0 ?
          <li className={styles.historyWithdrawalPoints_list_item}>У вас поки немає заявок на виведення</li> :
          history.map((request, index) =>
            <li key={index} className={styles.historyWithdrawalPoints_list_item}>
              <span>{new Date(request.created_at).toLocaleDateString()}</span>
              <span>{request.amount} ББ</span>
            </li>)}
      </ul>
    </div>
  );
};

export default HistoryWithdrawalPoints;
