import {createSlice, PayloadAction} from "@reduxjs/toolkit";

import {IProductCart} from "../../interfaces/IProductCart";

interface IState {
  totalPrice: number;
  discountPrice: number;
  totalPoints: number;
  productsIds: number[];
  products: IProductCart[];
}

const initialState: IState = {
  totalPrice: 0,
  discountPrice: 0,
  totalPoints: 0,
  productsIds: [],
  products: [],
}

const cartSlice = createSlice({
  name: "cartSlice",
  initialState,
  reducers: {
    addProductId: (state, action) => {
      const id = action.payload;

      // checking is the product in the shopping cart
      const isProductInCart = state.productsIds.some(productId => productId === id);
      if (!isProductInCart) {
        state.productsIds.push(id);
      }
    },
    addProductDescription: (state, action) => {
      const newProduct = action.payload;

      if (state.products.findIndex((product) => product.pk === newProduct.pk) === -1) {
        state.products = [...state.products, newProduct];
      }
    },
    deleteProductById: (state, action) => {
      const id = action.payload;
      const products = state.productsIds.filter((productId) => productId !== id);
      state.productsIds = [...products];

      if (products.length === 0) {
        state.totalPrice = 0;
        state.discountPrice = 0;
        state.totalPoints = 0;
      }
    },
    calculateOrderSummary: (state, action: PayloadAction<{ isUser: boolean }>) => {
      let totalPrice = 0;
      let totalPoints = 0;
      let discountPrice = 0;

      const {isUser} = action.payload;

      if (isUser) {
        state.products.forEach(product => {
          const {price, discount_percent, points_to_count, count} = product;

          const discountedPrice = Number(price) - (Number(price) * (Number(discount_percent) / 100));

          totalPrice += Number(price) * count;
          discountPrice += (Number(price) - discountedPrice) * count;
          totalPoints += points_to_count * count;
        });
      } else {
        state.products.forEach(product => {
          const {price, points_to_count, count} = product;


          totalPrice += Number(price) * count;
          discountPrice += 0;
          totalPoints += points_to_count * count;
        });
      }

      state.totalPrice = Number(totalPrice.toFixed(2));
      if (totalPrice > 0) {
        state.totalPrice = state.totalPrice + 15;
      }
      state.discountPrice = Number(discountPrice.toFixed(2));
      state.totalPoints = Number(totalPoints.toFixed(2));
    },
    deleteAllProductsDescription: (state) => {
      state.products = [];
    },
    changeCountOfProductById: (state, action) => {
      const {pk, count, isUser} = action.payload;

      const productIndex = state.products.findIndex((product) => product.pk === pk);
      if (productIndex !== -1) {
        state.products[productIndex] = {...state.products[productIndex], count};
      }

      cartSlice.caseReducers.calculateOrderSummary(state, {
        payload: {isUser},
        type: "CHANGE_COUNT_OF_PRODUCT_BY_ID"
      }); // calculate total points, total price, total discount price
    },
    resetCart: () => initialState,
  },
})
;

const {reducer: cartReducer, actions} = cartSlice;

const cartActions = {
  ...actions,
}

export {
  cartActions,
  cartReducer,
}
