import React, {FC} from "react";
import {useNavigate} from "react-router-dom";

import favourite from "../../assets/favourite-white.svg";
import {MAIN_ROUTES} from "../../routes/mainRoutes";
import styles from "./FavouriteDetails.module.scss";

interface IFavouriteDetailsProps {
}

const FavouriteDetails: FC<IFavouriteDetailsProps> = () => {
  const navigate = useNavigate();

  return (
    <div
      className={styles.favourite}
      onClick={() => navigate(MAIN_ROUTES.FAVOURITES)}
    >
      <button className={styles.favourite_button}>
        <img src={favourite} alt="favourite" draggable={false}/>
      </button>
      <p className={styles.favourite_label}>Улюблене</p>
    </div>
  );
};

export default FavouriteDetails;
