import React, {FC} from "react";

import styles from "./ReportStatistics.module.scss";

interface IReportStatisticsProps {
}

const ReportStatistics: FC<IReportStatisticsProps> = () => {
  return (
    <div className={styles.reportStatistics}>
      <div className={styles.reportStatistics_titles}>
        <div className={styles.reportStatistics_titles_item}>
          <p className={styles.reportStatistics_titles_item_subtitle}>Період</p>
          <p className={styles.reportStatistics_titles_item_data}>01.10.23 - 01.12.23</p>
        </div>
        <div className={styles.reportStatistics_titles_divider}></div>
        <div className={styles.reportStatistics_titles_item}>
          <p className={styles.reportStatistics_titles_item_subtitle}>Статус</p>
          <p className={styles.reportStatistics_titles_item_data}>3 ранг</p>
        </div>
        <div className={styles.reportStatistics_titles_divider}></div>
        <div className={styles.reportStatistics_titles_item}>
          <p className={styles.reportStatistics_titles_item_subtitle}>ОО, ББ</p>
          <p className={styles.reportStatistics_titles_item_data}>168</p>
        </div>
        <div className={styles.reportStatistics_titles_divider}></div>
        <div className={styles.reportStatistics_titles_item}>
          <p className={styles.reportStatistics_titles_item_subtitle}>ГО, ББ</p>
          <p className={styles.reportStatistics_titles_item_data}>300</p>
        </div>
        <div className={styles.reportStatistics_titles_divider}></div>
        <div className={styles.reportStatistics_titles_item}>
          <p className={styles.reportStatistics_titles_item_subtitle}>Загальний оборот, ББ</p>
          <p className={styles.reportStatistics_titles_item_data}>468</p>
        </div>
      </div>
    </div>
  );
};

export default ReportStatistics;
