import {joiResolver} from "@hookform/resolvers/joi";
import {AxiosError} from "axios";
import React, {FC, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import toast, {Toaster} from "react-hot-toast";
import {useNavigate} from "react-router-dom";

import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import MyOrderPrice from "../../components/MyOrderPrice/MyOrderPrice";
import MyOrderPriceGiftBonus from "../../components/MyOrderPriceGiftBonus/MyOrderPriceGiftBonus";
import PersonalOrderForm from "../../components/PersonalOrderForm/PersonalOrderForm";
import IsAuth from "../../HOC/IsAuth";
import {useAppDispatch, useAppSelector} from "../../hooks/reduxHooks";
import {IOrder} from "../../interfaces/IOrder";
import {IRecipientData} from "../../interfaces/IRecipientData";
import {MAIN_ROUTES} from "../../routes/mainRoutes";
import {orderService} from "../../services/order.service";
import {cartActions} from "../../store/slices/cartSlice";
import {personalOrderFormValidator} from "../../validators/personalOrderForm.validator";
import styles from "./ConfirmOrderPage.module.scss";

interface IConfirmOrderPageProps {
}

const ConfirmOrderPage: FC<IConfirmOrderPageProps> = () => {
  const [tab, setTab] = useState<number>(1); //Для себе або Для партнера
  // const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    formState: {errors},
    setValue,
    watch,
  } = useForm<IRecipientData>({
    mode: "all",
    resolver: joiResolver(personalOrderFormValidator)
  });

  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const {deliveryAddresses, deliveryAddress} = useAppSelector(state => state.delivery);
  const {user} = useAppSelector(state => state.user);
  const {productsIds, products} = useAppSelector(state => state.cart);
  const {first_name, last_name, email, mobile_nummer} = user!;

  useEffect(() => {
    setValue("receiver_first_name", first_name);
    setValue("receiver_last_name", last_name);
    setValue("receiver_mobile", mobile_nummer);
    setValue("receiver_email", email);

    // scroll to the top
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });

    return () => {
      toast.remove("cart");
    }
  }, []);

  const mobileNumber = watch("receiver_mobile");

  const handleCreateOrder = async (data: IRecipientData) => {
    // creates an array where each element is the 'pk' value repeated 'count' times
    const pkCounts = products.flatMap(obj => Array.from({length: obj.count}, () => obj.pk));
    const canPayWithBonus = products.every(product => product.can_pay_with_bonus);

    const order: IOrder = {
      products: [...pkCounts, 16], // ex. [1, 1, 2, 3]
      delivery_method: {...deliveryAddress!, ...data},
      can_pay_with_bonus: canPayWithBonus
    };

    try {
      const {data} = await orderService.create(order);
      window.location.href = data.url;

      // check if the tab is active
      if (document.visibilityState === "visible") {
        navigate(MAIN_ROUTES.MY_ORDERS);
      }

      dispatch(cartActions.resetCart());
    } catch (e) {
      console.error(e);
      const error = e as AxiosError;
      toast.error(JSON.stringify(error?.response?.data || error?.message));
    }
  };

  const canPayWithBonus = products.every(product => product.can_pay_with_bonus);
  const allProductsCanPayWithBonus = products.every(product => product.can_pay_with_bonus === products[0].can_pay_with_bonus);

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapper_confirmOrderPage}>
        <h1 className={styles.wrapper_confirmOrderPage_title}>ОФОРМЛЕННЯ <span>ЗАМОВЛЕННЯ</span></h1>

        <Breadcrumbs/>

        <ul className={styles.wrapper_confirmOrderPage_tabs}>
          <li
            className={`${styles.wrapper_confirmOrderPage_tabs_item} ${tab === 1 ? styles.active : ""}`}
            onClick={() => setTab(1)}
          >Для себе
          </li>
          {/*<li*/}
          {/*    className={`${styles.wrapper_confirmOrderPage_tabs_item} ${tab === 2 ? styles.active : ""}`}*/}
          {/*    onClick={() => setTab(2)}*/}
          {/*>Для партнера*/}
          {/*</li>*/}
        </ul>

        <div className={styles.wrapper_confirmOrderPage_row}>
          <PersonalOrderForm
            register={register}
            errors={errors}
            mobileNumber={mobileNumber}
            setValue={setValue}
          />

          {canPayWithBonus ?
            <MyOrderPriceGiftBonus
              onClick={handleSubmit(handleCreateOrder)}
              step={"order"}
              isDisabled={(deliveryAddresses.length === 0) || (Object.values(errors).length > 0) || (productsIds.length === 0) || !allProductsCanPayWithBonus}
            /> :
            <MyOrderPrice
              onClick={handleSubmit(handleCreateOrder)}
              step={"order"}
              isDisabled={(deliveryAddresses.length === 0) || (Object.values(errors).length > 0) || (productsIds.length === 0) || !allProductsCanPayWithBonus}
            />}
          {/*<CustomModal*/}
          {/*    isOpen={isModalOpen}*/}
          {/*    onClose={() => setIsModalOpen(false)}*/}
          {/*    component={() => <ModalPrivacyPolicy*/}
          {/*        handleCreateOrder={handleSubmit(handleCreateOrder)}*/}
          {/*        handleClose={() => setIsModalOpen(false)}*/}
          {/*        type={"create-order"}*/}
          {/*    />}*/}
          {/*/>*/}
        </div>
      </div>

      <Toaster/>
    </div>
  );
};

export default IsAuth(ConfirmOrderPage);
