import React, {FC, useEffect, useState} from "react";

import blogDetailed from "../../assets/blog-detailed.svg";
import BlogItem from "../../components/BlogItem/BlogItem";
import MainButtonTransparentBackground
  from "../../components/MainButtonTransparentBackground/MainButtonTransparentBackground";
import {handleTruncateText} from "../../helpers/truncateText";
import styles from "./BlogPage.module.scss";

interface IBlogPageProps {
}

const BlogPage: FC<IBlogPageProps> = () => {
  const [deviceWidth, setDeviceWidth] = useState<number>(window.innerWidth);
  const [activeCategory, setActiveCategory] = useState<string>("Усі статті");

  const handleResize = () => {
    setDeviceWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    // scroll to the top
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapper_blogPage}>
        <div className={styles.wrapper_blogPage_top}>
          <h1 className={styles.wrapper_blogPage_top_title}>ДІЛИМОСЬ ІДЕЯМИ <span>НАДИХАЄМО</span></h1>

          <div className={styles.wrapper_blogPage_top_lastPublished}>
            <img src={blogDetailed} alt=""/>

            <div className={styles.wrapper_blogPage_top_lastPublished_description}>
              <p className={styles.date}>Грудень 22, 2023</p>
              <h3 className={styles.title}>Осінній догляд за тілом і обличчям: Підбірка доглядових засобів
                від
                Life stylle</h3>
              <p
                className={styles.content}>{handleTruncateText("Які засоби і склади дієві в осінній період? На що варто звернути увагу при оновленні свого догляду в холодну пору.\n" +
                "Осінь - час змін, і це стосується не лише природи, але і нашої шкіри. Зміна погодних умов і температур впливає на неї, роблячи її більш схильною до сухості та подразнень. Тому осінь - ідеальний час для оновлення засобів по догляду за тілом і обличчям. У цій статті ти дізнаєшся: як підібрати осінні доглядові засоби та які діючі речовини стануть найкращими помічниками для твоєї шкіри.", 200)}</p>

              <div className={styles.button}>
                <MainButtonTransparentBackground
                  text={"Читати Більше"}
                  width={deviceWidth > 425 ? "auto" : "100%"}
                  onClick={null}
                  type={"button"}
                />
              </div>
            </div>
          </div>
        </div>

        <div className={styles.wrapper_blogPage_divider}></div>

        <div className={styles.wrapper_blogPage_blogs}>
          <div className={styles.wrapper_blogPage_blogs_categories}>
            <button
              onClick={() => setActiveCategory("Усі статті")}
              className={`${styles.wrapper_blogPage_blogs_categories_button} ${activeCategory === "Усі статті" ? styles.active : ""}`}
            >Усі статті
            </button>
            <button
              onClick={() => setActiveCategory("Турбота")}
              className={`${styles.wrapper_blogPage_blogs_categories_button} ${activeCategory === "Турбота" ? styles.active : ""}`}
            >Турбота
            </button>
          </div>

          <div className={styles.wrapper_blogPage_blogs_cards}>
            <BlogItem/>
            <BlogItem/>
            <BlogItem/>
            <BlogItem/>
            <BlogItem/>
            <BlogItem/>
            <BlogItem/>
            <BlogItem/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogPage;
