import {endpoints} from "../configs/endpoints";
import {IProduct} from "../interfaces/IProduct";
import {IResApiPagination} from "../interfaces/IResApiPagination";
import {instance, IRes} from "./axios.service";

export const productsService = {
  getAll: (): IRes<IResApiPagination<IProduct[]>> => {
    const res = instance.get(endpoints.product.base);

    return res;
  },
  getById: (id: number) => {
    const res = instance.get(`${endpoints.product.base}${id}/`);

    return res;
  },
  getByCategory: (category: string, ordering: string, can_pay_with_bonus: boolean = false, page: number): IRes<IResApiPagination<IProduct[]>> => {
    const res = instance.get(endpoints.product.base, {
      params: {
        category,
        ordering,
        page,
        can_pay_with_bonus: can_pay_with_bonus || null
      }
    });

    return res;
  },
  getFiltered: (search: string, ordering: string, page: number) => {
    const res = instance.get(endpoints.product.base, {
      params: {
        search,
        ordering,
        page
      }
    });

    return res;
  },
  getAllPromoProducts: () => {
    const res = instance.get(endpoints.product.allPromo);

    return res;
  }
}
