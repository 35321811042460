import React, {FC} from "react";

import chevron from "../../assets/chevron-right-red.svg";
import eventIcon from "../../assets/events-calendar.svg";
import styles from "./EventsCalendarItem.module.scss";

interface IEventsCalendarItemProps {
}

const EventsCalendarItem: FC<IEventsCalendarItemProps> = () => {
  return (
    <div className={styles.event}>
      <img src={eventIcon} alt="event"/>

      <div className={styles.event_details}>
        <div className={styles.event_details_gap8}>
          <h3>Презентація нового продукту</h3>

          <div className={styles.event_details_gap8_address}>
            <p>м. Львів</p>
            <p>24. 12. 2023</p>
          </div>

          <p>Запрошуємо вас на захоплюючу подію, яка переверне уявлення про красу! Lifestyll з радістю анонсує
            презентацію свого нового продукту, який обіцяє стати визначальним кроком у світі догляду за
            собою.</p>
        </div>

        <button className={styles.event_details_button}>
          <img src={chevron} alt="chevron"/>
          <span>Приєднатись до події</span>
        </button>
      </div>
    </div>
  );
};

export default EventsCalendarItem;
