import "swiper/css";
import "swiper/css/pagination";
import "../../utils/сustomCursor.scss";

import React, {FC, useEffect, useState} from "react";
import {Autoplay} from "swiper/modules";
import {Swiper, SwiperSlide} from "swiper/react";

import {IProduct} from "../../interfaces/IProduct";
import ProductCard from "../ProductCard/ProductCard";

interface IProductsSwiperProps {
  products: IProduct[];
}

const ProductsSwiper: FC<IProductsSwiperProps> = ({products}) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  let slidesPerView;
  switch (true) {
    case (1280 < windowWidth && windowWidth < 1440):
      slidesPerView = windowWidth / 360;
      break;
    case (1024 < windowWidth && windowWidth <= 1280):
      slidesPerView = windowWidth / 365;
      break;
    case (768 < windowWidth && windowWidth <= 1024):
      slidesPerView = windowWidth / 380;
      break;
    case (425 < windowWidth && windowWidth <= 768):
      slidesPerView = windowWidth / 355;
      break;
    case windowWidth <= 425:
      slidesPerView = windowWidth / 340;
      break;
    default:
      slidesPerView = 1440 / 360;
      break;
  }

  return (
    <Swiper
      style={{width: "100%", height: "100%"}}
      // style={{width: '100%', height: '100%', overflow: 'visible'}}
      autoplay={{
        delay: 5000,
        disableOnInteraction: false,
      }}
      slidesPerView={slidesPerView}
      spaceBetween={windowWidth < 425 ? 16 : 32}
      // pagination={{
      //     clickable: true,
      // }}
      modules={[Autoplay]}
      // modules={[Autoplay, Pagination]}
      className="mySwiper"
    >
      {products.map((product, index) =>
        // style={{minWidth: 300, maxWidth: 300}}
        <SwiperSlide key={index}>
          <ProductCard
            width={"300px"}
            product={product}
            type={product.is_promo ? "promo" : (+product.discount_percent ? "sale" : "usual")}
            isFavourite={false}
          />
        </SwiperSlide>)}
    </Swiper>
  );
};

export default ProductsSwiper;
