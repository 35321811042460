import React, {FC} from "react";

import founder from "../../assets/founder-our-story.jpg";
import styles from "./OurStory.module.scss";

interface IOurStoryProps {
}

const OurStory: FC<IOurStoryProps> = () => {
  return (
    <div className={styles.ourStory}>
      <img draggable={false} src={founder} alt="founder of LIFE STYLLE"/>

      <div className={styles.ourStory_description}>
        <div className={styles.ourStory_description_subtitle}>
          <p className={styles.red}>Ідея</p>
          <p className={styles.white}>Початок <span
            className={`${styles.red} ${styles.uppercase}`}>нової</span> ери</p>
        </div>

        <p>
          Мережевий бізнес давно став справою мого життя. Він дав мені фінансову незалежність, правильне
          оточення,
          особистий та професійний розвиток.
          Я була звичайним новачком, який просто повірив, що він нічим не гірший за тих, у кого вийшло. Далі
          були
          злети, падіння, робота над помилками та нескінченний розвиток себе та своїх компетенцій
        </p>
        <p>
          Моя ціль
          - створити в LIFE STYLLE tradition такі умови та інфраструктуру, в яких кожна людина зможе реалізувати
          себе на максимум, розкрити весь потенціал та стати автором свого кращого життя!
          Створюючи компанію, я заклала у її фундамент все найкраще, що може дати мережевий бізнес у найкращих
          його
          проявах.
        </p>
        <p>
          Я переконана, що бажання стати професіоналом у справі, якою займаєшся, допоможе кожному нашому
          партнеру
          досягти поставленої мети, а команда LIFE STYLLE tradition зробить все, щоб шлях до результату була
          легким і
          цікавим.
        </p>
        <p>— Засновниця LIFE STYLLE tradition </p></div>
    </div>
  );
};

export default OurStory;
