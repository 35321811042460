import React, {FC} from "react";

import community1 from "../../assets/community-3.jpg";
import community2 from "../../assets/community-4.jpg";
import styles from "./WhoAreWe.module.scss";

interface IWhoAreWeProps {
}

const WhoAreWe: FC<IWhoAreWeProps> = () => {
  return (
    <div className={styles.whoAreWe}>
      <img src={community1} alt="community"/>

      <div className={styles.whoAreWe_description}>
        <div className={styles.whoAreWe_description_title}>
          <h2>LIFE STYLLE:</h2>
          <h2>Хто ми?</h2>
        </div>

        <div className={styles.whoAreWe_description_top}>
          <p className={styles.text}>Ми - молода амбітна компанія, створена у 2024 році командою досвідчених
            МЛМ підприємців.</p>
          <p className={styles.text}>
            У нас абсолютно нова, інноваційна система винагороди, яка дозволить кожному партнеру вже з
            перших
            кроків відчути принади та переваги мережевого бізнесу.
            Ми створили спеціальні програми лояльності для підтримки як молодих, так і досвідчених МЛМ
            підприємців.
            Наші промо-програми дозволяють партнерам безкоштовно подорожувати та отримувати від компанії
            автомобілі преміум-класу та нерухомість.
            Система навчання у компанії спрямована на підвищення професійних якостей, необхідних для
            досягнення максимальних результатів у бізнесі та житті.
          </p>
        </div>

        <div className={styles.whoAreWe_description_center}>
          <img src={community2} alt="community"/>

          <div className={styles.whoAreWe_description_center_text}>
            <p>2024</p>
            <p className={styles.text}>
              Ми об'єднали свою пристрасть до краси та віру в ідею, що кожна людина
              заслуговує на власний
              унікальний шлях до самовираження. У нашому світі кожен продукт, який ми створюємо, несе в
              собі не лише високу якість, але і нашу невичерпну любов до краси та індивідуальності
            </p>
          </div>
        </div>

        <div className={styles.whoAreWe_description_bottom}>
          <p className={styles.text}>
            LIFE STYLLE - це не лише набір продуктів, це стиль життя, що допомагає вам
            відкривати та висловлювати себе кожен день. Наша місія - створювати інструменти для того, щоб ви
            відчувалися впевнено у своїй шкірі, виражаючи той неповторний внутрішній світ, який ви принесли
            у цей світ.
            Приєднуйтеся до LIFE STYLLE і давайте разом творити світ краси, де кожен може відчути себе
            справжнім художником свого власного образу.
          </p>
        </div>
      </div>
    </div>
  );
};

export default WhoAreWe;
