import {endpoints} from "../configs/endpoints";
import {instance} from "./axios.service";

export const paymentService = {
  getInfoByCurrentUser: () => {
    const res = instance.get(endpoints.payment.base);

    return res;
  },
  sendWithdrawalRequest: (amount: number) => {
    const res = instance.post(endpoints.payment.base, {amount});

    return res;
  }
}
