import "../../utils/сustomCursor.scss";

import React, {FC, useEffect, useState} from "react";
import {Swiper, SwiperSlide} from "swiper/react";

import {ICategory} from "../../interfaces/ICategory";
import CategoryBadge from "../CategoryBadge/CategoryBadge";

interface ICategorySwiperProps {
    categories: ICategory[];
}

const CategorySwiper: FC<ICategorySwiperProps> = ({categories}) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const slidesPerView = windowWidth < 1440 ? windowWidth / 250 : 1440 / 250;

  return (
    <Swiper
      style={{width: "100%", minHeight: 42}}
      spaceBetween={16}
      className="mySwiper customCursor"
      slidesPerView={slidesPerView}
    >
      {categories.map((category, index) => (
        <SwiperSlide key={index}>
          <CategoryBadge category={category}/>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default CategorySwiper;
