import React, {ComponentType, useEffect} from "react";
import {useNavigate} from "react-router-dom";

import {MAIN_ROUTES} from "../routes/mainRoutes";

interface ITokens {
  access: string;
  refresh: string;
}

// The user can't access pages requiring authentication without valid credentials.
// It redirects the user to the login page if authentication is needed.
const IsAuth = <T extends object>(Component: ComponentType<T>): ComponentType<T> => {
  return (props) => {
    const navigate = useNavigate();

    let parsedTokens: ITokens = {access: "", refresh: ""};
    const tokens = localStorage.getItem("tokens");
    if (tokens) {
      parsedTokens = JSON.parse(tokens);
    }
    // const cookies = new Cookies();
    // const tokens = cookies.get("jwt_auth") as ITokens;

    const persistedLocalStorage = localStorage.getItem("persist:root");
    let isUser = false;
    if (persistedLocalStorage) {
      const parsedPersistedLocalStorage = JSON.parse(persistedLocalStorage);
      const parsedUserData = JSON.parse(parsedPersistedLocalStorage?.user);

      if (parsedUserData) {
        isUser = Object.values(parsedUserData)?.length > 0;
      }
    }

    useEffect(() => {
      if (!parsedTokens?.access || !isUser) {
        navigate(MAIN_ROUTES.LOGIN);

        // if the token doesn't exist or there is no info about the user,
        // then we clear the local storage
        localStorage.clear();
        window.location.reload();
      }
    }, []);

    if (parsedTokens?.access && isUser) {
      return <Component {...props} />;
    }

    return null;
  };
};

export default IsAuth;
