import React, {FC} from "react";

import blog from "../../assets/blog-item.svg";
import styles from "./BlogItem.module.scss";

interface IBlogItemProps {
}

const BlogItem: FC<IBlogItemProps> = () => {
  return (
    <div className={styles.item}>
      <img src={blog} alt=""/>

      <div className={styles.item_description}>
        <p className={styles.item_description_category}>Новинки компанії</p>
        <p className={styles.item_description_title}>Сяюча шкіра разом з новими продуктами від Life Style</p>
        <p className={styles.item_description_content}>Продукт, який стане для тебе незамінною частиною дня.</p>
      </div>
    </div>
  );
};

export default BlogItem;
