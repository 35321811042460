import Joi from "joi";

import {REGEX} from "../constants/regex";

export const personalOrderFormValidator = Joi.object({
  receiver_first_name: Joi.string().required().messages({
    "string.empty": "Заповніть поле",
  }),
  receiver_last_name: Joi.string().required().messages({
    "string.empty": "Заповніть поле",
  }),
  receiver_mobile: Joi.string().required().messages({
    "string.empty": "Заповніть поле",
  }),
  receiver_email: Joi.string().regex(REGEX.EMAIL).required().messages({
    "string.empty": "Заповніть поле",
    "string.pattern.base": "Невалідний email",
  }),
  delivery_supplier_name: Joi.string().required().messages({
    "string.empty": "Заповніть поле",
  }),
  city: Joi.string().required().messages({
    "string.empty": "Заповніть поле",
  }),
  number_of_department: Joi.number().optional().allow("").messages({
    "string.empty": "Заповніть поле",
  }),
  delivery_method_name: Joi.string().required().messages({
    "string.empty": "Заповніть поле",
  }),
});
