import React, {FC, useEffect, useRef, useState} from "react";

import chevron from "../../assets/chevron-left.svg";
import {orderingBy} from "../../constants/orderingBy";
import {useAppDispatch, useAppSelector} from "../../hooks/reduxHooks";
import {productsActions} from "../../store/slices/productSlice";
import styles from "./OrderingBy.module.scss";

interface IOrderingByProps {
}

const OrderingBy: FC<IOrderingByProps> = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(orderingBy[0]?.name);

  const dropdownRef = useRef<HTMLDivElement>(null);

  const dispatch = useAppDispatch();
  const {filteringValues} = useAppSelector(state => state.products);

  //if the user click outside the dropdown, it closes
  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    if (isDropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDropdownOpen]);

  const handleSelectOption = (option: { name: string, ordering: string }) => {
    const {name, ordering} = option;
    setSelectedOption(name);

    dispatch(productsActions.setFilteringValues({...filteringValues, ordering: option}));

    setIsDropdownOpen(false);
  }

  return (
    <div className={styles.wrapper}>
      <div ref={dropdownRef} className={styles.dropdown_wrapper}>
        <button
          onClick={() => setIsDropdownOpen(prev => !prev)}
          className={styles.dropdown_wrapper_button}
        >
          <p>
            <span>Сортування:</span> {filteringValues?.ordering?.name ? filteringValues?.ordering?.name : selectedOption}
          </p>

          <img
            className={styles.chevron}
            src={chevron}
            alt="chevron down icon"
            draggable={false}
          />
        </button>

        {isDropdownOpen &&
          <ul className={styles.dropdown_wrapper_options}>
            {orderingBy.map((item, index) =>
              <li
                onClick={() => handleSelectOption(item)}
                key={index}
              >{item.name}</li>)}
          </ul>}
      </div>
    </div>
  );
};

export default OrderingBy;
