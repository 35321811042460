import Joi from "joi";

import {REGEX} from "../constants/regex";

export const updateUserValidator = Joi.object({
  email: Joi.string().pattern(REGEX.EMAIL).required().messages({
    "string.empty": "Заповніть це поле",
    "string.pattern.base": "Невалідний email",
  }),
  first_name: Joi.string().required().messages({
    "string.empty": "Заповніть це поле",
  }),
  last_name: Joi.string().required().messages({
    "string.empty": "Заповніть це поле",
  }),
  mobile_nummer: Joi.string().required().messages({
    "string.empty": "Заповніть це поле",
    "number.base": "Поле повинно містити тільки цифри",
    "string.pattern.base": "Невалідний номер телефону",
  }),
  city: Joi.string().required().messages({
    "string.empty": "Заповніть це поле",
  }),
});
