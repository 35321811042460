export const handleTruncateText = (text: string, maxLength: number) => {
  if (text.length > maxLength) {
    let truncatedText = text.substring(0, maxLength - 3);
    let lastSpaceIndex = truncatedText.lastIndexOf(" ");
    if (lastSpaceIndex !== -1) {
      truncatedText = truncatedText.substring(0, lastSpaceIndex);
    }
    return truncatedText + " ...";
  } else {
    return text;
  }
};
