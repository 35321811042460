import React, {FC} from "react";

import user from "../../assets/success-story.svg";
import styles from "./SuccessStoryItem.module.scss";

interface ISuccessStoryItemProps {
}

const SuccessStoryItem: FC<ISuccessStoryItemProps> = () => {
  return (
    <div className={styles.item}>
      <img src={user} alt="user" draggable={false}/>

      <div className={styles.item_description}>
        <p>Ольга Васильків</p>
        <p>Львів, Україна</p>
      </div>
    </div>
  );
};

export default SuccessStoryItem;
